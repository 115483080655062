/*!-----------------------------------------------------------------
    Name: GoodGames - Game Portal / Store and eSports HTML Template
    Version: 1.5.2
    Author: nK
    Website: https://nkdev.info/
    Purchase: https://1.envato.market/goodgames
    Support: https://nk.ticksy.com/
    License: You must have a valid license purchased only from ThemeForest (the above link) in order to legally use the theme for your project.
    Copyright 2020.
-------------------------------------------------------------------*/
body {
  position: relative;
  overflow-x: hidden;
  color: #a0a7ab;
  background-color: #181c23 !important;
}
.nk-main {
  overflow: hidden;
}
body.nk-page-boxed {
  max-width: 1200px;
  margin: 0 auto;
}
a {
  color: #dd163b;
  transition: 0.15s color;
}
a:focus,
a:hover {
  color: #dd163b;
}
::-moz-selection {
  color: #fff;
  background-color: #313947;
}
::selection {
  color: #fff;
  background-color: #313947;
}
.nk-page-background-bottom,
.nk-page-background-fixed,
.nk-page-background-top {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: -1;
}
.nk-page-background-bottom {
  top: auto;
  bottom: 0;
}
.nk-page-background-fixed {
  position: fixed;
  height: 100%;
  background-position: center center;
  background-size: cover;
}
.nk-block {
  position: relative;
  display: block;
  overflow: hidden;
  z-index: 1;
}
.nk-block::after,
.nk-block::before {
  content: "";
  display: block;
  clear: both;
}
.bg-image,
.bg-video {
  position: absolute;
  top: -30px;
  right: -30px;
  bottom: -30px;
  left: -30px;
  background-position: 50% 50%;
  background-size: cover;
  z-index: -1;
}
.bg-image > div,
.bg-video > div {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-position: 50% 50%;
  background-size: cover;
}
.bg-video {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.bg-image-row,
.bg-map-row,
.bg-video-row {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
}
.bg-image-row > div,
.bg-map-row > div,
.bg-video-row > div {
  height: 100%;
  overflow: hidden;
}
@media (max-width: 991px) {
  .bg-image-row,
  .bg-map-row,
  .bg-video-row {
    position: relative;
  }
  .bg-image-row > div,
  .bg-map-row > div,
  .bg-video-row > div {
    padding-top: 56.25%;
  }
  .bg-image-row > div > div,
  .bg-map-row > div > div,
  .bg-video-row > div > div {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}
.bg-map-row {
  z-index: auto;
}
.bg-map-row .nk-gmaps {
  height: 100%;
  z-index: 1;
}
@media (max-width: 991px) {
  .bg-map-row {
    position: relative;
  }
  .bg-map-row > div {
    padding-top: 0;
  }
  .bg-map-row > div > div {
    position: relative;
  }
  .bg-map-row .nk-gmaps {
    height: 300px;
  }
  .bg-map-row .nk-gmaps-sm {
    height: 250px;
  }
  .bg-map-row .nk-gmaps-md {
    height: 450px;
  }
  .bg-map-row .nk-gmaps-lg {
    height: 550px;
  }
  .bg-map-row .nk-gmaps-full {
    height: 700px;
    height: 100vh;
    min-height: 700px;
  }
}
.bg-color {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #181c23;
  z-index: -1;
}
.nk-gap,
.nk-gap-1,
.nk-gap-2,
.nk-gap-3,
.nk-gap-4,
.nk-gap-5,
.nk-gap-6 {
  display: block;
  height: 15px;
}
.nk-gap-1::after,
.nk-gap-1::before,
.nk-gap-2::after,
.nk-gap-2::before,
.nk-gap-3::after,
.nk-gap-3::before,
.nk-gap-4::after,
.nk-gap-4::before,
.nk-gap-5::after,
.nk-gap-5::before,
.nk-gap-6::after,
.nk-gap-6::before,
.nk-gap::after,
.nk-gap::before {
  content: "";
  display: block;
  clear: both;
}
.nk-gap-1 {
  height: 20px;
}
.nk-gap-2 {
  height: 30px;
}
.nk-gap-3 {
  height: 50px;
}
.nk-gap-4 {
  height: 70px;
}
.nk-gap-5 {
  height: 90px;
}
.nk-gap-6 {
  height: 110px;
}
.nk-body-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}
.nk-gmaps {
  width: 100%;
  height: 300px;
}
.nk-gmaps-sm {
  height: 250px;
}
.nk-gmaps-md {
  height: 450px;
}
.nk-gmaps-lg {
  height: 550px;
}
.nk-gmaps-full {
  height: 700px;
  height: 100vh;
  min-height: 700px;
}
.nk-badge {
  display: inline-block;
  min-width: 17px;
  height: 17px;
  padding: 0 2px;
  font-size: 9px;
  font-weight: 600;
  line-height: 17px;
  text-align: center;
  background-color: #dd163b;
  border-radius: 8.5px;
}
.nk-navbar li > .nk-badge {
  position: absolute;
  right: 5px;
  bottom: 0;
}
.nk-scroll-top {
  cursor: pointer;
}
pre {
  position: relative;
  max-height: 500px;
  padding: 25px;
  line-height: 1.3;
  color: #fff;
  background-color: #2f3541;
}
pre .tag {
  display: inline-block;
  padding: 0;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  text-align: inherit;
  white-space: inherit;
  vertical-align: inherit;
  border-radius: 0;
}
pre[class*="language-"] {
  margin: 0;
}
.nk-vertical-bottom,
.nk-vertical-center,
.nk-vertical-top {
  display: table;
  width: 100%;
  height: 100%;
}
.nk-vertical-bottom > div,
.nk-vertical-center > div,
.nk-vertical-top > div {
  display: table-cell;
  vertical-align: middle;
}
.nk-vertical-bottom > div {
  vertical-align: bottom;
}
.nk-vertical-top > div {
  vertical-align: top;
}
.row.no-gap {
  margin-right: 0;
  margin-left: 0;
}
.row.no-gap > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}
.text-xs-center {
  text-align: center;
}
.text-xs-left {
  text-align: left;
}
.text-xs-right {
  text-align: right;
}
.pull-xs-right {
  float: right;
}
.pull-xs-left {
  float: left;
}
.pull-xs-none {
  float: none;
}
html {
  font-size: 14px;
}
body {
  font-family: "Open Sans", sans-serif;
  line-height: 1.7;
  letter-spacing: 0.015em;
  word-wrap: break-word;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
p {
  margin-bottom: 1.5rem;
}
b,
strong {
  font-weight: 600;
}
label {
  font-weight: 600;
  color: #fff;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 1.5rem;
  font-family: Montserrat, sans-serif;
  font-weight: 600;
  color: #fff;
  text-transform: uppercase;
}
.h1,
h1 {
  font-size: 3rem;
}
.h2,
h2 {
  font-size: 2.35rem;
}
.h3,
h3 {
  font-size: 1.75rem;
}
.h4,
h4 {
  font-size: 1.32rem;
}
.h5,
h5 {
  font-size: 1.02rem;
}
.h6,
h6 {
  font-size: 0.88rem;
}
.display-1,
.display-2,
.display-3,
.display-4 {
  font-family: Montserrat, sans-serif;
  font-weight: 600;
  text-transform: uppercase;
}
.display-1 {
  font-size: 4.7rem;
}
.display-2 {
  font-size: 4.2rem;
}
.display-3 {
  font-size: 3.7rem;
}
.display-4 {
  font-size: 3.2rem;
}
.nk-decorated-h,
.nk-decorated-h-2 {
  display: flex;
}
.nk-decorated-h-2 > a,
.nk-decorated-h-2 > span,
.nk-decorated-h > a,
.nk-decorated-h > span {
  display: block;
  flex: auto;
  padding-right: 20px;
  padding-left: 20px;
}
.nk-decorated-h-2::after,
.nk-decorated-h-2::before,
.nk-decorated-h::after,
.nk-decorated-h::before {
  content: "";
  display: block;
  flex: 100;
  border-bottom: 4px solid;
  transform: translateY(-10px);
}
.nk-decorated-h-2::before,
.nk-decorated-h::before {
  flex: 20px;
  width: 20px;
  min-width: 20px;
}
.nk-decorated-h-2::after,
.nk-decorated-h-2::before {
  border-bottom-color: #2f3541;
}
.nk-decorated-h-3 {
  display: flex;
}
.nk-decorated-h-3 > a,
.nk-decorated-h-3 > span {
  display: block;
  flex: auto;
  padding-right: 20px;
}
.nk-decorated-h-3::after {
  content: "";
  display: block;
  flex: 100;
  border-bottom: 4px solid #dd163b;
  transform: translateY(-4px);
}
.nk-title {
  position: relative;
  z-index: 1;
}
.nk-sub-title {
  position: relative;
  font-family: "Open Sans", sans-serif;
  font-size: 1.1rem;
  font-style: italic;
  font-weight: 400;
  color: #dd163b;
  z-index: 1;
}
.nk-title + .nk-sub-title {
  margin-top: 1rem;
}
.nk-title-back {
  margin-bottom: 0;
  font-size: 6rem;
  color: inherit;
  opacity: 0.1;
}
.nk-title-back + * {
  position: relative;
  margin-top: -40px;
}
.nk-title-back + .nk-sub-title {
  margin-top: -50px;
}
.nk-title-sep {
  display: block;
  margin: 30px auto;
  line-height: 0;
  color: inherit;
}
.nk-title-sep::after {
  content: "";
  display: inline-block;
  width: 80%;
  max-width: 80px;
  vertical-align: middle;
  border-bottom: 1px dotted;
  opacity: 0.4;
}
.nk-title-sep-icon {
  display: block;
  margin: 30px auto;
  line-height: 0;
  color: inherit;
}
.nk-title-sep-icon .icon {
  font-size: 1.9rem;
  vertical-align: middle;
}
.nk-title-sep-icon::after,
.nk-title-sep-icon::before {
  content: "";
  display: inline-block;
  width: 60px;
  vertical-align: middle;
  border-bottom: 1px dotted;
  opacity: 0.4;
}
.nk-title-sep-icon::before {
  margin-right: 20px;
}
.nk-title-sep-icon::after {
  margin-left: 20px;
}
.nk-mega-item > .dropdown > ul li > label {
  font-family: Montserrat, sans-serif;
}
.nk-sidebar-sticky-parent {
  display: flex;
  flex-direction: column;
}
.nk-sidebar-sticky-parent > .nk-sidebar {
  flex: 1;
}
.row.vertical-gap > [class*="col-"] {
  padding-top: 30px;
}
.row.vertical-gap {
  margin-top: -30px;
}
.row.sm-gap {
  margin-right: -10px;
  margin-left: -10px;
}
.row.sm-gap > [class*="col-"] {
  padding-right: 10px;
  padding-left: 10px;
}
.row.sm-gap.vertical-gap > [class*="col-"] {
  padding-top: 20px;
}
.row.sm-gap.vertical-gap {
  margin-top: -20px;
}
.row.md-gap {
  margin-right: -22.5px;
  margin-left: -22.5px;
}
.row.md-gap > [class*="col-"] {
  padding-right: 22.5px;
  padding-left: 22.5px;
}
.row.md-gap.vertical-gap > [class*="col-"] {
  padding-top: 45px;
}
.row.md-gap.vertical-gap {
  margin-top: -45px;
}
.row.lg-gap {
  margin-right: -30px;
  margin-left: -30px;
}
.row.lg-gap > [class*="col-"] {
  padding-right: 30px;
  padding-left: 30px;
}
.row.lg-gap.vertical-gap > [class*="col-"] {
  padding-top: 65px;
}
.row.lg-gap.vertical-gap {
  margin-top: -65px;
}
.thead-default th {
  color: #313947;
  background-color: #f7f7f7;
}
.table td,
.table th,
.table thead th {
  border-color: #f2f2f2;
}
.modal-content {
  background-color: #181c23;
  border-radius: 4px;
}
.modal-content .close {
  color: #fff;
  text-shadow: none;
}
.modal-header {
  padding: 20px 25px;
  border-bottom-color: #292e38;
}
.modal-body {
  padding: 20px 25px;
}
.modal-footer {
  padding: 20px 25px;
  border-top-color: #292e38;
}
.text-main-1 {
  color: #dd163b !important;
}
.text-main-2 {
  color: #a714b9 !important;
}
.text-main-3 {
  color: #38a220 !important;
}
.text-main-4 {
  color: #22a2b1 !important;
}
.text-main-5 {
  color: #1464d2 !important;
}
.text-main-6 {
  color: #ef9e2b !important;
}
.text-primary {
  color: #0275d8 !important;
}
.text-success {
  color: #38a220 !important;
}
.text-info {
  color: #22a2b1 !important;
}
.text-warning {
  color: #ef9e2b !important;
}
.text-danger {
  color: #e2361f !important;
}
.text-white {
  color: #fff !important;
}
.text-black {
  color: #000 !important;
}
.text-dark-1 {
  color: #181c23 !important;
}
.text-dark-2 {
  color: #292e38 !important;
}
.text-dark-3 {
  color: #2f3541 !important;
}
.text-dark-4 {
  color: #313947 !important;
}
.bg-main-1 {
  background-color: #dd163b !important;
}
.bg-main-2 {
  background-color: #a714b9 !important;
}
.bg-main-3 {
  background-color: #38a220 !important;
}
.bg-main-4 {
  background-color: #22a2b1 !important;
}
.bg-main-5 {
  background-color: #1464d2 !important;
}
.bg-main-6 {
  background-color: #ef9e2b !important;
}
.bg-primary {
  background-color: #0275d8 !important;
}
.bg-success {
  background-color: #38a220 !important;
}
.bg-info {
  background-color: #22a2b1 !important;
}
.bg-warning {
  background-color: #ef9e2b !important;
}
.bg-danger {
  background-color: #e2361f !important;
}
.bg-white {
  background-color: #fff !important;
}
.bg-black {
  background-color: #000 !important;
}
.bg-dark-1 {
  background-color: #181c23 !important;
}
.bg-dark-2 {
  background-color: #292e38 !important;
}
.bg-dark-3 {
  background-color: #2f3541 !important;
}
.bg-dark-4 {
  background-color: #313947 !important;
}
.nk-img,
.nk-img-fit {
  max-width: 100%;
  height: auto;
}
@media (max-width: 575px) {
  .nk-img-fit-xs-down {
    max-width: 100%;
    height: auto;
  }
}
@media (min-width: 576px) {
  .nk-img-fit-sm-up {
    max-width: 100%;
    height: auto;
  }
}
@media (max-width: 767px) {
  .nk-img-fit-sm-down {
    max-width: 100%;
    height: auto;
  }
}
@media (min-width: 768px) {
  .nk-img-fit-md-up {
    max-width: 100%;
    height: auto;
  }
}
@media (max-width: 991px) {
  .nk-img-fit-md-down {
    max-width: 100%;
    height: auto;
  }
}
@media (min-width: 992px) {
  .nk-img-fit-lg-up {
    max-width: 100%;
    height: auto;
  }
}
@media (max-width: 1199px) {
  .nk-img-fit-lg-down {
    max-width: 100%;
    height: auto;
  }
}
@media (min-width: 1200) {
  .nk-img-fit-xl-up {
    max-width: 100%;
    height: auto;
  }
}
.nk-img-stretch {
  width: 100%;
  height: auto;
}
img.circle {
  border-radius: 50%;
}
img.rounded {
  border-radius: 5px;
}
.align-left {
  text-align: left;
}
.align-center {
  text-align: center;
}
.align-right {
  text-align: right;
}
.align-justify {
  text-align: justify;
}
.va-t {
  vertical-align: top !important;
}
.va-m {
  vertical-align: middle !important;
}
.va-b {
  vertical-align: bottom !important;
}
.va-s {
  vertical-align: super !important;
}
.dib {
  display: inline-block !important;
}
.db {
  display: block;
}
.cursor {
  cursor: pointer !important;
}
.text-uppercase {
  text-transform: uppercase !important;
}
.fw-100 {
  font-weight: 100 !important;
}
.fw-200 {
  font-weight: 200 !important;
}
.fw-300 {
  font-weight: 300 !important;
}
.fw-400 {
  font-weight: 400 !important;
}
.fw-500 {
  font-weight: 500 !important;
}
.fw-600 {
  font-weight: 600 !important;
}
.fw-700 {
  font-weight: 700 !important;
}
.fw-800 {
  font-weight: 800 !important;
}
.fw-900 {
  font-weight: 900 !important;
}
.fs-4 {
  font-size: 4px !important;
}
.fs-5 {
  font-size: 5px !important;
}
.fs-6 {
  font-size: 6px !important;
}
.fs-7 {
  font-size: 7px !important;
}
.fs-8 {
  font-size: 8px !important;
}
.fs-9 {
  font-size: 9px !important;
}
.fs-10 {
  font-size: 10px !important;
}
.fs-11 {
  font-size: 11px !important;
}
.fs-12 {
  font-size: 12px !important;
}
.fs-13 {
  font-size: 13px !important;
}
.fs-14 {
  font-size: 14px !important;
}
.fs-15 {
  font-size: 15px !important;
}
.fs-16 {
  font-size: 16px !important;
}
.fs-17 {
  font-size: 17px !important;
}
.fs-18 {
  font-size: 18px !important;
}
.fs-19 {
  font-size: 19px !important;
}
.fs-20 {
  font-size: 20px !important;
}
.fs-22 {
  font-size: 22px !important;
}
.fs-24 {
  font-size: 24px !important;
}
.fs-26 {
  font-size: 26px !important;
}
.fs-28 {
  font-size: 28px !important;
}
.fs-30 {
  font-size: 30px !important;
}
.fs-35 {
  font-size: 35px !important;
}
.fs-40 {
  font-size: 40px !important;
}
.fs-45 {
  font-size: 45px !important;
}
.fs-50 {
  font-size: 50px !important;
}
.lh-0 {
  line-height: 0 !important;
}
.lh-5 {
  line-height: 5px !important;
}
.lh-10 {
  line-height: 10px !important;
}
.lh-15 {
  line-height: 15px !important;
}
.lh-20 {
  line-height: 20px !important;
}
.lh-25 {
  line-height: 25px !important;
}
.lh-30 {
  line-height: 30px !important;
}
.lh-35 {
  line-height: 35px !important;
}
.lh-40 {
  line-height: 40px !important;
}
.lh-45 {
  line-height: 45px !important;
}
.lh-50 {
  line-height: 50px !important;
}
.nk-font-inherit {
  font-family: inherit !important;
}
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.hauto {
  height: auto !important;
}
.wauto {
  width: auto !important;
}
.of-a {
  overflow: auto !important;
}
.of-h {
  overflow: hidden !important;
}
.of-v {
  overflow: visible !important;
}
.of-x-a {
  overflow-x: auto !important;
}
.of-x-h {
  overflow-x: hidden !important;
}
.of-x-v {
  overflow-x: visible !important;
}
.of-y-a {
  overflow-y: auto !important;
}
.of-y-h {
  overflow-y: hidden !important;
}
.of-y-v {
  overflow-y: visible !important;
}
.br-0 {
  border-radius: 0 !important;
}
.br-1 {
  border-radius: 1px !important;
}
.br-2 {
  border-radius: 2px !important;
}
.br-3 {
  border-radius: 3px !important;
}
.br-4 {
  border-radius: 4px !important;
}
.br-5 {
  border-radius: 5px !important;
}
.br-6 {
  border-radius: 6px !important;
}
.br-7 {
  border-radius: 7px !important;
}
.br-8 {
  border-radius: 8px !important;
}
.br-9 {
  border-radius: 9px !important;
}
.br-10 {
  border-radius: 10px !important;
}
.br-12 {
  border-radius: 12px !important;
}
.br-14 {
  border-radius: 14px !important;
}
.br-16 {
  border-radius: 16px !important;
}
.br-18 {
  border-radius: 18px !important;
}
.br-20 {
  border-radius: 20px !important;
}
.br-25 {
  border-radius: 25px !important;
}
.br-30 {
  border-radius: 30px !important;
}
.br-35 {
  border-radius: 35px !important;
}
.p-0 {
  padding: 0 !important;
}
.p-1 {
  padding: 1px !important;
}
.p-2 {
  padding: 2px !important;
}
.p-3 {
  padding: 3px !important;
}
.p-4 {
  padding: 4px !important;
}
.p-5 {
  padding: 5px !important;
}
.p-6 {
  padding: 6px !important;
}
.p-7 {
  padding: 7px !important;
}
.p-8 {
  padding: 8px !important;
}
.p-9 {
  padding: 9px !important;
}
.p-10 {
  padding: 10px !important;
}
.p-15 {
  padding: 15px !important;
}
.p-20 {
  padding: 20px !important;
}
.p-25 {
  padding: 25px !important;
}
.p-30 {
  padding: 30px !important;
}
.p-35 {
  padding: 35px !important;
}
.p-40 {
  padding: 40px !important;
}
.p-45 {
  padding: 45px !important;
}
.p-50 {
  padding: 50px !important;
}
.p-60 {
  padding: 60px !important;
}
.p-70 {
  padding: 70px !important;
}
.p-80 {
  padding: 80px !important;
}
.p-90 {
  padding: 90px !important;
}
.p-100 {
  padding: 100px !important;
}
.pt-0 {
  padding-top: 0 !important;
}
.pt-1 {
  padding-top: 1px !important;
}
.pt-2 {
  padding-top: 2px !important;
}
.pt-3 {
  padding-top: 3px !important;
}
.pt-4 {
  padding-top: 4px !important;
}
.pt-5 {
  padding-top: 5px !important;
}
.pt-6 {
  padding-top: 6px !important;
}
.pt-7 {
  padding-top: 7px !important;
}
.pt-8 {
  padding-top: 8px !important;
}
.pt-9 {
  padding-top: 9px !important;
}
.pt-10 {
  padding-top: 10px !important;
}
.pt-15 {
  padding-top: 15px !important;
}
.pt-20 {
  padding-top: 20px !important;
}
.pt-25 {
  padding-top: 25px !important;
}
.pt-30 {
  padding-top: 30px !important;
}
.pt-35 {
  padding-top: 35px !important;
}
.pt-40 {
  padding-top: 40px !important;
}
.pt-45 {
  padding-top: 45px !important;
}
.pt-50 {
  padding-top: 50px !important;
}
.pt-60 {
  padding-top: 60px !important;
}
.pt-70 {
  padding-top: 70px !important;
}
.pt-80 {
  padding-top: 80px !important;
}
.pt-90 {
  padding-top: 90px !important;
}
.pt-100 {
  padding-top: 100px !important;
}
.pl-0 {
  padding-left: 0 !important;
}
.pl-1 {
  padding-left: 1px !important;
}
.pl-2 {
  padding-left: 2px !important;
}
.pl-3 {
  padding-left: 3px !important;
}
.pl-4 {
  padding-left: 4px !important;
}
.pl-5 {
  padding-left: 5px !important;
}
.pl-6 {
  padding-left: 6px !important;
}
.pl-7 {
  padding-left: 7px !important;
}
.pl-8 {
  padding-left: 8px !important;
}
.pl-9 {
  padding-left: 9px !important;
}
.pl-10 {
  padding-left: 10px !important;
}
.pl-15 {
  padding-left: 15px !important;
}
.pl-20 {
  padding-left: 20px !important;
}
.pl-25 {
  padding-left: 25px !important;
}
.pl-30 {
  padding-left: 30px !important;
}
.pl-35 {
  padding-left: 35px !important;
}
.pl-40 {
  padding-left: 40px !important;
}
.pl-45 {
  padding-left: 45px !important;
}
.pl-50 {
  padding-left: 50px !important;
}
.pl-60 {
  padding-left: 60px !important;
}
.pl-70 {
  padding-left: 70px !important;
}
.pl-80 {
  padding-left: 80px !important;
}
.pl-90 {
  padding-left: 90px !important;
}
.pl-100 {
  padding-left: 100px !important;
}
.pr-0 {
  padding-right: 0 !important;
}
.pr-1 {
  padding-right: 1px !important;
}
.pr-2 {
  padding-right: 2px !important;
}
.pr-3 {
  padding-right: 3px !important;
}
.pr-4 {
  padding-right: 4px !important;
}
.pr-5 {
  padding-right: 5px !important;
}
.pr-6 {
  padding-right: 6px !important;
}
.pr-7 {
  padding-right: 7px !important;
}
.pr-8 {
  padding-right: 8px !important;
}
.pr-9 {
  padding-right: 9px !important;
}
.pr-10 {
  padding-right: 10px !important;
}
.pr-15 {
  padding-right: 15px !important;
}
.pr-20 {
  padding-right: 20px !important;
}
.pr-25 {
  padding-right: 25px !important;
}
.pr-30 {
  padding-right: 30px !important;
}
.pr-35 {
  padding-right: 35px !important;
}
.pr-40 {
  padding-right: 40px !important;
}
.pr-45 {
  padding-right: 45px !important;
}
.pr-50 {
  padding-right: 50px !important;
}
.pr-60 {
  padding-right: 60px !important;
}
.pr-70 {
  padding-right: 70px !important;
}
.pr-80 {
  padding-right: 80px !important;
}
.pr-90 {
  padding-right: 90px !important;
}
.pr-100 {
  padding-right: 100px !important;
}
.pb-0 {
  padding-bottom: 0 !important;
}
.pb-1 {
  padding-bottom: 1px !important;
}
.pb-2 {
  padding-bottom: 2px !important;
}
.pb-3 {
  padding-bottom: 3px !important;
}
.pb-4 {
  padding-bottom: 4px !important;
}
.pb-5 {
  padding-bottom: 5px !important;
}
.pb-6 {
  padding-bottom: 6px !important;
}
.pb-7 {
  padding-bottom: 7px !important;
}
.pb-8 {
  padding-bottom: 8px !important;
}
.pb-9 {
  padding-bottom: 9px !important;
}
.pb-10 {
  padding-bottom: 10px !important;
}
.pb-15 {
  padding-bottom: 15px !important;
}
.pb-20 {
  padding-bottom: 20px !important;
}
.pb-25 {
  padding-bottom: 25px !important;
}
.pb-30 {
  padding-bottom: 30px !important;
}
.pb-35 {
  padding-bottom: 35px !important;
}
.pb-40 {
  padding-bottom: 40px !important;
}
.pb-45 {
  padding-bottom: 45px !important;
}
.pb-50 {
  padding-bottom: 50px !important;
}
.pb-60 {
  padding-bottom: 60px !important;
}
.pb-70 {
  padding-bottom: 70px !important;
}
.pb-80 {
  padding-bottom: 80px !important;
}
.pb-90 {
  padding-bottom: 90px !important;
}
.pb-100 {
  padding-bottom: 100px !important;
}
.mauto {
  margin-right: auto;
  margin-left: auto;
}
.m-0 {
  margin: 0 !important;
}
.m-1 {
  margin: 1px !important;
}
.m-2 {
  margin: 2px !important;
}
.m-3 {
  margin: 3px !important;
}
.m-4 {
  margin: 4px !important;
}
.m-5 {
  margin: 5px !important;
}
.m-6 {
  margin: 6px !important;
}
.m-7 {
  margin: 7px !important;
}
.m-8 {
  margin: 8px !important;
}
.m-9 {
  margin: 9px !important;
}
.m-10 {
  margin: 10px !important;
}
.m-15 {
  margin: 15px !important;
}
.m-20 {
  margin: 20px !important;
}
.m-25 {
  margin: 25px !important;
}
.m-30 {
  margin: 30px !important;
}
.m-35 {
  margin: 35px !important;
}
.m-40 {
  margin: 40px !important;
}
.m-45 {
  margin: 45px !important;
}
.m-50 {
  margin: 50px !important;
}
.m-60 {
  margin: 60px !important;
}
.m-70 {
  margin: 70px !important;
}
.m-80 {
  margin: 80px !important;
}
.m-90 {
  margin: 90px !important;
}
.m-100 {
  margin: 100px !important;
}
.mt-0 {
  margin-top: 0 !important;
}
.mt-1 {
  margin-top: 1px !important;
}
.mt-2 {
  margin-top: 2px !important;
}
.mt-3 {
  margin-top: 3px !important;
}
.mt-4 {
  margin-top: 4px !important;
}
.mt-5 {
  margin-top: 5px !important;
}
.mt-6 {
  margin-top: 6px !important;
}
.mt-7 {
  margin-top: 7px !important;
}
.mt-8 {
  margin-top: 8px !important;
}
.mt-9 {
  margin-top: 9px !important;
}
.mt-10 {
  margin-top: 10px !important;
}
.mt-15 {
  margin-top: 15px !important;
}
.mt-20 {
  margin-top: 20px !important;
}
.mt-25 {
  margin-top: 25px !important;
}
.mt-30 {
  margin-top: 30px !important;
}
.mt-35 {
  margin-top: 35px !important;
}
.mt-40 {
  margin-top: 40px !important;
}
.mt-45 {
  margin-top: 45px !important;
}
.mt-50 {
  margin-top: 50px !important;
}
.mt-60 {
  margin-top: 60px !important;
}
.mt-70 {
  margin-top: 70px !important;
}
.mt-80 {
  margin-top: 80px !important;
}
.mt-90 {
  margin-top: 90px !important;
}
.mt-100 {
  margin-top: 100px !important;
}
.ml-0 {
  margin-left: 0 !important;
}
.ml-1 {
  margin-left: 1px !important;
}
.ml-2 {
  margin-left: 2px !important;
}
.ml-3 {
  margin-left: 3px !important;
}
.ml-4 {
  margin-left: 4px !important;
}
.ml-5 {
  margin-left: 5px !important;
}
.ml-6 {
  margin-left: 6px !important;
}
.ml-7 {
  margin-left: 7px !important;
}
.ml-8 {
  margin-left: 8px !important;
}
.ml-9 {
  margin-left: 9px !important;
}
.ml-10 {
  margin-left: 10px !important;
}
.ml-15 {
  margin-left: 15px !important;
}
.ml-20 {
  margin-left: 20px !important;
}
.ml-25 {
  margin-left: 25px !important;
}
.ml-30 {
  margin-left: 30px !important;
}
.ml-35 {
  margin-left: 35px !important;
}
.ml-40 {
  margin-left: 40px !important;
}
.ml-45 {
  margin-left: 45px !important;
}
.ml-50 {
  margin-left: 50px !important;
}
.ml-60 {
  margin-left: 60px !important;
}
.ml-70 {
  margin-left: 70px !important;
}
.ml-80 {
  margin-left: 80px !important;
}
.ml-90 {
  margin-left: 90px !important;
}
.ml-100 {
  margin-left: 100px !important;
}
.mr-0 {
  margin-right: 0 !important;
}
.mr-1 {
  margin-right: 1px !important;
}
.mr-2 {
  margin-right: 2px !important;
}
.mr-3 {
  margin-right: 3px !important;
}
.mr-4 {
  margin-right: 4px !important;
}
.mr-5 {
  margin-right: 5px !important;
}
.mr-6 {
  margin-right: 6px !important;
}
.mr-7 {
  margin-right: 7px !important;
}
.mr-8 {
  margin-right: 8px !important;
}
.mr-9 {
  margin-right: 9px !important;
}
.mr-10 {
  margin-right: 10px !important;
}
.mr-15 {
  margin-right: 15px !important;
}
.mr-20 {
  margin-right: 20px !important;
}
.mr-25 {
  margin-right: 25px !important;
}
.mr-30 {
  margin-right: 30px !important;
}
.mr-35 {
  margin-right: 35px !important;
}
.mr-40 {
  margin-right: 40px !important;
}
.mr-45 {
  margin-right: 45px !important;
}
.mr-50 {
  margin-right: 50px !important;
}
.mr-60 {
  margin-right: 60px !important;
}
.mr-70 {
  margin-right: 70px !important;
}
.mr-80 {
  margin-right: 80px !important;
}
.mr-90 {
  margin-right: 90px !important;
}
.mr-100 {
  margin-right: 100px !important;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.mb-1 {
  margin-bottom: 1px !important;
}
.mb-2 {
  margin-bottom: 2px !important;
}
.mb-3 {
  margin-bottom: 3px !important;
}
.mb-4 {
  margin-bottom: 4px !important;
}
.mb-5 {
  margin-bottom: 5px !important;
}
.mb-6 {
  margin-bottom: 6px !important;
}
.mb-7 {
  margin-bottom: 7px !important;
}
.mb-8 {
  margin-bottom: 8px !important;
}
.mb-9 {
  margin-bottom: 9px !important;
}
.mb-10 {
  margin-bottom: 10px !important;
}
.mb-15 {
  margin-bottom: 15px !important;
}
.mb-20 {
  margin-bottom: 20px !important;
}
.mb-25 {
  margin-bottom: 25px !important;
}
.mb-30 {
  margin-bottom: 30px !important;
}
.mb-35 {
  margin-bottom: 35px !important;
}
.mb-40 {
  margin-bottom: 40px !important;
}
.mb-45 {
  margin-bottom: 45px !important;
}
.mb-50 {
  margin-bottom: 50px !important;
}
.mb-60 {
  margin-bottom: 60px !important;
}
.mb-70 {
  margin-bottom: 70px !important;
}
.mb-80 {
  margin-bottom: 80px !important;
}
.mb-90 {
  margin-bottom: 90px !important;
}
.mb-100 {
  margin-bottom: 100px !important;
}
.mn-0 {
  margin: 0 !important;
}
.mn-1 {
  margin: -1px !important;
}
.mn-2 {
  margin: -2px !important;
}
.mn-3 {
  margin: -3px !important;
}
.mn-4 {
  margin: -4px !important;
}
.mn-5 {
  margin: -5px !important;
}
.mn-6 {
  margin: -6px !important;
}
.mn-7 {
  margin: -7px !important;
}
.mn-8 {
  margin: -8px !important;
}
.mn-9 {
  margin: -9px !important;
}
.mn-10 {
  margin: -10px !important;
}
.mn-15 {
  margin: -15px !important;
}
.mn-20 {
  margin: -20px !important;
}
.mn-25 {
  margin: -25px !important;
}
.mn-30 {
  margin: -30px !important;
}
.mn-35 {
  margin: -35px !important;
}
.mn-40 {
  margin: -40px !important;
}
.mn-45 {
  margin: -45px !important;
}
.mn-50 {
  margin: -50px !important;
}
.mn-60 {
  margin: -60px !important;
}
.mn-70 {
  margin: -70px !important;
}
.mn-80 {
  margin: -80px !important;
}
.mn-90 {
  margin: -90px !important;
}
.mn-100 {
  margin: -100px !important;
}
.mnt-0 {
  margin-top: 0 !important;
}
.mnt-1 {
  margin-top: -1px !important;
}
.mnt-2 {
  margin-top: -2px !important;
}
.mnt-3 {
  margin-top: -3px !important;
}
.mnt-4 {
  margin-top: -4px !important;
}
.mnt-5 {
  margin-top: -5px !important;
}
.mnt-6 {
  margin-top: -6px !important;
}
.mnt-7 {
  margin-top: -7px !important;
}
.mnt-8 {
  margin-top: -8px !important;
}
.mnt-9 {
  margin-top: -9px !important;
}
.mnt-10 {
  margin-top: -10px !important;
}
.mnt-15 {
  margin-top: -15px !important;
}
.mnt-20 {
  margin-top: -20px !important;
}
.mnt-25 {
  margin-top: -25px !important;
}
.mnt-30 {
  margin-top: -30px !important;
}
.mnt-35 {
  margin-top: -35px !important;
}
.mnt-40 {
  margin-top: -40px !important;
}
.mnt-45 {
  margin-top: -45px !important;
}
.mnt-50 {
  margin-top: -50px !important;
}
.mnt-60 {
  margin-top: -60px !important;
}
.mnt-70 {
  margin-top: -70px !important;
}
.mnt-80 {
  margin-top: -80px !important;
}
.mnt-90 {
  margin-top: -90px !important;
}
.mnt-100 {
  margin-top: -100px !important;
}
.mnl-0 {
  margin-left: 0 !important;
}
.mnl-1 {
  margin-left: -1px !important;
}
.mnl-2 {
  margin-left: -2px !important;
}
.mnl-3 {
  margin-left: -3px !important;
}
.mnl-4 {
  margin-left: -4px !important;
}
.mnl-5 {
  margin-left: -5px !important;
}
.mnl-6 {
  margin-left: -6px !important;
}
.mnl-7 {
  margin-left: -7px !important;
}
.mnl-8 {
  margin-left: -8px !important;
}
.mnl-9 {
  margin-left: -9px !important;
}
.mnl-10 {
  margin-left: -10px !important;
}
.mnl-15 {
  margin-left: -15px !important;
}
.mnl-20 {
  margin-left: -20px !important;
}
.mnl-25 {
  margin-left: -25px !important;
}
.mnl-30 {
  margin-left: -30px !important;
}
.mnl-35 {
  margin-left: -35px !important;
}
.mnl-40 {
  margin-left: -40px !important;
}
.mnl-45 {
  margin-left: -45px !important;
}
.mnl-50 {
  margin-left: -50px !important;
}
.mnl-60 {
  margin-left: -60px !important;
}
.mnl-70 {
  margin-left: -70px !important;
}
.mnl-80 {
  margin-left: -80px !important;
}
.mnl-90 {
  margin-left: -90px !important;
}
.mnl-100 {
  margin-left: -100px !important;
}
.mnr-0 {
  margin-right: 0 !important;
}
.mnr-1 {
  margin-right: -1px !important;
}
.mnr-2 {
  margin-right: -2px !important;
}
.mnr-3 {
  margin-right: -3px !important;
}
.mnr-4 {
  margin-right: -4px !important;
}
.mnr-5 {
  margin-right: -5px !important;
}
.mnr-6 {
  margin-right: -6px !important;
}
.mnr-7 {
  margin-right: -7px !important;
}
.mnr-8 {
  margin-right: -8px !important;
}
.mnr-9 {
  margin-right: -9px !important;
}
.mnr-10 {
  margin-right: -10px !important;
}
.mnr-15 {
  margin-right: -15px !important;
}
.mnr-20 {
  margin-right: -20px !important;
}
.mnr-25 {
  margin-right: -25px !important;
}
.mnr-30 {
  margin-right: -30px !important;
}
.mnr-35 {
  margin-right: -35px !important;
}
.mnr-40 {
  margin-right: -40px !important;
}
.mnr-45 {
  margin-right: -45px !important;
}
.mnr-50 {
  margin-right: -50px !important;
}
.mnr-60 {
  margin-right: -60px !important;
}
.mnr-70 {
  margin-right: -70px !important;
}
.mnr-80 {
  margin-right: -80px !important;
}
.mnr-90 {
  margin-right: -90px !important;
}
.mnr-100 {
  margin-right: -100px !important;
}
.mnb-0 {
  margin-bottom: 0 !important;
}
.mnb-1 {
  margin-bottom: -1px !important;
}
.mnb-2 {
  margin-bottom: -2px !important;
}
.mnb-3 {
  margin-bottom: -3px !important;
}
.mnb-4 {
  margin-bottom: -4px !important;
}
.mnb-5 {
  margin-bottom: -5px !important;
}
.mnb-6 {
  margin-bottom: -6px !important;
}
.mnb-7 {
  margin-bottom: -7px !important;
}
.mnb-8 {
  margin-bottom: -8px !important;
}
.mnb-9 {
  margin-bottom: -9px !important;
}
.mnb-10 {
  margin-bottom: -10px !important;
}
.mnb-15 {
  margin-bottom: -15px !important;
}
.mnb-20 {
  margin-bottom: -20px !important;
}
.mnb-25 {
  margin-bottom: -25px !important;
}
.mnb-30 {
  margin-bottom: -30px !important;
}
.mnb-35 {
  margin-bottom: -35px !important;
}
.mnb-40 {
  margin-bottom: -40px !important;
}
.mnb-45 {
  margin-bottom: -45px !important;
}
.mnb-50 {
  margin-bottom: -50px !important;
}
.mnb-60 {
  margin-bottom: -60px !important;
}
.mnb-70 {
  margin-bottom: -70px !important;
}
.mnb-80 {
  margin-bottom: -80px !important;
}
.mnb-90 {
  margin-bottom: -90px !important;
}
.mnb-100 {
  margin-bottom: -100px !important;
}
.op-0 {
  opacity: 0 !important;
}
.op-1 {
  opacity: 0.1 !important;
}
.op-2 {
  opacity: 0.2 !important;
}
.op-3 {
  opacity: 0.3 !important;
}
.op-4 {
  opacity: 0.4 !important;
}
.op-5 {
  opacity: 0.5 !important;
}
.op-6 {
  opacity: 0.6 !important;
}
.op-7 {
  opacity: 0.7 !important;
}
.op-8 {
  opacity: 0.8 !important;
}
.op-9 {
  opacity: 0.9 !important;
}
.op-10 {
  opacity: 1 !important;
}
.responsive-embed {
  position: relative;
  display: block;
  height: 0;
  padding: 0;
  overflow: hidden;
  clear: both;
}
.responsive-embed.responsive-embed-16x9 {
  padding-bottom: 56.25%;
}
.responsive-embed.responsive-embed-4x3 {
  padding-bottom: 75%;
}
.responsive-embed > .embed-item,
.responsive-embed > embed,
.responsive-embed > iframe,
.responsive-embed > object {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
.nk-keyboard {
  display: inline-block;
  min-width: 30px;
  height: 30px;
  padding: 0 7px;
  margin: 5px;
  font-size: 12px;
  line-height: 28px;
  color: #181c23;
  text-align: center;
  background-color: #fff;
  border: 1px solid #8b98af;
  border-radius: 3px;
}
.nk-header {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1000;
}
.nk-header.nk-header-opaque {
  position: relative;
}
.nk-logo-top {
  position: relative;
  padding: 70px 10px;
  color: #fff;
  text-align: center;
}
.nk-logo-top.nk-logo-top-light,
.nk-logo-top.nk-logo-top-light.nk-logo-top-solid {
  color: inherit;
  background-color: rgba(255, 255, 255, 0.85);
}
.nk-logo-top.nk-logo-top-transparent {
  background-color: transparent;
}
.nk-logo-top,
.nk-logo-top.nk-logo-top-solid {
  background-color: rgba(24, 28, 35, 0.75);
}
.nk-contacts-top {
  padding: 8px 0;
  font-size: 0.85rem;
  color: #fff;
}
.nk-contacts-top::after {
  content: "";
  display: block;
  clear: both;
}
.nk-contacts-top .nk-contacts-left {
  float: left;
}
.nk-contacts-top .nk-contacts-right {
  float: right;
}
@media (max-width: 575px) {
  .nk-contacts-top .nk-contacts-left,
  .nk-contacts-top .nk-contacts-right {
    float: none;
    text-align: center;
  }
  .nk-contacts-top .nk-contacts-left .nk-social-links,
  .nk-contacts-top .nk-contacts-right .nk-social-links {
    justify-content: center;
  }
  .nk-contacts-top .nk-contacts-right {
    margin-top: 10px;
  }
}
.nk-navbar {
  position: relative;
  padding: 27px 0;
  font-family: Montserrat, sans-serif;
  font-size: 1.07rem;
  color: #fff;
  transition: 0.3s background-color;
  z-index: 1000;
  will-change: background-color;
}
.nk-navbar.nk-navbar-fixed {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  transition: 0.2s transform, 0.2s visibility, 0.3s background-color;
  will-change: transform, visibility, background-color;
}
.nk-navbar.nk-navbar-fixed.nk-onscroll-hide {
  visibility: hidden;
  transform: translateY(-100%);
}
.nk-navbar.nk-navbar-fixed.nk-onscroll-show {
  visibility: visible;
  transform: translateY(0);
}
.nk-navbar.nk-navbar-light,
.nk-navbar.nk-navbar-light.nk-navbar-solid {
  color: inherit;
  background-color: rgba(255, 255, 255, 0.6);
}
.nk-navbar.nk-navbar-light.nk-navbar-fixed {
  background-color: rgba(255, 255, 255, 0.9);
}
.nk-navbar.nk-navbar-transparent {
  background-color: transparent;
}
.nk-navbar,
.nk-navbar.nk-navbar-solid {
  background-color: rgba(0, 0, 0, 0.6);
}
.nk-navbar.nk-navbar-fixed {
  background-color: rgba(0, 0, 0, 0.9);
}
.nk-navbar.nk-navbar-align-center {
  text-align: center;
}
.nk-navbar.nk-navbar-align-right {
  text-align: right;
}
.nk-navbar .nk-nav-table {
  display: table;
  width: 100%;
  height: 100%;
}
.nk-navbar .nk-nav-table > * {
  display: table-cell;
  vertical-align: middle;
}
.nk-navbar .nk-nav-table > .nk-nav-row {
  display: table-row;
}
.nk-navbar .nk-nav-table > .nk-nav-row-full {
  height: 100%;
}
.nk-navbar .nk-nav-table > .nk-nav-row-center > * {
  display: table-cell;
  vertical-align: middle;
}
.nk-navbar .nk-nav-table > .nk-nav-icons,
.nk-navbar .nk-nav-table > .nk-nav-logo {
  width: 1%;
}
.nk-navbar .nk-nav-logo img {
  height: auto;
}
.nk-navbar .nk-nav-icons {
  white-space: nowrap;
}
.nk-navbar .nk-nav-icons > * {
  white-space: initial;
}
.nk-navbar a {
  color: inherit;
}
.nk-navbar ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.nk-navbar ul > li > a {
  white-space: nowrap;
}
.nk-navbar ul > li > a:focus,
.nk-navbar ul > li > a:hover {
  text-decoration: none;
}
.nk-navbar .nk-navbar-bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
}
.nk-navbar .nk-nav {
  position: relative;
  width: 100px;
}
.nk-navbar .nk-nav .nk-nav {
  padding-right: 0;
  padding-left: 0;
}
.nk-navbar .nk-nav li.single-icon > a {
  padding: 0 15px;
}
.nk-navbar .nk-nav li.single-icon > a > i,
.nk-navbar .nk-nav li.single-icon > a > span {
  height: 32px;
  font-size: 1.1rem;
  line-height: 32px;
  vertical-align: middle;
}
.nk-navbar .nk-nav li > a .nk-item-descr {
  display: block;
  font-size: 0.7em;
  font-style: italic;
  opacity: 0.7;
}
.nk-navbar .nk-nav li > a {
  font-weight: 600;
  text-transform: uppercase;
  transition: 0.15s color;
}
.nk-navbar .nk-nav li > a.hover,
.nk-navbar .nk-nav li > a:hover {
  color: #dd163b;
}
.nk-navbar .nk-nav li.active > a {
  color: #dd163b;
}
.nk-navbar .nk-nav > li {
  position: relative;
  display: inline-block;
  vertical-align: middle;
}
.nk-navbar .nk-nav > li:last-child {
  margin-right: -21px;
}
@media (max-width: 767px) {
  .nk-navbar .nk-nav > li:last-child {
    margin-right: -10px;
  }
}
.nk-navbar .nk-nav > li > a {
  position: relative;
  display: block;
  padding: 6px 21px;
}
@media (max-width: 1199px) {
  .nk-navbar .nk-nav > li > a {
    padding: 6px 18px;
  }
}
.nk-navbar .nk-nav .dropdown {
  position: absolute;
  min-width: 250px;
  padding: 9px 0;
  margin-top: 12px;
  margin-left: 9px;
  visibility: hidden;
  background-color: rgba(0, 0, 0, 0.9);
  border-bottom: 4px solid #dd163b;
  border-radius: 4px;
  opacity: 0;
  transition: 0.2s opacity, 0.2s transform, 0.2s visibility;
  z-index: 1;
}
.nk-navbar .nk-nav .dropdown::before {
  content: "";
  position: absolute;
  display: block;
  top: -45px;
  right: -1px;
  left: -1px;
  height: 150px;
  max-height: 130%;
  z-index: -1;
}
.nk-navbar .nk-nav .dropdown > li {
  position: relative;
}
.nk-navbar .nk-nav .dropdown > li > a {
  display: block;
  padding: 6px 17px;
  padding-right: 64px;
}
.nk-navbar .nk-nav .dropdown > li > a:hover {
  text-decoration: none;
}
.nk-navbar .nk-nav .nk-drop-item > a::after {
  content: " +";
}
.nk-navbar .nk-nav .nk-drop-item.open > .dropdown,
.nk-navbar .nk-nav .nk-drop-item:hover > .dropdown {
  visibility: inherit;
  opacity: 1;
  transform: translateY(0);
}
.nk-navbar .nk-nav .nk-drop-item .nk-drop-item > a::after {
  position: absolute;
  right: 20px;
}
.nk-navbar .nk-nav .nk-drop-item .nk-drop-item .dropdown {
  top: 0;
  left: 5px;
  margin-top: -9px;
  margin-left: 100%;
}
.nk-navbar .nk-nav .nk-drop-item .nk-drop-item .dropdown::before {
  top: 0;
  right: auto;
  left: -10px;
  width: 10px;
  height: 100%;
}
.nk-navbar
  .nk-nav
  .nk-drop-item
  .nk-drop-item.nk-drop-item-reverse
  > .dropdown {
  right: 5px;
  left: auto;
  margin-right: 100%;
  margin-left: 0;
}
.nk-navbar
  .nk-nav
  .nk-drop-item
  .nk-drop-item.nk-drop-item-reverse
  > .dropdown::before {
  right: -10px;
  left: auto;
}
.nk-navbar.nk-navbar-light .nk-nav .dropdown {
  background-color: #fff;
}
.nk-navbar .nk-nav-right {
  text-align: right;
}
.nk-navbar .nk-nav-right > * {
  text-align: left;
}
.nk-navbar .nk-nav-right .nk-mega-item > .dropdown {
  right: 0;
  left: auto;
}
.nk-navbar .nk-nav-center {
  text-align: center;
}
.nk-navbar .nk-nav-center > * {
  text-align: left;
}
.nk-navbar .nk-nav-center .nk-mega-item > .dropdown {
  right: 0;
  left: auto;
}
.nk-navbar .nk-drop-item.nk-drop-left .dropdown {
  margin-left: -100%;
}
.nk-navbar-left.nk-navbar-side {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 280px;
  z-index: 999;
}
.nk-navbar-left.nk-navbar-side ~ .nk-main,
.nk-navbar-left.nk-navbar-side ~ .nk-search {
  margin-left: 280px;
}
.nk-navbar-left.nk-navbar-side.nk-navbar-lg {
  width: 300px;
}
.nk-navbar-left.nk-navbar-side.nk-navbar-lg ~ .nk-main,
.nk-navbar-left.nk-navbar-side.nk-navbar-lg ~ .nk-search {
  margin-left: 300px;
}
@media (max-width: 991px) {
  .nk-navbar-left.nk-navbar-side {
    display: none;
  }
  .nk-navbar-left.nk-navbar-side.nk-navbar-lg ~ .nk-main,
  .nk-navbar-left.nk-navbar-side.nk-navbar-lg ~ .nk-search,
  .nk-navbar-left.nk-navbar-side ~ .nk-main,
  .nk-navbar-left.nk-navbar-side ~ .nk-search {
    margin-left: 0;
  }
}
.nk-nav-toggler-left,
.nk-nav-toggler-right {
  position: fixed;
  top: 20px;
  right: 20px;
  padding: 0;
  margin: 0;
  list-style: none;
  z-index: 1000;
}
.nk-nav-toggler-left > li,
.nk-nav-toggler-right > li {
  display: inline-block;
}
.nk-nav-toggler-left > li + li,
.nk-nav-toggler-right > li + li {
  margin-left: 15px;
}
.nk-nav-toggler-left > li > a,
.nk-nav-toggler-right > li > a {
  display: block;
  padding: 12px;
  color: #fff;
  background-color: #181c23;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.08);
}
.nk-nav-toggler-left > li > a > span,
.nk-nav-toggler-right > li > a > span {
  height: 25px;
  vertical-align: text-bottom;
}
.nk-nav-toggler-left {
  right: auto;
  left: 20px;
}
.nk-navbar-side {
  position: fixed;
  top: 0;
  bottom: 0;
  padding: 0;
  overflow: hidden;
  background-color: #181c23;
  z-index: 1002;
}
.nk-navbar-side.nk-navbar-left-side {
  left: 0;
  width: 250px;
  margin-left: -250px;
}
@media (max-width: 400px) {
  .nk-navbar-side.nk-navbar-left-side {
    width: 200px;
    margin-left: -200px;
  }
}
.nk-navbar-side.nk-navbar-right-side {
  right: 0;
  width: 250px;
  margin-right: -250px;
}
@media (max-width: 400px) {
  .nk-navbar-side.nk-navbar-right-side {
    width: 200px;
    margin-right: -200px;
  }
}
.nk-navbar-side.nk-navbar-lg.nk-navbar-left-side {
  width: 350px;
  margin-left: -350px;
}
@media (max-width: 550px) {
  .nk-navbar-side.nk-navbar-lg.nk-navbar-left-side {
    width: 300px;
    margin-left: -300px;
  }
}
@media (max-width: 450px) {
  .nk-navbar-side.nk-navbar-lg.nk-navbar-left-side {
    width: 250px;
    margin-left: -250px;
  }
}
.nk-navbar-side.nk-navbar-lg.nk-navbar-right-side {
  width: 350px;
  margin-right: -350px;
}
@media (max-width: 550px) {
  .nk-navbar-side.nk-navbar-lg.nk-navbar-right-side {
    width: 300px;
    margin-right: -300px;
  }
}
@media (max-width: 450px) {
  .nk-navbar-side.nk-navbar-lg.nk-navbar-right-side {
    width: 250px;
    margin-right: -250px;
  }
}
.nk-navbar-side .nk-nav-logo {
  display: block;
  width: 100%;
  padding: 20px 25px;
}
.nk-navbar-side.nk-navbar-lg .nk-nav-logo {
  padding-top: 30px;
  padding-bottom: 30px;
}
.nk-navbar-side .nano {
  height: 100%;
  overflow-y: auto;
}
.nk-navbar-side .nk-nav {
  position: relative;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.nk-navbar-side .nk-nav > li:last-child {
  margin-right: 0;
}
.nk-navbar-side .nk-nav .dropdown > li,
.nk-navbar-side .nk-nav > li {
  position: static;
  display: block;
}
.nk-navbar-side .nk-nav .dropdown > li > a,
.nk-navbar-side .nk-nav > li > a {
  padding: 12px 25px;
  white-space: normal;
}
.nk-navbar-side .nk-nav .bropdown-back > a,
.nk-navbar-side .nk-nav .nk-drop-item > a,
.nk-navbar-side .nk-nav > .nk-drop-item > a {
  position: relative;
}
.nk-navbar-side .nk-nav .nk-drop-item .nk-drop-item > a::after,
.nk-navbar-side .nk-nav .nk-drop-item > a::after,
.nk-navbar-side .nk-nav > .nk-drop-item > a::after {
  position: absolute;
  right: 20px;
}
.nk-navbar-side .nk-nav .dropdown {
  display: block;
  padding: 0;
  background-color: transparent;
  border-bottom: 0;
  opacity: 1;
}
.nk-navbar-side .nk-nav .dropdown::before {
  content: none;
}
.nk-navbar-side .nk-nav .nk-drop-item .nk-drop-item > .dropdown,
.nk-navbar-side .nk-nav .nk-drop-item > .dropdown {
  top: 0;
  left: 0;
  width: 100%;
  margin-top: 0;
  margin-left: 0;
}
.nk-navbar-side .nk-nav .nk-drop-item .nk-drop-item > .dropdown > li > a,
.nk-navbar-side .nk-nav .nk-drop-item > .dropdown > li > a {
  display: none;
}
.nk-navbar-side .dropdown > .bropdown-back > a::after {
  content: "";
  position: absolute;
  display: block;
  top: 50%;
  left: 15px;
  width: 0;
  height: 0;
  margin-top: -3px;
  color: inherit;
  border: none;
  border-top: 3px solid transparent;
  border-right: 3px solid;
  border-bottom: 3px solid transparent;
  transition: 0.13s opacity;
  transform: none;
  will-change: opacity;
}
.nk-navbar-side .nk-nav-icons {
  list-style: none;
}
.nk-navbar-side .nk-nav-icons li {
  position: relative;
  display: inline-block;
  vertical-align: middle;
}
.nk-navbar-side .nk-nav-icons li > a {
  display: inline-block;
  padding: 0 15px;
}
.nk-navbar-side .nk-nav-icons li > a > i,
.nk-navbar-side .nk-nav-icons li > a > span {
  height: 32px;
  font-size: 1.2rem;
  line-height: 32px;
  vertical-align: middle;
}
.nk-navbar-overlay {
  position: fixed;
  display: none;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  cursor: pointer;
  background-color: #000;
  opacity: 0;
  z-index: 1001;
}
.nk-search {
  position: fixed;
  display: none;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  color: #fff;
  background: #181c23;
  background: linear-gradient(
    to bottom,
    #181c23 0,
    #181c23 100px,
    rgba(24, 28, 35, 0) 100%
  );
  opacity: 0;
  z-index: 999;
}
.nk-search .nk-search-field {
  position: relative;
  margin-top: 60px;
  margin-bottom: 130px;
}
.nk-search .nk-search-field label {
  position: absolute;
  top: 0;
  left: 0;
  height: 124px;
  margin: 0;
  font-size: 4rem;
  line-height: 124px;
  transition: 0.3s color;
  will-change: color;
}
.nk-search .nk-search-field input {
  width: 93%;
  width: calc(100% - 70px);
  padding: 1rem 1rem;
  margin-left: 70px;
  font-size: 5rem;
  color: inherit;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 0;
  transition: 0.3s border, 0.3s box-shadow;
  will-change: border, box-shadow;
}
.nk-search .nk-search-field input.focus,
.nk-search .nk-search-field input:focus {
  border-bottom-color: rgba(255, 255, 255, 0.8);
  box-shadow: 0 1px 0 0 rgba(255, 255, 255, 0.8);
}
@media (max-width: 767px) {
  .nk-search .nk-search-field {
    margin-top: 30px;
    margin-bottom: 50px;
  }
  .nk-search .nk-search-field label {
    height: 106px;
    font-size: 3rem;
    line-height: 106px;
  }
  .nk-search .nk-search-field input {
    width: calc(100% - 50px);
    margin-left: 50px;
    font-size: 4rem;
  }
}
@media (max-width: 575px) {
  .nk-search .nk-search-field label {
    height: 68px;
    font-size: 2rem;
    line-height: 68px;
  }
  .nk-search .nk-search-field input {
    width: calc(100% - 40px);
    margin-left: 40px;
    font-size: 2rem;
  }
}
.nk-search.nk-search-light {
  color: inherit;
  background: #fff;
  background: linear-gradient(
    to bottom,
    #fff 0,
    #fff 100px,
    rgba(255, 255, 255, 0) 100%
  );
}
.nk-search.nk-search-light .nk-search-field input {
  border-bottom: 1px solid rgba(24, 28, 35, 0.3);
}
.nk-search.nk-search-light .nk-search-field input.focus,
.nk-search.nk-search-light .nk-search-field input:focus {
  border-bottom-color: rgba(24, 28, 35, 0.8);
  box-shadow: 0 1px 0 0 rgba(24, 28, 35, 0.8);
}
.nk-breadcrumbs {
  padding: 0;
  margin: 0;
  font-family: Montserrat, sans-serif;
  font-weight: 600;
  color: #fff;
  text-transform: uppercase;
  list-style-type: none;
}
.nk-breadcrumbs > li {
  display: inline-block;
  font-size: 1.07rem;
}
.nk-breadcrumbs > li + li {
  margin-left: 6px;
}
.nk-breadcrumbs > li a {
  color: inherit;
  text-decoration: none;
}
.nk-breadcrumbs > li a.hover,
.nk-breadcrumbs > li a:hover {
  color: #dd163b;
}
.nk-breadcrumbs > li:last-of-type {
  display: flex;
  margin-top: 9px;
  margin-left: 0;
  font-size: 2.025rem;
}
.nk-breadcrumbs > li:last-of-type > a,
.nk-breadcrumbs > li:last-of-type > span {
  display: block;
  flex: auto;
  padding-right: 20px;
}
.nk-breadcrumbs > li:last-of-type::after {
  content: "";
  display: block;
  flex: 100;
  border-bottom: 4px solid #dd163b;
  transform: translateY(-12px);
}
.nk-footer {
  position: relative;
  overflow: hidden;
  color: #eee;
  background-color: rgba(18, 21, 26, 0.8);
  z-index: 1;
}
.nk-footer-logo {
  text-align: center;
}
.nk-footer-social {
  text-align: center;
}
.nk-footer-social > a {
  display: inline-block;
  width: 40px;
  height: 40px;
  margin: 6px 4px;
  font-size: 1.15rem;
  line-height: 40px;
  color: inherit;
  border: 1px solid;
  border-radius: 20px;
  transition: 0.3s color, 0.3s background-color, 0.3s border-color;
  will-change: color, background-color;
}
.nk-footer-social > a.hover,
.nk-footer-social > a:hover {
  color: #181c23;
  background-color: #fff;
  border-color: #fff;
}
.nk-footer-social.nk-footer-social-inverted > a.hover,
.nk-footer-social.nk-footer-social-inverted > a:hover {
  color: #fff;
  background-color: #181c23;
  border-color: #181c23;
}
.nk-footer-social-2 {
  text-align: center;
}
.nk-footer-social-2 > a {
  display: inline-block;
  margin: 15px;
  color: inherit;
  text-decoration: none;
  transition: 0.3s opacity;
  will-change: opacity;
}
.nk-footer-social-2 > a.hover,
.nk-footer-social-2 > a:hover {
  opacity: 0.6;
}
.nk-footer-social-2 > a > * {
  display: block;
  margin: 0;
}
.nk-footer-social-2 > a > .icon {
  margin-bottom: 5px;
  font-size: 2rem;
}
.nk-copyright {
  background-color: #000;
}
.nk-copyright .container {
  display: flex;
  flex-wrap: wrap;
}
.nk-copyright .nk-copyright-left {
  padding: 25px 0;
}
.nk-copyright .nk-copyright-right {
  padding: 21px 0;
  margin-left: auto;
}
@media (max-width: 991px) {
  .nk-copyright .nk-copyright-left,
  .nk-copyright .nk-copyright-right {
    width: 100%;
    text-align: center;
  }
  .nk-copyright .nk-copyright-right {
    padding-top: 0;
  }
}
.nk-widget {
  overflow: hidden;
  border-radius: 4px;
}
.nk-widget ~ .nk-widget {
  margin-top: 30px;
}
.nk-widget .nk-widget-title {
  margin-bottom: 15px;
  font-size: 1.22rem;
  text-transform: uppercase;
}
.nk-widget-highlighted .nk-widget-title {
  position: relative;
  padding: 23px 23px;
  margin-bottom: 0;
  background-color: #2f3541;
  z-index: 1;
}
.nk-widget-highlighted .nk-widget-title > span {
  display: inline-block;
  padding-right: 23px;
  background-color: #2f3541;
}
.nk-widget-highlighted .nk-widget-title::after {
  content: "";
  position: absolute;
  display: block;
  top: 32px;
  right: 1px;
  left: 30px;
  height: 3px;
  background-color: #fff;
  z-index: -1;
}
.nk-widget-highlighted .nk-widget-content {
  padding: 20px 23px;
  background-color: #292e38;
}
.nk-widget-post,
.nk-widget-post-2 {
  padding-left: 110px;
}
.nk-widget-post + .nk-widget-post,
.nk-widget-post-2 + .nk-widget-post {
  padding-top: 20px;
}
.nk-widget-post-2::after,
.nk-widget-post::after {
  content: "";
  display: block;
  clear: both;
}
.nk-widget-post a,
.nk-widget-post-2 a {
  text-decoration: none;
}
.nk-widget-post .nk-post-image,
.nk-widget-post-2 .nk-post-image {
  float: left;
  width: 90px;
  margin-left: -110px;
  overflow: hidden;
  border-radius: 4px;
  transition: opacity 0.15s ease-in-out;
}
.nk-widget-post .nk-post-image:hover,
.nk-widget-post-2 .nk-post-image:hover {
  opacity: 0.8;
}
.nk-widget-post .nk-post-image img,
.nk-widget-post-2 .nk-post-image img {
  width: 100%;
  height: auto;
  transition: transform 0.15s ease-in-out;
  transform: scale(1);
  will-change: transform;
}
.nk-widget-post-2:hover .nk-post-image img,
.nk-widget-post:hover .nk-post-image img {
  transform: scale(1.05);
}
.nk-widget-post .nk-post-title,
.nk-widget-post-2 .nk-post-title {
  display: inline-block;
  width: 100%;
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 1.15rem;
}
.nk-widget-post .nk-product-price,
.nk-widget-post-2 .nk-product-price {
  margin-top: 20px;
}
.nk-widget-post-2 {
  position: relative;
}
.nk-widget-post-2 div.nk-post-title {
  font-size: 1em;
  line-height: 1.4;
}
.nk-widget-categories {
  color: #fff;
}
.nk-widget-categories,
.nk-widget-categories ul {
  padding: 0;
  margin: 0;
  margin-top: -14px;
  list-style-type: none;
}
.nk-widget-categories .nk-widget-categories {
  margin-top: 0;
  margin-left: 25px;
}
.nk-widget-categories .nk-widget-categories li {
  border-top: 1px solid #2f3541;
}
.nk-widget-categories li {
  display: block;
}
.nk-widget-categories li + li {
  border-top: 1px solid #2f3541;
}
.nk-widget-categories li a {
  display: block;
  padding: 14px 0;
  color: inherit;
  text-decoration: none;
  transition: 0.3s color, 0.3s background-color;
}
.nk-widget-categories li a.hover,
.nk-widget-categories li a:active,
.nk-widget-categories li a:focus,
.nk-widget-categories li a:hover {
  color: #dd163b;
  background-color: #2f3541;
}
.nk-widget-highlighted .nk-widget-categories li a {
  padding-right: 23px;
  padding-left: 23px;
  margin-top: -1px;
  margin-right: -23px;
  margin-bottom: -1px;
  margin-left: -23px;
  color: inherit;
}
.nk-widget-categories li .nk-widget-categories-count {
  float: right;
  color: inherit;
  opacity: 0.5;
}
.nk-post-tags,
.nk-widget-tags {
  margin-bottom: -5px;
  font-size: 0.9rem;
  font-style: normal;
}
.nk-post-tags > a,
.nk-widget-tags > a {
  position: relative;
  display: inline-block;
  padding: 5px 11px;
  margin-right: 1px;
  margin-bottom: 5px;
  color: inherit;
  text-decoration: none;
  transition: 0.3s opacity;
  z-index: 1;
}
.nk-post-tags > a::before,
.nk-widget-tags > a::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: 1px solid;
  opacity: 0.6;
  z-index: -1;
}
.nk-post-tags > a.hover,
.nk-post-tags > a:hover,
.nk-widget-tags > a.hover,
.nk-widget-tags > a:hover {
  opacity: 0.7;
}
.nk-instagram img {
  border-radius: 4px;
}
.nk-twitter {
  position: relative;
  padding-left: 30px;
}
.nk-twitter + .nk-twitter {
  margin-top: 20px;
}
.nk-twitter .nk-twitter-date {
  float: left;
  padding-left: 5px;
  color: #a0a7ab;
}
.nk-twitter .nk-twitter-date::before {
  content: " | ";
}
.nk-twitter .nk-twitter-name {
  float: left;
  color: #fff;
}
.nk-twitter .nk-twitter-name a {
  color: inherit;
  text-decoration: none;
}
.nk-twitter .nk-twitter-name a.hover,
.nk-twitter .nk-twitter-name a:focus,
.nk-twitter .nk-twitter-name a:hover {
  color: #dd163b;
}
.nk-twitter .nk-twitter-icon {
  position: absolute;
  top: 4px;
  left: 0;
  font-size: 1.3rem;
  color: #fff;
}
.nk-twitter .nk-twitter-text {
  color: #fff;
}
.nk-twitter .nk-twitter-text::before {
  content: "";
  display: block;
  width: 100%;
  clear: both;
}
.nk-nav .nk-widget-store-cart {
  width: 354px;
  padding-top: 5px;
  padding-right: 25px;
  padding-bottom: 5px;
  padding-left: 25px;
}
.nk-widget-store-cart .nk-widget-store-cart-actions {
  margin-top: 25px;
}
.nk-widget-store-cart .nk-widget-store-cart-actions a + a {
  margin-left: 15px;
}
.nk-widget-store-cart .nk-widget-store-cart-total {
  margin-top: 25px;
  margin-bottom: 25px;
}
.nk-widget-store-cart .nk-widget-store-cart-total::after,
.nk-widget-store-cart .nk-widget-store-cart-total::before {
  content: "";
  display: block;
  width: 100%;
  clear: both;
  border-top: 1px dotted #777;
}
.nk-widget-store-cart .nk-widget-store-cart-total::before {
  margin-bottom: 15px;
}
.nk-widget-store-cart .nk-widget-store-cart-total::after {
  margin-top: 15px;
}
.nk-widget-store-cart .nk-widget-store-cart-total > a {
  color: inherit;
  text-decoration: none;
  transition: 0.3s opacity;
}
.nk-widget-store-cart .nk-widget-store-cart-total > a:focus,
.nk-widget-store-cart .nk-widget-store-cart-total > a:hover {
  opacity: 0.7;
}
.nk-widget-store-cart .nk-widget-store-cart-total > span {
  float: right;
}
.nk-widget-match {
  padding: 20px;
}
.nk-widget .nk-widget-match {
  background-color: #292e38;
}
.nk-widget .nk-widget-match:nth-child(2n) {
  background-color: #2f3541;
}
.nk-widget-highlighted .nk-widget-content .nk-widget-match {
  margin-top: -20px;
  margin-right: -23px;
  margin-bottom: -20px;
  margin-left: -23px;
}
.nk-widget-highlighted .nk-widget-content .nk-widget-match + .nk-widget-match {
  margin-top: 20px;
}
.nk-widget-match > * {
  display: flex;
  flex-wrap: wrap-reverse;
  align-items: center;
  width: 100%;
}
.nk-widget-match > a {
  color: inherit;
  text-decoration: none;
}
.nk-widget-match > a:hover {
  color: #dd163b;
}
.nk-widget-match .nk-widget-match-right {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: auto;
}
.nk-widget-match .nk-widget-match-teams {
  display: flex;
  align-items: center;
}
.nk-widget-match .nk-widget-match-teams .nk-widget-match-vs {
  padding-right: 5px;
  padding-left: 7px;
  font-size: 0.9em;
  font-weight: 600;
  color: #fff;
  text-align: center;
}
.nk-widget-match .nk-widget-match-teams .nk-widget-match-team-logo img {
  width: 47px;
  height: auto;
  border-radius: 4px;
}
.nk-widget-match
  .nk-widget-match-teams
  .nk-widget-match-vs
  + .nk-widget-match-team-logo {
  text-align: right;
}
.nk-widget-match .nk-widget-match-date {
  margin-top: 5px;
  font-size: 0.9em;
}
.nk-sidebar {
  position: relative;
  height: 100%;
}
.nk-social-links,
.nk-social-links-2,
.nk-social-links-3 {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  margin-right: -2px;
  margin-left: -2px;
  list-style-type: none;
}
.nk-social-links-2.nk-social-links-center,
.nk-social-links-3.nk-social-links-center,
.nk-social-links.nk-social-links-center {
  justify-content: center;
}
.nk-social-links-2.nk-social-links-left,
.nk-social-links-3.nk-social-links-left,
.nk-social-links.nk-social-links-left {
  justify-content: flex-start;
}
.nk-social-links-2.nk-social-links-right,
.nk-social-links-3.nk-social-links-right,
.nk-social-links.nk-social-links-right {
  justify-content: flex-end;
}
.nk-social-links-2 > li > *,
.nk-social-links-3 > li > *,
.nk-social-links > li > * {
  display: block;
  width: 20px;
  height: 20px;
  margin: 3px;
  font-size: 0.9em;
  line-height: 20px;
  text-align: center;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.75);
  border-radius: 10px;
  transition: 0.15s background-color, 0.15s color;
}
.nk-social-links-2 > li > *,
.nk-social-links-2 > li > :active,
.nk-social-links-2 > li > :focus,
.nk-social-links-2 > li > :hover,
.nk-social-links-3 > li > *,
.nk-social-links-3 > li > :active,
.nk-social-links-3 > li > :focus,
.nk-social-links-3 > li > :hover,
.nk-social-links > li > *,
.nk-social-links > li > :active,
.nk-social-links > li > :focus,
.nk-social-links > li > :hover {
  color: #181c23;
}
.nk-social-links-2 > li > .hover,
.nk-social-links-2 > li > :hover,
.nk-social-links-3 > li > .hover,
.nk-social-links-3 > li > :hover,
.nk-social-links > li > .hover,
.nk-social-links > li > :hover {
  color: #fff;
  background-color: #dd163b;
}
.nk-social-links-2.nk-social-links-3 > li > .nk-social-rss.hover,
.nk-social-links-2.nk-social-links-3 > li > .nk-social-rss:hover,
.nk-social-links-2 > li > .nk-social-rss.hover,
.nk-social-links-2 > li > .nk-social-rss:hover,
.nk-social-links-3.nk-social-links-3 > li > .nk-social-rss.hover,
.nk-social-links-3.nk-social-links-3 > li > .nk-social-rss:hover,
.nk-social-links-3 > li > .nk-social-rss.hover,
.nk-social-links-3 > li > .nk-social-rss:hover,
.nk-social-links.nk-social-links-3 > li > .nk-social-rss.hover,
.nk-social-links.nk-social-links-3 > li > .nk-social-rss:hover,
.nk-social-links > li > .nk-social-rss.hover,
.nk-social-links > li > .nk-social-rss:hover {
  color: #fff;
  background-color: #e06618;
}
.nk-social-links-2.nk-social-links-3 > li > .nk-social-behance.hover,
.nk-social-links-2.nk-social-links-3 > li > .nk-social-behance:hover,
.nk-social-links-2 > li > .nk-social-behance.hover,
.nk-social-links-2 > li > .nk-social-behance:hover,
.nk-social-links-3.nk-social-links-3 > li > .nk-social-behance.hover,
.nk-social-links-3.nk-social-links-3 > li > .nk-social-behance:hover,
.nk-social-links-3 > li > .nk-social-behance.hover,
.nk-social-links-3 > li > .nk-social-behance:hover,
.nk-social-links.nk-social-links-3 > li > .nk-social-behance.hover,
.nk-social-links.nk-social-links-3 > li > .nk-social-behance:hover,
.nk-social-links > li > .nk-social-behance.hover,
.nk-social-links > li > .nk-social-behance:hover {
  color: #fff;
  background-color: #487cfb;
}
.nk-social-links-2.nk-social-links-3 > li > .nk-social-bitbucket.hover,
.nk-social-links-2.nk-social-links-3 > li > .nk-social-bitbucket:hover,
.nk-social-links-2 > li > .nk-social-bitbucket.hover,
.nk-social-links-2 > li > .nk-social-bitbucket:hover,
.nk-social-links-3.nk-social-links-3 > li > .nk-social-bitbucket.hover,
.nk-social-links-3.nk-social-links-3 > li > .nk-social-bitbucket:hover,
.nk-social-links-3 > li > .nk-social-bitbucket.hover,
.nk-social-links-3 > li > .nk-social-bitbucket:hover,
.nk-social-links.nk-social-links-3 > li > .nk-social-bitbucket.hover,
.nk-social-links.nk-social-links-3 > li > .nk-social-bitbucket:hover,
.nk-social-links > li > .nk-social-bitbucket.hover,
.nk-social-links > li > .nk-social-bitbucket:hover {
  color: #fff;
  background-color: #36517e;
}
.nk-social-links-2.nk-social-links-3 > li > .nk-social-dropbox.hover,
.nk-social-links-2.nk-social-links-3 > li > .nk-social-dropbox:hover,
.nk-social-links-2 > li > .nk-social-dropbox.hover,
.nk-social-links-2 > li > .nk-social-dropbox:hover,
.nk-social-links-3.nk-social-links-3 > li > .nk-social-dropbox.hover,
.nk-social-links-3.nk-social-links-3 > li > .nk-social-dropbox:hover,
.nk-social-links-3 > li > .nk-social-dropbox.hover,
.nk-social-links-3 > li > .nk-social-dropbox:hover,
.nk-social-links.nk-social-links-3 > li > .nk-social-dropbox.hover,
.nk-social-links.nk-social-links-3 > li > .nk-social-dropbox:hover,
.nk-social-links > li > .nk-social-dropbox.hover,
.nk-social-links > li > .nk-social-dropbox:hover {
  color: #fff;
  background-color: #4d86d9;
}
.nk-social-links-2.nk-social-links-3 > li > .nk-social-dribbble.hover,
.nk-social-links-2.nk-social-links-3 > li > .nk-social-dribbble:hover,
.nk-social-links-2 > li > .nk-social-dribbble.hover,
.nk-social-links-2 > li > .nk-social-dribbble:hover,
.nk-social-links-3.nk-social-links-3 > li > .nk-social-dribbble.hover,
.nk-social-links-3.nk-social-links-3 > li > .nk-social-dribbble:hover,
.nk-social-links-3 > li > .nk-social-dribbble.hover,
.nk-social-links-3 > li > .nk-social-dribbble:hover,
.nk-social-links.nk-social-links-3 > li > .nk-social-dribbble.hover,
.nk-social-links.nk-social-links-3 > li > .nk-social-dribbble:hover,
.nk-social-links > li > .nk-social-dribbble.hover,
.nk-social-links > li > .nk-social-dribbble:hover {
  color: #fff;
  background-color: #ea4c89;
}
.nk-social-links-2.nk-social-links-3 > li > .nk-social-deviantart.hover,
.nk-social-links-2.nk-social-links-3 > li > .nk-social-deviantart:hover,
.nk-social-links-2 > li > .nk-social-deviantart.hover,
.nk-social-links-2 > li > .nk-social-deviantart:hover,
.nk-social-links-3.nk-social-links-3 > li > .nk-social-deviantart.hover,
.nk-social-links-3.nk-social-links-3 > li > .nk-social-deviantart:hover,
.nk-social-links-3 > li > .nk-social-deviantart.hover,
.nk-social-links-3 > li > .nk-social-deviantart:hover,
.nk-social-links.nk-social-links-3 > li > .nk-social-deviantart.hover,
.nk-social-links.nk-social-links-3 > li > .nk-social-deviantart:hover,
.nk-social-links > li > .nk-social-deviantart.hover,
.nk-social-links > li > .nk-social-deviantart:hover {
  color: #fff;
  background-color: #4a5d4e;
}
.nk-social-links-2.nk-social-links-3 > li > .nk-social-facebook.hover,
.nk-social-links-2.nk-social-links-3 > li > .nk-social-facebook:hover,
.nk-social-links-2 > li > .nk-social-facebook.hover,
.nk-social-links-2 > li > .nk-social-facebook:hover,
.nk-social-links-3.nk-social-links-3 > li > .nk-social-facebook.hover,
.nk-social-links-3.nk-social-links-3 > li > .nk-social-facebook:hover,
.nk-social-links-3 > li > .nk-social-facebook.hover,
.nk-social-links-3 > li > .nk-social-facebook:hover,
.nk-social-links.nk-social-links-3 > li > .nk-social-facebook.hover,
.nk-social-links.nk-social-links-3 > li > .nk-social-facebook:hover,
.nk-social-links > li > .nk-social-facebook.hover,
.nk-social-links > li > .nk-social-facebook:hover {
  color: #fff;
  background-color: #3b5998;
}
.nk-social-links-2.nk-social-links-3 > li > .nk-social-flickr.hover,
.nk-social-links-2.nk-social-links-3 > li > .nk-social-flickr:hover,
.nk-social-links-2 > li > .nk-social-flickr.hover,
.nk-social-links-2 > li > .nk-social-flickr:hover,
.nk-social-links-3.nk-social-links-3 > li > .nk-social-flickr.hover,
.nk-social-links-3.nk-social-links-3 > li > .nk-social-flickr:hover,
.nk-social-links-3 > li > .nk-social-flickr.hover,
.nk-social-links-3 > li > .nk-social-flickr:hover,
.nk-social-links.nk-social-links-3 > li > .nk-social-flickr.hover,
.nk-social-links.nk-social-links-3 > li > .nk-social-flickr:hover,
.nk-social-links > li > .nk-social-flickr.hover,
.nk-social-links > li > .nk-social-flickr:hover {
  color: #fff;
  background-color: #f40083;
}
.nk-social-links-2.nk-social-links-3 > li > .nk-social-foursquare.hover,
.nk-social-links-2.nk-social-links-3 > li > .nk-social-foursquare:hover,
.nk-social-links-2 > li > .nk-social-foursquare.hover,
.nk-social-links-2 > li > .nk-social-foursquare:hover,
.nk-social-links-3.nk-social-links-3 > li > .nk-social-foursquare.hover,
.nk-social-links-3.nk-social-links-3 > li > .nk-social-foursquare:hover,
.nk-social-links-3 > li > .nk-social-foursquare.hover,
.nk-social-links-3 > li > .nk-social-foursquare:hover,
.nk-social-links.nk-social-links-3 > li > .nk-social-foursquare.hover,
.nk-social-links.nk-social-links-3 > li > .nk-social-foursquare:hover,
.nk-social-links > li > .nk-social-foursquare.hover,
.nk-social-links > li > .nk-social-foursquare:hover {
  color: #fff;
  background-color: #fc4575;
}
.nk-social-links-2.nk-social-links-3 > li > .nk-social-github.hover,
.nk-social-links-2.nk-social-links-3 > li > .nk-social-github:hover,
.nk-social-links-2 > li > .nk-social-github.hover,
.nk-social-links-2 > li > .nk-social-github:hover,
.nk-social-links-3.nk-social-links-3 > li > .nk-social-github.hover,
.nk-social-links-3.nk-social-links-3 > li > .nk-social-github:hover,
.nk-social-links-3 > li > .nk-social-github.hover,
.nk-social-links-3 > li > .nk-social-github:hover,
.nk-social-links.nk-social-links-3 > li > .nk-social-github.hover,
.nk-social-links.nk-social-links-3 > li > .nk-social-github:hover,
.nk-social-links > li > .nk-social-github.hover,
.nk-social-links > li > .nk-social-github:hover {
  color: #fff;
  background-color: #464646;
}
.nk-social-links-2.nk-social-links-3 > li > .nk-social-google-plus.hover,
.nk-social-links-2.nk-social-links-3 > li > .nk-social-google-plus:hover,
.nk-social-links-2 > li > .nk-social-google-plus.hover,
.nk-social-links-2 > li > .nk-social-google-plus:hover,
.nk-social-links-3.nk-social-links-3 > li > .nk-social-google-plus.hover,
.nk-social-links-3.nk-social-links-3 > li > .nk-social-google-plus:hover,
.nk-social-links-3 > li > .nk-social-google-plus.hover,
.nk-social-links-3 > li > .nk-social-google-plus:hover,
.nk-social-links.nk-social-links-3 > li > .nk-social-google-plus.hover,
.nk-social-links.nk-social-links-3 > li > .nk-social-google-plus:hover,
.nk-social-links > li > .nk-social-google-plus.hover,
.nk-social-links > li > .nk-social-google-plus:hover {
  color: #fff;
  background-color: #df4a32;
}
.nk-social-links-2.nk-social-links-3 > li > .nk-social-instagram.hover,
.nk-social-links-2.nk-social-links-3 > li > .nk-social-instagram:hover,
.nk-social-links-2 > li > .nk-social-instagram.hover,
.nk-social-links-2 > li > .nk-social-instagram:hover,
.nk-social-links-3.nk-social-links-3 > li > .nk-social-instagram.hover,
.nk-social-links-3.nk-social-links-3 > li > .nk-social-instagram:hover,
.nk-social-links-3 > li > .nk-social-instagram.hover,
.nk-social-links-3 > li > .nk-social-instagram:hover,
.nk-social-links.nk-social-links-3 > li > .nk-social-instagram.hover,
.nk-social-links.nk-social-links-3 > li > .nk-social-instagram:hover,
.nk-social-links > li > .nk-social-instagram.hover,
.nk-social-links > li > .nk-social-instagram:hover {
  color: #fff;
  background-color: #527198;
}
.nk-social-links-2.nk-social-links-3 > li > .nk-social-linkedin.hover,
.nk-social-links-2.nk-social-links-3 > li > .nk-social-linkedin:hover,
.nk-social-links-2 > li > .nk-social-linkedin.hover,
.nk-social-links-2 > li > .nk-social-linkedin:hover,
.nk-social-links-3.nk-social-links-3 > li > .nk-social-linkedin.hover,
.nk-social-links-3.nk-social-links-3 > li > .nk-social-linkedin:hover,
.nk-social-links-3 > li > .nk-social-linkedin.hover,
.nk-social-links-3 > li > .nk-social-linkedin:hover,
.nk-social-links.nk-social-links-3 > li > .nk-social-linkedin.hover,
.nk-social-links.nk-social-links-3 > li > .nk-social-linkedin:hover,
.nk-social-links > li > .nk-social-linkedin.hover,
.nk-social-links > li > .nk-social-linkedin:hover {
  color: #fff;
  background-color: #007bb6;
}
.nk-social-links-2.nk-social-links-3 > li > .nk-social-medium.hover,
.nk-social-links-2.nk-social-links-3 > li > .nk-social-medium:hover,
.nk-social-links-2 > li > .nk-social-medium.hover,
.nk-social-links-2 > li > .nk-social-medium:hover,
.nk-social-links-3.nk-social-links-3 > li > .nk-social-medium.hover,
.nk-social-links-3.nk-social-links-3 > li > .nk-social-medium:hover,
.nk-social-links-3 > li > .nk-social-medium.hover,
.nk-social-links-3 > li > .nk-social-medium:hover,
.nk-social-links.nk-social-links-3 > li > .nk-social-medium.hover,
.nk-social-links.nk-social-links-3 > li > .nk-social-medium:hover,
.nk-social-links > li > .nk-social-medium.hover,
.nk-social-links > li > .nk-social-medium:hover {
  color: #fff;
  background-color: #71c66d;
}
.nk-social-links-2.nk-social-links-3 > li > .nk-social-odnoklassniki.hover,
.nk-social-links-2.nk-social-links-3 > li > .nk-social-odnoklassniki:hover,
.nk-social-links-2 > li > .nk-social-odnoklassniki.hover,
.nk-social-links-2 > li > .nk-social-odnoklassniki:hover,
.nk-social-links-3.nk-social-links-3 > li > .nk-social-odnoklassniki.hover,
.nk-social-links-3.nk-social-links-3 > li > .nk-social-odnoklassniki:hover,
.nk-social-links-3 > li > .nk-social-odnoklassniki.hover,
.nk-social-links-3 > li > .nk-social-odnoklassniki:hover,
.nk-social-links.nk-social-links-3 > li > .nk-social-odnoklassniki.hover,
.nk-social-links.nk-social-links-3 > li > .nk-social-odnoklassniki:hover,
.nk-social-links > li > .nk-social-odnoklassniki.hover,
.nk-social-links > li > .nk-social-odnoklassniki:hover {
  color: #fff;
  background-color: #d8732a;
}
.nk-social-links-2.nk-social-links-3 > li > .nk-social-paypal.hover,
.nk-social-links-2.nk-social-links-3 > li > .nk-social-paypal:hover,
.nk-social-links-2 > li > .nk-social-paypal.hover,
.nk-social-links-2 > li > .nk-social-paypal:hover,
.nk-social-links-3.nk-social-links-3 > li > .nk-social-paypal.hover,
.nk-social-links-3.nk-social-links-3 > li > .nk-social-paypal:hover,
.nk-social-links-3 > li > .nk-social-paypal.hover,
.nk-social-links-3 > li > .nk-social-paypal:hover,
.nk-social-links.nk-social-links-3 > li > .nk-social-paypal.hover,
.nk-social-links.nk-social-links-3 > li > .nk-social-paypal:hover,
.nk-social-links > li > .nk-social-paypal.hover,
.nk-social-links > li > .nk-social-paypal:hover {
  color: #fff;
  background-color: #1d3283;
}
.nk-social-links-2.nk-social-links-3 > li > .nk-social-pinterest.hover,
.nk-social-links-2.nk-social-links-3 > li > .nk-social-pinterest:hover,
.nk-social-links-2 > li > .nk-social-pinterest.hover,
.nk-social-links-2 > li > .nk-social-pinterest:hover,
.nk-social-links-3.nk-social-links-3 > li > .nk-social-pinterest.hover,
.nk-social-links-3.nk-social-links-3 > li > .nk-social-pinterest:hover,
.nk-social-links-3 > li > .nk-social-pinterest.hover,
.nk-social-links-3 > li > .nk-social-pinterest:hover,
.nk-social-links.nk-social-links-3 > li > .nk-social-pinterest.hover,
.nk-social-links.nk-social-links-3 > li > .nk-social-pinterest:hover,
.nk-social-links > li > .nk-social-pinterest.hover,
.nk-social-links > li > .nk-social-pinterest:hover {
  color: #fff;
  background-color: #cb2027;
}
.nk-social-links-2.nk-social-links-3 > li > .nk-social-reddit.hover,
.nk-social-links-2.nk-social-links-3 > li > .nk-social-reddit:hover,
.nk-social-links-2 > li > .nk-social-reddit.hover,
.nk-social-links-2 > li > .nk-social-reddit:hover,
.nk-social-links-3.nk-social-links-3 > li > .nk-social-reddit.hover,
.nk-social-links-3.nk-social-links-3 > li > .nk-social-reddit:hover,
.nk-social-links-3 > li > .nk-social-reddit.hover,
.nk-social-links-3 > li > .nk-social-reddit:hover,
.nk-social-links.nk-social-links-3 > li > .nk-social-reddit.hover,
.nk-social-links.nk-social-links-3 > li > .nk-social-reddit:hover,
.nk-social-links > li > .nk-social-reddit.hover,
.nk-social-links > li > .nk-social-reddit:hover {
  color: #fff;
  background-color: #ff5700;
}
.nk-social-links-2.nk-social-links-3 > li > .nk-social-skype.hover,
.nk-social-links-2.nk-social-links-3 > li > .nk-social-skype:hover,
.nk-social-links-2 > li > .nk-social-skype.hover,
.nk-social-links-2 > li > .nk-social-skype:hover,
.nk-social-links-3.nk-social-links-3 > li > .nk-social-skype.hover,
.nk-social-links-3.nk-social-links-3 > li > .nk-social-skype:hover,
.nk-social-links-3 > li > .nk-social-skype.hover,
.nk-social-links-3 > li > .nk-social-skype:hover,
.nk-social-links.nk-social-links-3 > li > .nk-social-skype.hover,
.nk-social-links.nk-social-links-3 > li > .nk-social-skype:hover,
.nk-social-links > li > .nk-social-skype.hover,
.nk-social-links > li > .nk-social-skype:hover {
  color: #fff;
  background-color: #00aff0;
}
.nk-social-links-2.nk-social-links-3 > li > .nk-social-soundcloud.hover,
.nk-social-links-2.nk-social-links-3 > li > .nk-social-soundcloud:hover,
.nk-social-links-2 > li > .nk-social-soundcloud.hover,
.nk-social-links-2 > li > .nk-social-soundcloud:hover,
.nk-social-links-3.nk-social-links-3 > li > .nk-social-soundcloud.hover,
.nk-social-links-3.nk-social-links-3 > li > .nk-social-soundcloud:hover,
.nk-social-links-3 > li > .nk-social-soundcloud.hover,
.nk-social-links-3 > li > .nk-social-soundcloud:hover,
.nk-social-links.nk-social-links-3 > li > .nk-social-soundcloud.hover,
.nk-social-links.nk-social-links-3 > li > .nk-social-soundcloud:hover,
.nk-social-links > li > .nk-social-soundcloud.hover,
.nk-social-links > li > .nk-social-soundcloud:hover {
  color: #fff;
  background-color: #f50;
}
.nk-social-links-2.nk-social-links-3 > li > .nk-social-slack.hover,
.nk-social-links-2.nk-social-links-3 > li > .nk-social-slack:hover,
.nk-social-links-2 > li > .nk-social-slack.hover,
.nk-social-links-2 > li > .nk-social-slack:hover,
.nk-social-links-3.nk-social-links-3 > li > .nk-social-slack.hover,
.nk-social-links-3.nk-social-links-3 > li > .nk-social-slack:hover,
.nk-social-links-3 > li > .nk-social-slack.hover,
.nk-social-links-3 > li > .nk-social-slack:hover,
.nk-social-links.nk-social-links-3 > li > .nk-social-slack.hover,
.nk-social-links.nk-social-links-3 > li > .nk-social-slack:hover,
.nk-social-links > li > .nk-social-slack.hover,
.nk-social-links > li > .nk-social-slack:hover {
  color: #fff;
  background-color: #543b56;
}
.nk-social-links-2.nk-social-links-3 > li > .nk-social-steam.hover,
.nk-social-links-2.nk-social-links-3 > li > .nk-social-steam:hover,
.nk-social-links-2 > li > .nk-social-steam.hover,
.nk-social-links-2 > li > .nk-social-steam:hover,
.nk-social-links-3.nk-social-links-3 > li > .nk-social-steam.hover,
.nk-social-links-3.nk-social-links-3 > li > .nk-social-steam:hover,
.nk-social-links-3 > li > .nk-social-steam.hover,
.nk-social-links-3 > li > .nk-social-steam:hover,
.nk-social-links.nk-social-links-3 > li > .nk-social-steam.hover,
.nk-social-links.nk-social-links-3 > li > .nk-social-steam:hover,
.nk-social-links > li > .nk-social-steam.hover,
.nk-social-links > li > .nk-social-steam:hover {
  color: #fff;
  background-color: #272425;
}
.nk-social-links-2.nk-social-links-3 > li > .nk-social-snapchat.hover,
.nk-social-links-2.nk-social-links-3 > li > .nk-social-snapchat:hover,
.nk-social-links-2 > li > .nk-social-snapchat.hover,
.nk-social-links-2 > li > .nk-social-snapchat:hover,
.nk-social-links-3.nk-social-links-3 > li > .nk-social-snapchat.hover,
.nk-social-links-3.nk-social-links-3 > li > .nk-social-snapchat:hover,
.nk-social-links-3 > li > .nk-social-snapchat.hover,
.nk-social-links-3 > li > .nk-social-snapchat:hover,
.nk-social-links.nk-social-links-3 > li > .nk-social-snapchat.hover,
.nk-social-links.nk-social-links-3 > li > .nk-social-snapchat:hover,
.nk-social-links > li > .nk-social-snapchat.hover,
.nk-social-links > li > .nk-social-snapchat:hover {
  color: #fff;
  background-color: #fffc00;
}
.nk-social-links-2.nk-social-links-3 > li > .nk-social-swarm.hover,
.nk-social-links-2.nk-social-links-3 > li > .nk-social-swarm:hover,
.nk-social-links-2 > li > .nk-social-swarm.hover,
.nk-social-links-2 > li > .nk-social-swarm:hover,
.nk-social-links-3.nk-social-links-3 > li > .nk-social-swarm.hover,
.nk-social-links-3.nk-social-links-3 > li > .nk-social-swarm:hover,
.nk-social-links-3 > li > .nk-social-swarm.hover,
.nk-social-links-3 > li > .nk-social-swarm:hover,
.nk-social-links.nk-social-links-3 > li > .nk-social-swarm.hover,
.nk-social-links.nk-social-links-3 > li > .nk-social-swarm:hover,
.nk-social-links > li > .nk-social-swarm.hover,
.nk-social-links > li > .nk-social-swarm:hover {
  color: #fff;
  background-color: #ffb000;
}
.nk-social-links-2.nk-social-links-3 > li > .nk-social-stumbleupon.hover,
.nk-social-links-2.nk-social-links-3 > li > .nk-social-stumbleupon:hover,
.nk-social-links-2 > li > .nk-social-stumbleupon.hover,
.nk-social-links-2 > li > .nk-social-stumbleupon:hover,
.nk-social-links-3.nk-social-links-3 > li > .nk-social-stumbleupon.hover,
.nk-social-links-3.nk-social-links-3 > li > .nk-social-stumbleupon:hover,
.nk-social-links-3 > li > .nk-social-stumbleupon.hover,
.nk-social-links-3 > li > .nk-social-stumbleupon:hover,
.nk-social-links.nk-social-links-3 > li > .nk-social-stumbleupon.hover,
.nk-social-links.nk-social-links-3 > li > .nk-social-stumbleupon:hover,
.nk-social-links > li > .nk-social-stumbleupon.hover,
.nk-social-links > li > .nk-social-stumbleupon:hover {
  color: #fff;
  background-color: #eb4924;
}
.nk-social-links-2.nk-social-links-3 > li > .nk-social-spotify.hover,
.nk-social-links-2.nk-social-links-3 > li > .nk-social-spotify:hover,
.nk-social-links-2 > li > .nk-social-spotify.hover,
.nk-social-links-2 > li > .nk-social-spotify:hover,
.nk-social-links-3.nk-social-links-3 > li > .nk-social-spotify.hover,
.nk-social-links-3.nk-social-links-3 > li > .nk-social-spotify:hover,
.nk-social-links-3 > li > .nk-social-spotify.hover,
.nk-social-links-3 > li > .nk-social-spotify:hover,
.nk-social-links.nk-social-links-3 > li > .nk-social-spotify.hover,
.nk-social-links.nk-social-links-3 > li > .nk-social-spotify:hover,
.nk-social-links > li > .nk-social-spotify.hover,
.nk-social-links > li > .nk-social-spotify:hover {
  color: #fff;
  background-color: #1ed760;
}
.nk-social-links-2.nk-social-links-3 > li > .nk-social-tumblr.hover,
.nk-social-links-2.nk-social-links-3 > li > .nk-social-tumblr:hover,
.nk-social-links-2 > li > .nk-social-tumblr.hover,
.nk-social-links-2 > li > .nk-social-tumblr:hover,
.nk-social-links-3.nk-social-links-3 > li > .nk-social-tumblr.hover,
.nk-social-links-3.nk-social-links-3 > li > .nk-social-tumblr:hover,
.nk-social-links-3 > li > .nk-social-tumblr.hover,
.nk-social-links-3 > li > .nk-social-tumblr:hover,
.nk-social-links.nk-social-links-3 > li > .nk-social-tumblr.hover,
.nk-social-links.nk-social-links-3 > li > .nk-social-tumblr:hover,
.nk-social-links > li > .nk-social-tumblr.hover,
.nk-social-links > li > .nk-social-tumblr:hover {
  color: #fff;
  background-color: #2c4762;
}
.nk-social-links-2.nk-social-links-3 > li > .nk-social-twitch.hover,
.nk-social-links-2.nk-social-links-3 > li > .nk-social-twitch:hover,
.nk-social-links-2 > li > .nk-social-twitch.hover,
.nk-social-links-2 > li > .nk-social-twitch:hover,
.nk-social-links-3.nk-social-links-3 > li > .nk-social-twitch.hover,
.nk-social-links-3.nk-social-links-3 > li > .nk-social-twitch:hover,
.nk-social-links-3 > li > .nk-social-twitch.hover,
.nk-social-links-3 > li > .nk-social-twitch:hover,
.nk-social-links.nk-social-links-3 > li > .nk-social-twitch.hover,
.nk-social-links.nk-social-links-3 > li > .nk-social-twitch:hover,
.nk-social-links > li > .nk-social-twitch.hover,
.nk-social-links > li > .nk-social-twitch:hover {
  color: #fff;
  background-color: #5c43a1;
}
.nk-social-links-2.nk-social-links-3 > li > .nk-social-twitter.hover,
.nk-social-links-2.nk-social-links-3 > li > .nk-social-twitter:hover,
.nk-social-links-2 > li > .nk-social-twitter.hover,
.nk-social-links-2 > li > .nk-social-twitter:hover,
.nk-social-links-3.nk-social-links-3 > li > .nk-social-twitter.hover,
.nk-social-links-3.nk-social-links-3 > li > .nk-social-twitter:hover,
.nk-social-links-3 > li > .nk-social-twitter.hover,
.nk-social-links-3 > li > .nk-social-twitter:hover,
.nk-social-links.nk-social-links-3 > li > .nk-social-twitter.hover,
.nk-social-links.nk-social-links-3 > li > .nk-social-twitter:hover,
.nk-social-links > li > .nk-social-twitter.hover,
.nk-social-links > li > .nk-social-twitter:hover {
  color: #fff;
  background-color: #00b6f1;
}
.nk-social-links > li > *.nk-social-telegram:hover, .nk-social-links > li > *.nk-social-telegram.hover, .nk-social-links.nk-social-links-3 > li > *.nk-social-telegram:hover, .nk-social-links.nk-social-links-3 > li > *.nk-social-telegram.hover,
.nk-social-links-2 > li > *.nk-social-telegram:hover,
.nk-social-links-2 > li > *.nk-social-telegram.hover,
.nk-social-links-2.nk-social-links-3 > li > *.nk-social-telegram:hover,
.nk-social-links-2.nk-social-links-3 > li > *.nk-social-telegram.hover,
.nk-social-links-3 > li > *.nk-social-telegram:hover,
.nk-social-links-3 > li > *.nk-social-telegram.hover,
.nk-social-links-3.nk-social-links-3 > li > *.nk-social-telegram:hover,
.nk-social-links-3.nk-social-links-3 > li > *.nk-social-telegram.hover {
    color: #fff;
    background-color: #5a58e6;
}
.nk-social-links-2.nk-social-links-3 > li > .nk-social-vimeo.hover,
.nk-social-links-2.nk-social-links-3 > li > .nk-social-vimeo:hover,
.nk-social-links-2 > li > .nk-social-vimeo.hover,
.nk-social-links-2 > li > .nk-social-vimeo:hover,
.nk-social-links-3.nk-social-links-3 > li > .nk-social-vimeo.hover,
.nk-social-links-3.nk-social-links-3 > li > .nk-social-vimeo:hover,
.nk-social-links-3 > li > .nk-social-vimeo.hover,
.nk-social-links-3 > li > .nk-social-vimeo:hover,
.nk-social-links.nk-social-links-3 > li > .nk-social-vimeo.hover,
.nk-social-links.nk-social-links-3 > li > .nk-social-vimeo:hover,
.nk-social-links > li > .nk-social-vimeo.hover,
.nk-social-links > li > .nk-social-vimeo:hover {
  color: #fff;
  background-color: #45bbff;
}
.nk-social-links-2.nk-social-links-3 > li > .nk-social-vk.hover,
.nk-social-links-2.nk-social-links-3 > li > .nk-social-vk:hover,
.nk-social-links-2 > li > .nk-social-vk.hover,
.nk-social-links-2 > li > .nk-social-vk:hover,
.nk-social-links-3.nk-social-links-3 > li > .nk-social-vk.hover,
.nk-social-links-3.nk-social-links-3 > li > .nk-social-vk:hover,
.nk-social-links-3 > li > .nk-social-vk.hover,
.nk-social-links-3 > li > .nk-social-vk:hover,
.nk-social-links.nk-social-links-3 > li > .nk-social-vk.hover,
.nk-social-links.nk-social-links-3 > li > .nk-social-vk:hover,
.nk-social-links > li > .nk-social-vk.hover,
.nk-social-links > li > .nk-social-vk:hover {
  color: #fff;
  background-color: #657da0;
}
.nk-social-links-2.nk-social-links-3 > li > .nk-social-wordpress.hover,
.nk-social-links-2.nk-social-links-3 > li > .nk-social-wordpress:hover,
.nk-social-links-2 > li > .nk-social-wordpress.hover,
.nk-social-links-2 > li > .nk-social-wordpress:hover,
.nk-social-links-3.nk-social-links-3 > li > .nk-social-wordpress.hover,
.nk-social-links-3.nk-social-links-3 > li > .nk-social-wordpress:hover,
.nk-social-links-3 > li > .nk-social-wordpress.hover,
.nk-social-links-3 > li > .nk-social-wordpress:hover,
.nk-social-links.nk-social-links-3 > li > .nk-social-wordpress.hover,
.nk-social-links.nk-social-links-3 > li > .nk-social-wordpress:hover,
.nk-social-links > li > .nk-social-wordpress.hover,
.nk-social-links > li > .nk-social-wordpress:hover {
  color: #fff;
  background-color: #4072a1;
}
.nk-social-links-2.nk-social-links-3 > li > .nk-social-youtube.hover,
.nk-social-links-2.nk-social-links-3 > li > .nk-social-youtube:hover,
.nk-social-links-2 > li > .nk-social-youtube.hover,
.nk-social-links-2 > li > .nk-social-youtube:hover,
.nk-social-links-3.nk-social-links-3 > li > .nk-social-youtube.hover,
.nk-social-links-3.nk-social-links-3 > li > .nk-social-youtube:hover,
.nk-social-links-3 > li > .nk-social-youtube.hover,
.nk-social-links-3 > li > .nk-social-youtube:hover,
.nk-social-links.nk-social-links-3 > li > .nk-social-youtube.hover,
.nk-social-links.nk-social-links-3 > li > .nk-social-youtube:hover,
.nk-social-links > li > .nk-social-youtube.hover,
.nk-social-links > li > .nk-social-youtube:hover {
  color: #fff;
  background-color: red;
}
.nk-social-links-2 {
  margin-right: -4px;
  margin-left: -4px;
}
.nk-social-links-2 > li > * {
  width: 26px;
  height: 26px;
  margin: 3px 5px;
  font-size: 1.1em;
  line-height: 26px;
  background-color: #fff;
  border-radius: 13px;
}
.nk-social-links-3 > li > * {
  position: relative;
  width: 100%;
  padding-top: 100%;
  margin: 0;
  font-size: 1.2em;
  color: #fff;
  background-color: #292e38;
  border-radius: 0;
}
.nk-social-links-3 > li > * > span,
.nk-social-links-3 > li > * > svg {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 14px;
  height: 14px;
  margin-top: -7px;
  margin-left: -7px;
}
.nk-social-links-3 > li > * > svg {
  width: 18px;
  height: 18px;
  margin-top: -9px;
  margin-left: -9px;
}
.nk-social-links-3 > li > * > svg.svg-inline--fa {
  width: 18px;
}
.nk-social-links-3.nk-social-links-cols-5 > li {
  width: 20%;
}
.nk-social-links-3.nk-social-links-cols-5 > li:nth-child(6n) > a {
  background-color: #2f3541;
}
.nk-social-links-3.nk-social-links-cols-5 > li:nth-child(6n + 2) > a {
  background-color: #363d4a;
}
.nk-social-links-3.nk-social-links-cols-5 > li:nth-child(6n + 4) > a {
  background-color: #3f4655;
}
.nk-social-links-3.nk-social-links-cols-4 > li {
  width: 25%;
}
.nk-social-links-3.nk-social-links-cols-4 > li:nth-child(5n) > a {
  background-color: #2f3541;
}
.nk-social-links-3.nk-social-links-cols-4 > li:nth-child(5n + 2) > a {
  background-color: #363d4a;
}
.nk-social-links-3.nk-social-links-cols-4 > li:nth-child(5n + 4) > a {
  background-color: #3f4655;
}
.nk-social-links-3.nk-social-links-cols-3 > li {
  width: 33.333%;
}
.nk-social-links-3.nk-social-links-cols-3 > li:nth-child(4n) > a {
  background-color: #2f3541;
}
.nk-social-links-3.nk-social-links-cols-3 > li:nth-child(4n + 2) > a {
  background-color: #363d4a;
}
.nk-social-links-3.nk-social-links-cols-3 > li:nth-child(4n + 4) > a {
  background-color: #3f4655;
}
.nk-social-links-3.nk-social-links-cols-2 > li {
  width: 50%;
}
.nk-social-links-3.nk-social-links-cols-2 > li:nth-child(3n) > a {
  background-color: #2f3541;
}
.nk-social-links-3.nk-social-links-cols-2 > li:nth-child(3n + 2) > a {
  background-color: #363d4a;
}
.nk-social-links-3.nk-social-links-cols-2 > li:nth-child(3n + 4) > a {
  background-color: #3f4655;
}
.nk-widget-highlighted .nk-widget-content .nk-social-links-3 {
  margin-top: -21px;
  margin-right: -24px;
  margin-bottom: -21px;
  margin-left: -24px;
}
.nk-contacts-icons {
  position: relative;
  padding: 0;
  margin: 0;
  margin-right: -20px;
  list-style-type: none;
}
@media (max-width: 767px) {
  .nk-contacts-icons {
    margin-right: 0;
  }
}
.nk-contacts-icons::after {
  content: "";
  display: block;
  clear: both;
}
.nk-contacts-icons > li {
  display: inline-block;
  margin: 3px 20px;
  line-height: 1.3;
  text-align: center;
}
.nk-contacts-icons > li > a {
  color: inherit;
  text-decoration: none;
  transition: 0.15s color;
}
.nk-contacts-icons > li.hover,
.nk-contacts-icons > li > a:active,
.nk-contacts-icons > li > a:focus,
.nk-contacts-icons > li > a:hover {
  color: #dd163b;
}
.nk-btn {
  display: inline-block;
  padding: 9px 19px;
  font-family: Montserrat, sans-serif;
  font-size: 0.87rem;
  font-weight: 600;
  line-height: 1.2;
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 0;
  transition: 0.15s all;
}
.nk-btn,
.nk-btn.active,
.nk-btn.hover,
.nk-btn:active,
.nk-btn:focus,
.nk-btn:hover {
  color: #fff;
  text-decoration: none;
}
.nk-btn.nk-btn-outline {
  padding-top: 8px;
  padding-bottom: 8px;
}
.nk-btn-xs {
  padding: 6px 15px;
  font-size: 0.8rem;
}
.nk-btn-xs.nk-btn-outline {
  padding-top: 5px;
  padding-bottom: 5px;
}
.nk-btn-sm {
  padding: 7px 17px;
}
.nk-btn-sm.nk-btn-outline {
  padding-top: 6px;
  padding-bottom: 6px;
}
.nk-btn-lg {
  padding: 12px 22px;
}
.nk-btn-lg.nk-btn-outline {
  padding-top: 11px;
  padding-bottom: 11px;
}
.nk-btn-x2 {
  padding: 14px 25px;
  font-size: 1rem;
}
.nk-btn-x2.nk-btn-outline {
  padding-top: 13px;
  padding-bottom: 13px;
}
.nk-btn-x3 {
  padding: 18px 30px;
  font-size: 1.1rem;
}
.nk-btn-x3.nk-btn-outline {
  padding-top: 17px;
  padding-bottom: 17px;
}
.nk-btn-x4 {
  padding: 20px 45px;
  font-size: 1.2rem;
}
.nk-btn-x4.nk-btn-outline {
  padding-top: 19px;
  padding-bottom: 19px;
}
.nk-btn-color-main-1 {
  background-color: #dd163b;
  border-color: #a5102c;
  border-style: solid;
}
.nk-btn-color-main-1.hover,
.nk-btn-color-main-1:hover {
  background-color: #eb3154;
  border-color: #ee526f;
}
.nk-btn-color-main-1.active,
.nk-btn-color-main-1:active {
  background-color: #ee526f;
  border-color: #f1728a;
}
.nk-btn-color-main-1.nk-btn-outline {
  color: #dd163b;
}
.nk-btn-color-main-1.nk-btn-outline.hover,
.nk-btn-color-main-1.nk-btn-outline:hover {
  color: #af112f;
}
.nk-btn-color-main-1.nk-btn-outline.active,
.nk-btn-color-main-1.nk-btn-outline:active {
  color: #800d22;
}
.nk-btn-color-main-2 {
  background-color: #a714b9;
  border-color: #750e82;
  border-style: solid;
}
.nk-btn-color-main-2.hover,
.nk-btn-color-main-2:hover {
  background-color: #c818de;
  border-color: #d531e9;
}
.nk-btn-color-main-2.active,
.nk-btn-color-main-2:active {
  background-color: #d531e9;
  border-color: #db51ec;
}
.nk-btn-color-main-2.nk-btn-outline {
  color: #a714b9;
}
.nk-btn-color-main-2.nk-btn-outline.hover,
.nk-btn-color-main-2.nk-btn-outline:hover {
  color: #7d0f8b;
}
.nk-btn-color-main-2.nk-btn-outline.active,
.nk-btn-color-main-2.nk-btn-outline:active {
  color: #540a5d;
}
.nk-btn-color-main-3 {
  background-color: #38a220;
  border-color: #266f16;
  border-style: solid;
}
.nk-btn-color-main-3.hover,
.nk-btn-color-main-3:hover {
  background-color: #44c427;
  border-color: #55d737;
}
.nk-btn-color-main-3.active,
.nk-btn-color-main-3:active {
  background-color: #55d737;
  border-color: #6edd55;
}
.nk-btn-color-main-3.nk-btn-outline {
  color: #38a220;
}
.nk-btn-color-main-3.nk-btn-outline.hover,
.nk-btn-color-main-3.nk-btn-outline:hover {
  color: #297718;
}
.nk-btn-color-main-3.nk-btn-outline.active,
.nk-btn-color-main-3.nk-btn-outline:active {
  color: #1b4d0f;
}
.nk-btn-color-main-4 {
  background-color: #22a2b1;
  border-color: #18737e;
  border-style: solid;
}
.nk-btn-color-main-4.hover,
.nk-btn-color-main-4:hover {
  background-color: #29c1d3;
  border-color: #44cbdb;
}
.nk-btn-color-main-4.active,
.nk-btn-color-main-4:active {
  background-color: #44cbdb;
  border-color: #62d4e1;
}
.nk-btn-color-main-4.nk-btn-outline {
  color: #22a2b1;
}
.nk-btn-color-main-4.nk-btn-outline.hover,
.nk-btn-color-main-4.nk-btn-outline:hover {
  color: #1a7b86;
}
.nk-btn-color-main-4.nk-btn-outline.active,
.nk-btn-color-main-4.nk-btn-outline:active {
  color: #12545b;
}
.nk-btn-color-main-5 {
  background-color: #1464d2;
  border-color: #0f499a;
  border-style: solid;
}
.nk-btn-color-main-5.hover,
.nk-btn-color-main-5:hover {
  background-color: #2578ea;
  border-color: #458ced;
}
.nk-btn-color-main-5.active,
.nk-btn-color-main-5:active {
  background-color: #458ced;
  border-color: #66a0f0;
}
.nk-btn-color-main-5.nk-btn-outline {
  color: #1464d2;
}
.nk-btn-color-main-5.nk-btn-outline.hover,
.nk-btn-color-main-5.nk-btn-outline:hover {
  color: #104ea3;
}
.nk-btn-color-main-5.nk-btn-outline.active,
.nk-btn-color-main-5.nk-btn-outline:active {
  color: #0b3875;
}
.nk-btn-color-main-6 {
  background-color: #ef9e2b;
  border-color: #cd7f0f;
  border-style: solid;
}
.nk-btn-color-main-6.hover,
.nk-btn-color-main-6:hover {
  background-color: #f2af51;
  border-color: #f4bf72;
}
.nk-btn-color-main-6.active,
.nk-btn-color-main-6:active {
  background-color: #f4bf72;
  border-color: #f7ce93;
}
.nk-btn-color-main-6.nk-btn-outline {
  color: #ef9e2b;
}
.nk-btn-color-main-6.nk-btn-outline.hover,
.nk-btn-color-main-6.nk-btn-outline:hover {
  color: #d78510;
}
.nk-btn-color-main-6.nk-btn-outline.active,
.nk-btn-color-main-6.nk-btn-outline:active {
  color: #a7670d;
}
.nk-btn-color-primary {
  background-color: #0275d8;
  border-color: #01549b;
  border-style: solid;
}
.nk-btn-color-primary.hover,
.nk-btn-color-primary:hover {
  background-color: #068bfd;
  border-color: #299bfd;
}
.nk-btn-color-primary.active,
.nk-btn-color-primary:active {
  background-color: #299bfd;
  border-color: #4dacfd;
}
.nk-btn-color-primary.nk-btn-outline {
  color: #0275d8;
}
.nk-btn-color-primary.nk-btn-outline.hover,
.nk-btn-color-primary.nk-btn-outline:hover {
  color: #025aa5;
}
.nk-btn-color-primary.nk-btn-outline.active,
.nk-btn-color-primary.nk-btn-outline:active {
  color: #013e73;
}
.nk-btn-color-success {
  background-color: #38a220;
  border-color: #266f16;
  border-style: solid;
}
.nk-btn-color-success.hover,
.nk-btn-color-success:hover {
  background-color: #44c427;
  border-color: #55d737;
}
.nk-btn-color-success.active,
.nk-btn-color-success:active {
  background-color: #55d737;
  border-color: #6edd55;
}
.nk-btn-color-success.nk-btn-outline {
  color: #38a220;
}
.nk-btn-color-success.nk-btn-outline.hover,
.nk-btn-color-success.nk-btn-outline:hover {
  color: #297718;
}
.nk-btn-color-success.nk-btn-outline.active,
.nk-btn-color-success.nk-btn-outline:active {
  color: #1b4d0f;
}
.nk-btn-color-info {
  background-color: #22a2b1;
  border-color: #18737e;
  border-style: solid;
}
.nk-btn-color-info.hover,
.nk-btn-color-info:hover {
  background-color: #29c1d3;
  border-color: #44cbdb;
}
.nk-btn-color-info.active,
.nk-btn-color-info:active {
  background-color: #44cbdb;
  border-color: #62d4e1;
}
.nk-btn-color-info.nk-btn-outline {
  color: #22a2b1;
}
.nk-btn-color-info.nk-btn-outline.hover,
.nk-btn-color-info.nk-btn-outline:hover {
  color: #1a7b86;
}
.nk-btn-color-info.nk-btn-outline.active,
.nk-btn-color-info.nk-btn-outline:active {
  color: #12545b;
}
.nk-btn-color-warning {
  background-color: #ef9e2b;
  border-color: #cd7f0f;
  border-style: solid;
}
.nk-btn-color-warning.hover,
.nk-btn-color-warning:hover {
  background-color: #f2af51;
  border-color: #f4bf72;
}
.nk-btn-color-warning.active,
.nk-btn-color-warning:active {
  background-color: #f4bf72;
  border-color: #f7ce93;
}
.nk-btn-color-warning.nk-btn-outline {
  color: #ef9e2b;
}
.nk-btn-color-warning.nk-btn-outline.hover,
.nk-btn-color-warning.nk-btn-outline:hover {
  color: #d78510;
}
.nk-btn-color-warning.nk-btn-outline.active,
.nk-btn-color-warning.nk-btn-outline:active {
  color: #a7670d;
}
.nk-btn-color-danger {
  background-color: #e2361f;
  border-color: #ad2816;
  border-style: solid;
}
.nk-btn-color-danger.hover,
.nk-btn-color-danger:hover {
  background-color: #e75643;
  border-color: #eb7363;
}
.nk-btn-color-danger.active,
.nk-btn-color-danger:active {
  background-color: #eb7363;
  border-color: #ef8f82;
}
.nk-btn-color-danger.nk-btn-outline {
  color: #e2361f;
}
.nk-btn-color-danger.nk-btn-outline.hover,
.nk-btn-color-danger.nk-btn-outline:hover {
  color: #b62a18;
}
.nk-btn-color-danger.nk-btn-outline.active,
.nk-btn-color-danger.nk-btn-outline:active {
  color: #892012;
}
.nk-btn-color-white {
  background-color: #fff;
  border-color: #e0e0e0;
  border-style: solid;
}
.nk-btn-color-white,
.nk-btn-color-white.active,
.nk-btn-color-white.hover,
.nk-btn-color-white:active,
.nk-btn-color-white:focus,
.nk-btn-color-white:hover {
  color: #181c23;
}
.nk-btn-color-white.hover,
.nk-btn-color-white:hover {
  background-color: #e0e0e0;
  border-color: #d1d1d1;
}
.nk-btn-color-white.active,
.nk-btn-color-white:active {
  background-color: #d9d9d9;
  border-color: #c7c7c7;
}
.nk-btn-color-white.nk-btn-outline {
  color: #fff;
}
.nk-btn-color-white.nk-btn-outline.hover,
.nk-btn-color-white.nk-btn-outline:hover {
  color: #e6e6e6;
}
.nk-btn-color-white.nk-btn-outline.active,
.nk-btn-color-white.nk-btn-outline:active {
  color: #ccc;
}
.nk-btn-color-black {
  background-color: #000;
  border-color: #000;
  border-style: solid;
}
.nk-btn-color-black.hover,
.nk-btn-color-black:hover {
  background-color: #141414;
  border-color: #262626;
}
.nk-btn-color-black.active,
.nk-btn-color-black:active {
  background-color: #262626;
  border-color: #383838;
}
.nk-btn-color-black.nk-btn-outline {
  color: #000;
}
.nk-btn-color-black.nk-btn-outline.hover,
.nk-btn-color-black.nk-btn-outline:hover {
  color: #000;
}
.nk-btn-color-black.nk-btn-outline.active,
.nk-btn-color-black.nk-btn-outline:active {
  color: #000;
}
.nk-btn-color-dark-1 {
  background-color: #181c23;
  border-color: #000;
  border-style: solid;
}
.nk-btn-color-dark-1.hover,
.nk-btn-color-dark-1:hover {
  background-color: #292f3b;
  border-color: #374050;
}
.nk-btn-color-dark-1.active,
.nk-btn-color-dark-1:active {
  background-color: #374050;
  border-color: #465166;
}
.nk-btn-color-dark-1.nk-btn-outline {
  color: #181c23;
}
.nk-btn-color-dark-1.nk-btn-outline.hover,
.nk-btn-color-dark-1.nk-btn-outline:hover {
  color: #030405;
}
.nk-btn-color-dark-1.nk-btn-outline.active,
.nk-btn-color-dark-1.nk-btn-outline:active {
  color: #000;
}
.nk-btn-color-dark-2 {
  background-color: #292e38;
  border-color: #0f1115;
  border-style: solid;
}
.nk-btn-color-dark-2.hover,
.nk-btn-color-dark-2:hover {
  background-color: #3a4150;
  border-color: #495264;
}
.nk-btn-color-dark-2.active,
.nk-btn-color-dark-2:active {
  background-color: #495264;
  border-color: #586379;
}
.nk-btn-color-dark-2.nk-btn-outline {
  color: #292e38;
}
.nk-btn-color-dark-2.nk-btn-outline.hover,
.nk-btn-color-dark-2.nk-btn-outline:hover {
  color: #13161b;
}
.nk-btn-color-dark-2.nk-btn-outline.active,
.nk-btn-color-dark-2.nk-btn-outline:active {
  color: #000;
}
.nk-btn-color-dark-3 {
  background-color: #2f3541;
  border-color: #16181e;
  border-style: solid;
}
.nk-btn-color-dark-3.hover,
.nk-btn-color-dark-3:hover {
  background-color: #414958;
  border-color: #505a6d;
}
.nk-btn-color-dark-3.active,
.nk-btn-color-dark-3:active {
  background-color: #505a6d;
  border-color: #5f6a82;
}
.nk-btn-color-dark-3.nk-btn-outline {
  color: #2f3541;
}
.nk-btn-color-dark-3.nk-btn-outline.hover,
.nk-btn-color-dark-3.nk-btn-outline:hover {
  color: #1a1d23;
}
.nk-btn-color-dark-3.nk-btn-outline.active,
.nk-btn-color-dark-3.nk-btn-outline:active {
  color: #040506;
}
.nk-btn-color-dark-4 {
  background-color: #313947;
  border-color: #181c23;
  border-style: solid;
}
.nk-btn-color-dark-4.hover,
.nk-btn-color-dark-4:hover {
  background-color: #414c60;
  border-color: #505d75;
}
.nk-btn-color-dark-4.active,
.nk-btn-color-dark-4:active {
  background-color: #505d75;
  border-color: #5f6e8a;
}
.nk-btn-color-dark-4.nk-btn-outline {
  color: #313947;
}
.nk-btn-color-dark-4.nk-btn-outline.hover,
.nk-btn-color-dark-4.nk-btn-outline:hover {
  color: #1c2129;
}
.nk-btn-color-dark-4.nk-btn-outline.active,
.nk-btn-color-dark-4.nk-btn-outline:active {
  color: #07090b;
}
.nk-btn-hover-color-main-1.nk-btn-color-white.active,
.nk-btn-hover-color-main-1.nk-btn-color-white.hover,
.nk-btn-hover-color-main-1.nk-btn-color-white:active,
.nk-btn-hover-color-main-1.nk-btn-color-white:hover {
  color: #fff;
}
.nk-btn-hover-color-main-1.hover,
.nk-btn-hover-color-main-1:hover {
  background-color: #dd163b;
  border-color: #a5102c;
}
.nk-btn-hover-color-main-1.active,
.nk-btn-hover-color-main-1:active {
  background-color: #c11334;
  border-color: #8a0e25;
}
.nk-btn-hover-color-main-1.nk-btn-outline.hover,
.nk-btn-hover-color-main-1.nk-btn-outline:hover {
  color: #af112f;
}
.nk-btn-hover-color-main-1.nk-btn-outline.active,
.nk-btn-hover-color-main-1.nk-btn-outline:active {
  color: #800d22;
}
.nk-btn-hover-color-main-2.nk-btn-color-white.active,
.nk-btn-hover-color-main-2.nk-btn-color-white.hover,
.nk-btn-hover-color-main-2.nk-btn-color-white:active,
.nk-btn-hover-color-main-2.nk-btn-color-white:hover {
  color: #fff;
}
.nk-btn-hover-color-main-2.hover,
.nk-btn-hover-color-main-2:hover {
  background-color: #a714b9;
  border-color: #750e82;
}
.nk-btn-hover-color-main-2.active,
.nk-btn-hover-color-main-2:active {
  background-color: #8e119d;
  border-color: #5c0b66;
}
.nk-btn-hover-color-main-2.nk-btn-outline.hover,
.nk-btn-hover-color-main-2.nk-btn-outline:hover {
  color: #7d0f8b;
}
.nk-btn-hover-color-main-2.nk-btn-outline.active,
.nk-btn-hover-color-main-2.nk-btn-outline:active {
  color: #540a5d;
}
.nk-btn-hover-color-main-3.nk-btn-color-white.active,
.nk-btn-hover-color-main-3.nk-btn-color-white.hover,
.nk-btn-hover-color-main-3.nk-btn-color-white:active,
.nk-btn-hover-color-main-3.nk-btn-color-white:hover {
  color: #fff;
}
.nk-btn-hover-color-main-3.hover,
.nk-btn-hover-color-main-3:hover {
  background-color: #38a220;
  border-color: #266f16;
}
.nk-btn-hover-color-main-3.active,
.nk-btn-hover-color-main-3:active {
  background-color: #2f881b;
  border-color: #1e5511;
}
.nk-btn-hover-color-main-3.nk-btn-outline.hover,
.nk-btn-hover-color-main-3.nk-btn-outline:hover {
  color: #297718;
}
.nk-btn-hover-color-main-3.nk-btn-outline.active,
.nk-btn-hover-color-main-3.nk-btn-outline:active {
  color: #1b4d0f;
}
.nk-btn-hover-color-main-4.nk-btn-color-white.active,
.nk-btn-hover-color-main-4.nk-btn-color-white.hover,
.nk-btn-hover-color-main-4.nk-btn-color-white:active,
.nk-btn-hover-color-main-4.nk-btn-color-white:hover {
  color: #fff;
}
.nk-btn-hover-color-main-4.hover,
.nk-btn-hover-color-main-4:hover {
  background-color: #22a2b1;
  border-color: #18737e;
}
.nk-btn-hover-color-main-4.active,
.nk-btn-hover-color-main-4:active {
  background-color: #1d8b97;
  border-color: #135c64;
}
.nk-btn-hover-color-main-4.nk-btn-outline.hover,
.nk-btn-hover-color-main-4.nk-btn-outline:hover {
  color: #1a7b86;
}
.nk-btn-hover-color-main-4.nk-btn-outline.active,
.nk-btn-hover-color-main-4.nk-btn-outline:active {
  color: #12545b;
}
.nk-btn-hover-color-main-5.nk-btn-color-white.active,
.nk-btn-hover-color-main-5.nk-btn-color-white.hover,
.nk-btn-hover-color-main-5.nk-btn-color-white:active,
.nk-btn-hover-color-main-5.nk-btn-color-white:hover {
  color: #fff;
}
.nk-btn-hover-color-main-5.hover,
.nk-btn-hover-color-main-5:hover {
  background-color: #1464d2;
  border-color: #0f499a;
}
.nk-btn-hover-color-main-5.active,
.nk-btn-hover-color-main-5:active {
  background-color: #1157b6;
  border-color: #0c3c7e;
}
.nk-btn-hover-color-main-5.nk-btn-outline.hover,
.nk-btn-hover-color-main-5.nk-btn-outline:hover {
  color: #104ea3;
}
.nk-btn-hover-color-main-5.nk-btn-outline.active,
.nk-btn-hover-color-main-5.nk-btn-outline:active {
  color: #0b3875;
}
.nk-btn-hover-color-main-6.nk-btn-color-white.active,
.nk-btn-hover-color-main-6.nk-btn-color-white.hover,
.nk-btn-hover-color-main-6.nk-btn-color-white:active,
.nk-btn-hover-color-main-6.nk-btn-color-white:hover {
  color: #fff;
}
.nk-btn-hover-color-main-6.hover,
.nk-btn-hover-color-main-6:hover {
  background-color: #ef9e2b;
  border-color: #cd7f0f;
}
.nk-btn-hover-color-main-6.active,
.nk-btn-hover-color-main-6:active {
  background-color: #ea9012;
  border-color: #b16d0d;
}
.nk-btn-hover-color-main-6.nk-btn-outline.hover,
.nk-btn-hover-color-main-6.nk-btn-outline:hover {
  color: #d78510;
}
.nk-btn-hover-color-main-6.nk-btn-outline.active,
.nk-btn-hover-color-main-6.nk-btn-outline:active {
  color: #a7670d;
}
.nk-btn-hover-color-primary.nk-btn-color-white.active,
.nk-btn-hover-color-primary.nk-btn-color-white.hover,
.nk-btn-hover-color-primary.nk-btn-color-white:active,
.nk-btn-hover-color-primary.nk-btn-color-white:hover {
  color: #fff;
}
.nk-btn-hover-color-primary.hover,
.nk-btn-hover-color-primary:hover {
  background-color: #0275d8;
  border-color: #01549b;
}
.nk-btn-hover-color-primary.active,
.nk-btn-hover-color-primary:active {
  background-color: #0265ba;
  border-color: #01447d;
}
.nk-btn-hover-color-primary.nk-btn-outline.hover,
.nk-btn-hover-color-primary.nk-btn-outline:hover {
  color: #025aa5;
}
.nk-btn-hover-color-primary.nk-btn-outline.active,
.nk-btn-hover-color-primary.nk-btn-outline:active {
  color: #013e73;
}
.nk-btn-hover-color-success.nk-btn-color-white.active,
.nk-btn-hover-color-success.nk-btn-color-white.hover,
.nk-btn-hover-color-success.nk-btn-color-white:active,
.nk-btn-hover-color-success.nk-btn-color-white:hover {
  color: #fff;
}
.nk-btn-hover-color-success.hover,
.nk-btn-hover-color-success:hover {
  background-color: #38a220;
  border-color: #266f16;
}
.nk-btn-hover-color-success.active,
.nk-btn-hover-color-success:active {
  background-color: #2f881b;
  border-color: #1e5511;
}
.nk-btn-hover-color-success.nk-btn-outline.hover,
.nk-btn-hover-color-success.nk-btn-outline:hover {
  color: #297718;
}
.nk-btn-hover-color-success.nk-btn-outline.active,
.nk-btn-hover-color-success.nk-btn-outline:active {
  color: #1b4d0f;
}
.nk-btn-hover-color-info.nk-btn-color-white.active,
.nk-btn-hover-color-info.nk-btn-color-white.hover,
.nk-btn-hover-color-info.nk-btn-color-white:active,
.nk-btn-hover-color-info.nk-btn-color-white:hover {
  color: #fff;
}
.nk-btn-hover-color-info.hover,
.nk-btn-hover-color-info:hover {
  background-color: #22a2b1;
  border-color: #18737e;
}
.nk-btn-hover-color-info.active,
.nk-btn-hover-color-info:active {
  background-color: #1d8b97;
  border-color: #135c64;
}
.nk-btn-hover-color-info.nk-btn-outline.hover,
.nk-btn-hover-color-info.nk-btn-outline:hover {
  color: #1a7b86;
}
.nk-btn-hover-color-info.nk-btn-outline.active,
.nk-btn-hover-color-info.nk-btn-outline:active {
  color: #12545b;
}
.nk-btn-hover-color-warning.nk-btn-color-white.active,
.nk-btn-hover-color-warning.nk-btn-color-white.hover,
.nk-btn-hover-color-warning.nk-btn-color-white:active,
.nk-btn-hover-color-warning.nk-btn-color-white:hover {
  color: #fff;
}
.nk-btn-hover-color-warning.hover,
.nk-btn-hover-color-warning:hover {
  background-color: #ef9e2b;
  border-color: #cd7f0f;
}
.nk-btn-hover-color-warning.active,
.nk-btn-hover-color-warning:active {
  background-color: #ea9012;
  border-color: #b16d0d;
}
.nk-btn-hover-color-warning.nk-btn-outline.hover,
.nk-btn-hover-color-warning.nk-btn-outline:hover {
  color: #d78510;
}
.nk-btn-hover-color-warning.nk-btn-outline.active,
.nk-btn-hover-color-warning.nk-btn-outline:active {
  color: #a7670d;
}
.nk-btn-hover-color-danger.nk-btn-color-white.active,
.nk-btn-hover-color-danger.nk-btn-color-white.hover,
.nk-btn-hover-color-danger.nk-btn-color-white:active,
.nk-btn-hover-color-danger.nk-btn-color-white:hover {
  color: #fff;
}
.nk-btn-hover-color-danger.hover,
.nk-btn-hover-color-danger:hover {
  background-color: #e2361f;
  border-color: #ad2816;
}
.nk-btn-hover-color-danger.active,
.nk-btn-hover-color-danger:active {
  background-color: #c82f1a;
  border-color: #922213;
}
.nk-btn-hover-color-danger.nk-btn-outline.hover,
.nk-btn-hover-color-danger.nk-btn-outline:hover {
  color: #b62a18;
}
.nk-btn-hover-color-danger.nk-btn-outline.active,
.nk-btn-hover-color-danger.nk-btn-outline:active {
  color: #892012;
}
.nk-btn-hover-color-white.active,
.nk-btn-hover-color-white.hover,
.nk-btn-hover-color-white:active,
.nk-btn-hover-color-white:focus,
.nk-btn-hover-color-white:hover {
  color: #181c23;
}
.nk-btn-hover-color-white.hover,
.nk-btn-hover-color-white:hover {
  background-color: #fff;
  border-color: #e0e0e0;
}
.nk-btn-hover-color-white.active,
.nk-btn-hover-color-white:active {
  background-color: #f0f0f0;
  border-color: #d1d1d1;
}
.nk-btn-hover-color-white.nk-btn-outline.hover,
.nk-btn-hover-color-white.nk-btn-outline:hover {
  color: #e6e6e6;
}
.nk-btn-hover-color-white.nk-btn-outline.active,
.nk-btn-hover-color-white.nk-btn-outline:active {
  color: #ccc;
}
.nk-btn-hover-color-black.nk-btn-color-white.active,
.nk-btn-hover-color-black.nk-btn-color-white.hover,
.nk-btn-hover-color-black.nk-btn-color-white:active,
.nk-btn-hover-color-black.nk-btn-color-white:hover {
  color: #fff;
}
.nk-btn-hover-color-black.hover,
.nk-btn-hover-color-black:hover {
  background-color: #000;
  border-color: #000;
}
.nk-btn-hover-color-black.active,
.nk-btn-hover-color-black:active {
  background-color: #000;
  border-color: #000;
}
.nk-btn-hover-color-black.nk-btn-outline.hover,
.nk-btn-hover-color-black.nk-btn-outline:hover {
  color: #000;
}
.nk-btn-hover-color-black.nk-btn-outline.active,
.nk-btn-hover-color-black.nk-btn-outline:active {
  color: #000;
}
.nk-btn-hover-color-dark-1.nk-btn-color-white.active,
.nk-btn-hover-color-dark-1.nk-btn-color-white.hover,
.nk-btn-hover-color-dark-1.nk-btn-color-white:active,
.nk-btn-hover-color-dark-1.nk-btn-color-white:hover {
  color: #fff;
}
.nk-btn-hover-color-dark-1.hover,
.nk-btn-hover-color-dark-1:hover {
  background-color: #181c23;
  border-color: #000;
}
.nk-btn-hover-color-dark-1.active,
.nk-btn-hover-color-dark-1:active {
  background-color: #0c0d11;
  border-color: #000;
}
.nk-btn-hover-color-dark-1.nk-btn-outline.hover,
.nk-btn-hover-color-dark-1.nk-btn-outline:hover {
  color: #030405;
}
.nk-btn-hover-color-dark-1.nk-btn-outline.active,
.nk-btn-hover-color-dark-1.nk-btn-outline:active {
  color: #000;
}
.nk-btn-hover-color-dark-2.nk-btn-color-white.active,
.nk-btn-hover-color-dark-2.nk-btn-color-white.hover,
.nk-btn-hover-color-dark-2.nk-btn-color-white:active,
.nk-btn-hover-color-dark-2.nk-btn-color-white:hover {
  color: #fff;
}
.nk-btn-hover-color-dark-2.hover,
.nk-btn-hover-color-dark-2:hover {
  background-color: #292e38;
  border-color: #0f1115;
}
.nk-btn-hover-color-dark-2.active,
.nk-btn-hover-color-dark-2:active {
  background-color: #1c1f26;
  border-color: #020203;
}
.nk-btn-hover-color-dark-2.nk-btn-outline.hover,
.nk-btn-hover-color-dark-2.nk-btn-outline:hover {
  color: #13161b;
}
.nk-btn-hover-color-dark-2.nk-btn-outline.active,
.nk-btn-hover-color-dark-2.nk-btn-outline:active {
  color: #000;
}
.nk-btn-hover-color-dark-3.nk-btn-color-white.active,
.nk-btn-hover-color-dark-3.nk-btn-color-white.hover,
.nk-btn-hover-color-dark-3.nk-btn-color-white:active,
.nk-btn-hover-color-dark-3.nk-btn-color-white:hover {
  color: #fff;
}
.nk-btn-hover-color-dark-3.hover,
.nk-btn-hover-color-dark-3:hover {
  background-color: #2f3541;
  border-color: #16181e;
}
.nk-btn-hover-color-dark-3.active,
.nk-btn-hover-color-dark-3:active {
  background-color: #23272f;
  border-color: #090a0c;
}
.nk-btn-hover-color-dark-3.nk-btn-outline.hover,
.nk-btn-hover-color-dark-3.nk-btn-outline:hover {
  color: #1a1d23;
}
.nk-btn-hover-color-dark-3.nk-btn-outline.active,
.nk-btn-hover-color-dark-3.nk-btn-outline:active {
  color: #040506;
}
.nk-btn-hover-color-dark-4.nk-btn-color-white.active,
.nk-btn-hover-color-dark-4.nk-btn-color-white.hover,
.nk-btn-hover-color-dark-4.nk-btn-color-white:active,
.nk-btn-hover-color-dark-4.nk-btn-color-white:hover {
  color: #fff;
}
.nk-btn-hover-color-dark-4.hover,
.nk-btn-hover-color-dark-4:hover {
  background-color: #313947;
  border-color: #181c23;
}
.nk-btn-hover-color-dark-4.active,
.nk-btn-hover-color-dark-4:active {
  background-color: #242b35;
  border-color: #0c0d11;
}
.nk-btn-hover-color-dark-4.nk-btn-outline.hover,
.nk-btn-hover-color-dark-4.nk-btn-outline:hover {
  color: #1c2129;
}
.nk-btn-hover-color-dark-4.nk-btn-outline.active,
.nk-btn-hover-color-dark-4.nk-btn-outline:active {
  color: #07090b;
}
.nk-btn-outline {
  border-width: 1px;
}
.nk-btn-outline,
.nk-btn-outline.active,
.nk-btn-outline.hover,
.nk-btn-outline:active,
.nk-btn-outline:focus,
.nk-btn-outline:hover {
  color: inherit;
  background-color: transparent;
}
.nk-btn-rounded {
  border-radius: 4px;
}
.nk-btn-block {
  display: block;
  width: 100%;
}
.nk-btn > .icon {
  margin-right: 3px;
}
.nk-btn > span + .icon {
  margin-right: 0;
  margin-left: 3px;
}
.nk-tabs::before {
  content: "";
  display: block;
  clear: both;
}
.nk-tabs .nav-tabs {
  border-bottom: none;
}
.nk-tabs .nav-tabs .nav-item + .nav-item {
  margin-left: 10px;
}
.nk-tabs .nav-tabs .nav-item,
.nk-tabs .nav-tabs .nav-item.open .nav-link,
.nk-tabs .nav-tabs .nav-item.open .nav-link:focus,
.nk-tabs .nav-tabs .nav-item.open .nav-link:hover,
.nk-tabs .nav-tabs .nav-item:focus,
.nk-tabs .nav-tabs .nav-item:hover,
.nk-tabs .nav-tabs .nav-link,
.nk-tabs .nav-tabs .nav-link.active,
.nk-tabs .nav-tabs .nav-link.active:focus,
.nk-tabs .nav-tabs .nav-link.active:hover,
.nk-tabs .nav-tabs .nav-link:focus,
.nk-tabs .nav-tabs .nav-link:hover {
  color: #fff;
}
.nk-tabs .nav-tabs .nav-link {
  min-width: 100px;
  padding: 10px 15px;
  font-family: Montserrat, sans-serif;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  background-color: #2f3541;
  border: none;
  border-radius: 4px;
  transition: 0.15s background-color;
}
.nk-tabs .nav-tabs .nav-link:focus,
.nk-tabs .nav-tabs .nav-link:hover {
  background-color: #363d4a;
}
.nk-tabs .nav-tabs .nav-link.active,
.nk-tabs .nav-tabs .nav-link.active:focus,
.nk-tabs .nav-tabs .nav-link.active:hover {
  background-color: #dd163b;
}
.nk-tabs .nav-tabs.nav-tabs-fill {
  display: flex;
  flex-flow: row wrap;
  margin: -10px -5px;
}
.nk-tabs .nav-tabs.nav-tabs-fill .nav-item {
  flex: 1;
  margin: 10px 5px;
}
.nk-accordion .panel-heading {
  margin-bottom: 12px;
}
.nk-accordion .panel-heading a {
  position: relative;
  display: block;
  padding: 10px 20px;
  font-family: Montserrat, sans-serif;
  font-weight: 600;
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
  background-color: #2f3541;
  border-radius: 4px;
  transition: 0.15s background-color;
}
.nk-accordion .panel-heading a:focus,
.nk-accordion .panel-heading a:hover {
  background-color: #363d4a;
}
.nk-accordion .panel-heading a:not(.collapsed),
.nk-accordion .panel-heading a:not(.collapsed):focus,
.nk-accordion .panel-heading a:not(.collapsed):hover {
  background-color: #dd163b;
}
.nk-accordion .panel-heading a:not(.collapsed) .panel-heading-arrow {
  transform: rotate(-90deg);
}
.nk-accordion .panel-heading a .panel-heading-arrow {
  position: absolute;
  top: 50%;
  right: 20px;
  height: 14px;
  margin-top: -7px;
  transition: 0.2s transform;
}
.nk-feature-1,
.nk-feature-2 {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 50px;
  padding: 15px;
  background-color: #292e38;
  border-radius: 4px;
}
.nk-feature-1 .nk-feature-icon,
.nk-feature-2 .nk-feature-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 110px;
  height: 50px;
  padding-right: 10px;
  font-size: 3rem;
  line-height: 50px;
  color: #fff;
  text-align: center;
  border-radius: 3px;
}
.nk-feature-1 .nk-feature-icon img,
.nk-feature-2 .nk-feature-icon img {
  max-width: 100%;
  height: auto;
}
.nk-feature-1 .nk-feature-cont,
.nk-feature-2 .nk-feature-cont {
  padding-top: 8px;
}
.nk-feature-2 {
  flex-direction: column;
}
.nk-feature-2 .nk-feature-icon {
  width: 100%;
  height: auto;
  padding: 20px 0;
  border: 0;
}
.nk-feature-2 .nk-feature-cont {
  padding-right: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
}
.nk-feature-title {
  margin-bottom: 0.5rem;
  font-size: 1.25rem;
}
.nk-feature-title a {
  color: inherit;
  text-decoration: none;
}
.nk-box,
.nk-box-1,
.nk-box-2,
.nk-box-3,
.nk-box-4,
.nk-box-rounded,
.nk-box-rounded-1,
.nk-box-rounded-2,
.nk-box-rounded-3,
.nk-box-rounded-4 {
  position: relative;
  display: block;
  height: 100%;
  overflow: hidden;
  z-index: 1;
}
.nk-box-1,
.nk-box-rounded-1 {
  padding: 20px;
}
.nk-box-2,
.nk-box-rounded-2 {
  padding: 40px;
}
.nk-box-3,
.nk-box-rounded-3 {
  padding: 60px;
}
.nk-box-4,
.nk-box-rounded-4 {
  padding: 80px;
}
.nk-box-rounded,
.nk-box-rounded-1,
.nk-box-rounded-2,
.nk-box-rounded-3,
.nk-box-rounded-4 {
  border-radius: 6px;
}
.nk-box-line::before {
  content: "";
  position: absolute;
  display: block;
  top: 2rem;
  right: 0;
  bottom: 2rem;
  border-left: 1px solid;
  opacity: 0.07;
}
.nk-box-line.nk-box-line-top::after {
  content: "";
  position: absolute;
  display: block;
  top: 0;
  right: 2rem;
  left: 2rem;
  border-bottom: 1px solid;
  opacity: 0.07;
}
.nk-box-line.nk-box-last::before {
  content: none;
}
.nk-counter,
.nk-counter-2,
.nk-counter-3 {
  position: relative;
}
.nk-counter .nk-count,
.nk-counter-2 .nk-count,
.nk-counter-3 .nk-count {
  font-family: Montserrat, sans-serif;
  font-weight: 600;
}
.nk-counter .nk-count {
  font-size: 4rem;
}
.nk-counter-2 .nk-count {
  font-size: 4rem;
  line-height: 1;
  color: #dd163b;
}
.nk-counter-3 .nk-counter-icon {
  font-size: 3rem;
  line-height: 1;
  color: #dd163b;
}
.nk-counter-3 .nk-count {
  font-size: 3rem;
  line-height: 2;
}
.nk-counter-3 .nk-counter-title {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  line-height: 1;
}
.nk-pagination {
  display: flex;
  flex-wrap: wrap;
  font-size: 1.7rem;
  font-weight: 600;
  color: #fff;
}
.nk-pagination .nk-pagination-next,
.nk-pagination .nk-pagination-prev {
  position: relative;
  color: inherit;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
}
.nk-pagination .nk-pagination-next.disabled,
.nk-pagination .nk-pagination-prev.disabled {
  pointer-events: none;
  cursor: default;
  opacity: 0.2;
}
.nk-pagination .nk-pagination-next .nk-icon-arrow-left,
.nk-pagination .nk-pagination-next .nk-icon-arrow-right,
.nk-pagination .nk-pagination-prev .nk-icon-arrow-left,
.nk-pagination .nk-pagination-prev .nk-icon-arrow-right {
  position: absolute;
  top: 33px;
}
.nk-pagination .nk-pagination-next .nk-icon-arrow-right,
.nk-pagination .nk-pagination-prev .nk-icon-arrow-right {
  right: 0;
}
.nk-pagination .nk-pagination-next .nk-icon-arrow-left,
.nk-pagination .nk-pagination-prev .nk-icon-arrow-left {
  left: 0;
}
.nk-pagination .nk-pagination-prev {
  padding-right: 14px;
  text-align: left;
}
.nk-pagination .nk-pagination-next {
  padding-left: 14px;
  text-align: right;
}
.nk-pagination a {
  color: inherit;
  text-decoration: none;
  transition: 0.15s color;
}
.nk-pagination a:hover {
  color: #dd163b;
}
.nk-pagination nav {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
}
.nk-pagination nav > * {
  padding-right: 15px;
  padding-left: 15px;
}
.nk-pagination nav > .nk-pagination-current {
  min-width: 42px;
  height: 42px;
  padding: 3px;
  line-height: 28px;
  color: #dd163b;
  border: 4px solid;
  border-radius: 21px;
}
.nk-pagination.nk-pagination-center {
  justify-content: center;
}
.nk-pagination.nk-pagination-left {
  justify-content: flex-start;
}
.nk-pagination.nk-pagination-right {
  justify-content: flex-end;
}
.nk-blockquote {
  position: relative;
  margin: 20px 0;
  margin-top: 33px;
  font-style: italic;
  z-index: 1;
}
.nk-blockquote .nk-blockquote-icon {
  display: table;
  margin-bottom: 20px;
}
.nk-blockquote .nk-blockquote-icon > span {
  display: table-cell;
  height: 0;
  padding-right: 25px;
  font-size: 96px;
  font-weight: 600;
  line-height: 0;
  color: #fff;
  vertical-align: top;
  cursor: default;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.nk-blockquote .nk-blockquote-icon::after,
.nk-blockquote .nk-blockquote-icon::before {
  content: "";
  display: table-cell;
  width: 100%;
  vertical-align: top;
  border-bottom: 2px solid #dd163b;
  transform: translateY(-18px);
}
.nk-blockquote .nk-blockquote-icon::before {
  width: 50px;
  min-width: 50px;
}
.nk-blockquote .nk-blockquote-author {
  margin-top: 25px;
  text-align: right;
}
.nk-blockquote .nk-blockquote-author > span {
  display: table-cell;
  padding-right: 30px;
  padding-left: 30px;
  font-size: 0.95rem;
  font-weight: 600;
  color: #fff;
  white-space: nowrap;
}
.nk-blockquote .nk-blockquote-author::after,
.nk-blockquote .nk-blockquote-author::before {
  content: "";
  display: table-cell;
  width: 100%;
  border-bottom: 2px solid #dd163b;
  transform: translateY(-10px);
}
.nk-blockquote .nk-blockquote-author::after {
  width: 50px;
  min-width: 50px;
}
.nk-blockquote > a {
  color: inherit;
  text-decoration: none;
}
.nk-info-box {
  position: relative;
  display: block;
  padding: 30px;
  padding-right: 40px;
  padding-left: 118px;
  margin-bottom: 1.7rem;
  overflow: hidden;
  color: #fff;
  background-color: #292e38;
  border-radius: 4px;
}
.nk-info-box .h1,
.nk-info-box .h2,
.nk-info-box .h3,
.nk-info-box .h4,
.nk-info-box .h5,
.nk-info-box .h6,
.nk-info-box h1,
.nk-info-box h2,
.nk-info-box h3,
.nk-info-box h4,
.nk-info-box h5,
.nk-info-box h6 {
  margin-top: -6px;
  margin-bottom: 0.7rem;
  color: inherit;
}
.nk-info-box.nk-info-box-noicon {
  padding-left: 40px;
}
.nk-info-box .nk-info-box-icon {
  position: absolute;
  display: block;
  width: 52px;
  height: 52px;
  margin-top: -3px;
  margin-left: -85px;
  font-size: 1.7rem;
  line-height: 44px;
  text-align: center;
  border: 4px solid;
  border-radius: 26px;
}
.nk-info-box .nk-info-box-close-btn {
  display: block;
  float: right;
  margin-top: -7px;
  margin-right: -20px;
  font-size: 1.3rem;
  cursor: pointer;
}
.nk-carousel {
  position: relative;
}
.nk-carousel .flickity-slider > div {
  display: block;
  width: 100%;
  max-width: 600px;
  padding: 17px 0;
}
.nk-carousel.nk-carousel-x2 .flickity-slider > div {
  width: 33.3334%;
}
@media (max-width: 1199px) {
  .nk-carousel.nk-carousel-x2 .flickity-slider > div {
    width: 33.3334%;
  }
}
@media (max-width: 767px) {
  .nk-carousel.nk-carousel-x2 .flickity-slider > div {
    width: 50%;
  }
}
@media (max-width: 575px) {
  .nk-carousel.nk-carousel-x2 .flickity-slider > div {
    width: 100%;
  }
}
.nk-carousel.nk-carousel-x3 .flickity-slider > div {
  width: 25%;
}
@media (max-width: 1199px) {
  .nk-carousel.nk-carousel-x3 .flickity-slider > div {
    width: 33.3334%;
  }
}
@media (max-width: 767px) {
  .nk-carousel.nk-carousel-x3 .flickity-slider > div {
    width: 50%;
  }
}
@media (max-width: 575px) {
  .nk-carousel.nk-carousel-x3 .flickity-slider > div {
    width: 100%;
  }
}
.nk-carousel.nk-carousel-x4 .flickity-slider > div {
  width: 20%;
}
@media (max-width: 1199px) {
  .nk-carousel.nk-carousel-x4 .flickity-slider > div {
    width: 25%;
  }
}
@media (max-width: 767px) {
  .nk-carousel.nk-carousel-x4 .flickity-slider > div {
    width: 33.3334%;
  }
}
@media (max-width: 575px) {
  .nk-carousel.nk-carousel-x4 .flickity-slider > div {
    width: 50%;
  }
}
.nk-carousel.nk-carousel-x5 .flickity-slider > div {
  width: 14.285%;
}
@media (max-width: 1199px) {
  .nk-carousel.nk-carousel-x5 .flickity-slider > div {
    width: 20%;
  }
}
@media (max-width: 767px) {
  .nk-carousel.nk-carousel-x5 .flickity-slider > div {
    width: 25%;
  }
}
@media (max-width: 575px) {
  .nk-carousel.nk-carousel-x5 .flickity-slider > div {
    width: 50%;
  }
}
.flickity-page-dots .dot {
  background: grey;
}
.nk-flickity-arrow {
  position: absolute;
  top: 50%;
  width: 56px;
  height: 56px;
  padding: 1px;
  margin-top: -29px;
  font-size: 2.6rem;
  line-height: 1.3;
  color: #fff;
  text-align: center;
  cursor: pointer;
  border: 4px solid;
  border-radius: 50%;
  transition: 0.15s color;
  z-index: 1;
}
.nk-flickity-arrow.hover,
.nk-flickity-arrow:active,
.nk-flickity-arrow:focus,
.nk-flickity-arrow:hover {
  color: #dd163b;
}
.nk-flickity-arrow.nk-flickity-arrow-prev {
  left: -100px;
  padding-right: 5px;
}
.nk-flickity-arrow.nk-flickity-arrow-next {
  right: -100px;
  padding-left: 5px;
}
@media (max-width: 991px) {
  .nk-flickity-arrow {
    display: none;
    margin-right: 0;
    margin-left: 0;
  }
}
.nk-carousel[data-arrows="true"] .nk-carousel-inner {
  margin-right: 100px;
  margin-left: 100px;
}
@media (max-width: 991px) {
  .nk-carousel[data-arrows="true"] .nk-carousel-inner {
    margin-right: 0;
    margin-left: 0;
  }
}
.flickity-viewport.is-dragging {
  pointer-events: none;
}
.nk-modal .modal-content {
  background-color: #292e38;
  border: none;
}
.nk-modal .modal-body {
  padding: 38px;
}
.nk-modal .modal-sm {
  max-width: 470px;
}
.nk-modal .close {
  position: absolute;
  top: 38px;
  right: 38px;
  margin-top: 0;
  font-size: 1.7rem;
  color: inherit;
  text-shadow: none;
  transition: 0.2s color;
  z-index: 2;
}
.nk-modal .close:hover {
  color: #fff;
}
@media (min-width: 576px) {
  .nk-modal .modal-dialog {
    margin-top: 190px;
    margin-bottom: 60px;
  }
}
.nk-gallery-item-box,
.nk-gallery-item-group {
  position: relative;
  display: block;
  overflow: hidden;
}
.nk-gallery-item-box,
.nk-gallery-item-box a,
.nk-gallery-item-box a:active,
.nk-gallery-item-box a:focus,
.nk-gallery-item-box a:hover,
.nk-gallery-item-box:active,
.nk-gallery-item-box:focus,
.nk-gallery-item-box:hover,
.nk-gallery-item-group,
.nk-gallery-item-group a,
.nk-gallery-item-group a:active,
.nk-gallery-item-group a:focus,
.nk-gallery-item-group a:hover,
.nk-gallery-item-group:active,
.nk-gallery-item-group:focus,
.nk-gallery-item-group:hover {
  text-decoration: none;
}
.nk-gallery-item-box img,
.nk-gallery-item-group img {
  width: 100%;
  height: auto;
  transition: transform 0.15s ease-in-out;
  transform: scale(1);
  will-change: transform;
}
.nk-gallery-item-box .nk-gallery-item,
.nk-gallery-item-group .nk-gallery-item {
  display: block;
  overflow: hidden;
  border-radius: 4px;
}
.nk-gallery-item-box .nk-gallery-item:hover img,
.nk-gallery-item-group .nk-gallery-item:hover img {
  transform: scale(1.05);
}
.nk-gallery-item-box .nk-gallery-item-description,
.nk-gallery-item-group .nk-gallery-item-description {
  position: absolute;
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
}
.nk-gallery-item-box .nk-gallery-item-link,
.nk-gallery-item-group .nk-gallery-item-link {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
}
.nk-gallery-item-box .nk-gallery-item-label,
.nk-gallery-item-group .nk-gallery-item-label {
  position: absolute;
  bottom: 30px;
  padding: 17px 30px;
  background-color: #181c23;
  z-index: 2;
}
.nk-gallery-item-box .nk-gallery-item-overlay,
.nk-gallery-item-group .nk-gallery-item-overlay {
  position: absolute;
  display: flex;
  align-items: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  color: #fff;
  background-color: rgba(221, 22, 59, 0.9);
  border-radius: 4px;
  opacity: 0;
  transition: 0.2s opacity;
  z-index: 1;
}
.nk-gallery-item-box .nk-gallery-item-overlay > span,
.nk-gallery-item-group .nk-gallery-item-overlay > span {
  width: 56px;
  height: 56px;
  margin: 0 auto;
  font-size: 3rem;
  line-height: 48px;
  text-align: center;
  border: 4px solid #fff;
  border-radius: 28px;
  opacity: 0.85;
  transition: 0.35s transform ease;
  transform: scale(0.95);
}
.nk-gallery-item-box.hover .nk-gallery-item-overlay,
.nk-gallery-item-box:hover .nk-gallery-item-overlay,
.nk-gallery-item-group.hover .nk-gallery-item-overlay,
.nk-gallery-item-group:hover .nk-gallery-item-overlay {
  opacity: 1;
}
.nk-gallery-item-box.hover .nk-gallery-item-overlay > span,
.nk-gallery-item-box:hover .nk-gallery-item-overlay > span,
.nk-gallery-item-group.hover .nk-gallery-item-overlay > span,
.nk-gallery-item-group:hover .nk-gallery-item-overlay > span {
  transform: scale(1);
}
.nk-gallery-item-group .nk-gallery-item-overlay {
  padding-bottom: 55px;
  background-color: rgba(24, 28, 35, 0.65);
}
.nk-gallery-item-group .nk-gallery-item-description {
  position: absolute;
  right: 10px;
  bottom: 10px;
  left: 10px;
  padding: 20px 19px;
  overflow: auto;
  color: #fff;
  text-indent: 0;
  white-space: normal;
  background-color: rgba(221, 22, 59, 0.9);
  border-radius: 4px;
}
.nk-gallery-item-group
  .nk-gallery-item-description
  .nk-gallery-item-description-title {
  float: left;
  margin-bottom: 0;
  line-height: 1.1;
}
.nk-gallery-item-group
  .nk-gallery-item-description
  .nk-gallery-item-description-info {
  float: right;
  line-height: 1.1;
}
.nk-gallery-item-group
  .nk-gallery-item-description
  .nk-gallery-item-description-info
  span:last-of-type {
  margin-left: 8px;
  font-weight: 600;
}
.nk-plain-video {
  position: relative;
  display: block;
  width: 100%;
  padding-top: 56.25%;
  cursor: pointer;
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 4px;
}
.nk-plain-video > iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.nk-plain-video > .nk-video-plain-toggle {
  position: absolute;
  display: inline-block;
  top: 50%;
  left: 50%;
  cursor: pointer;
  transform: translate(-50%, -50%);
  z-index: 0;
}
.nk-plain-video > .nk-video-plain-toggle .nk-video-icon {
  text-decoration: none;
}
.form-control::-webkit-input-placeholder,
::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.5;
}
.form-control::-moz-placeholder,
::-moz-placeholder {
  color: inherit;
  opacity: 0.5;
}
.form-control:-ms-input-placeholder,
:-ms-input-placeholder {
  color: inherit;
  opacity: 0.5;
}
.form-control::-ms-input-placeholder,
::-ms-input-placeholder {
  color: inherit;
  opacity: 0.5;
}
.form-control::placeholder,
::placeholder {
  color: inherit;
  opacity: 0.5;
}
button:focus {
  outline: 0;
}
.nk-rating {
  position: relative;
  display: inline-block;
  overflow: hidden;
  font-size: 1.2rem;
  color: #dd163b;
}
.nk-rating input {
  display: none;
}
.nk-rating input:checked ~ label span:first-child {
  display: none;
}
.nk-rating input:checked ~ label span:last-child {
  display: block;
}
.nk-rating label {
  position: relative;
  display: block;
  float: right;
  padding-right: 2px;
  padding-left: 2px;
  margin: 0;
  clear: none;
  line-height: 1;
  cursor: pointer;
}
.nk-rating label span:first-child {
  display: block;
}
.nk-rating label span:last-child {
  display: none;
}
.nk-rating label::after,
.nk-rating label::before {
  content: none;
  display: none;
}
.nk-rating label:hover span:first-child,
.nk-rating label:hover ~ label span:first-child {
  display: none;
}
.nk-rating label:hover span:last-child,
.nk-rating label:hover ~ label span:last-child {
  display: block;
}
.form-control {
  padding: 6.5px 10px;
  font-size: 1em;
  font-weight: 400;
  color: #fff;
  background-color: transparent;
  border: 1px solid #313947;
  border-radius: 4px;
  transition: 0.15s border-color;
}
.form-control.focus,
.form-control:focus {
  color: #fff;
  background-color: transparent;
  border-color: #dd163b;
  box-shadow: none;
}
select.form-control:not([size]):not([multiple]) {
  height: 32px;
}
select.form-control option {
  color: #000;
}
@-moz-document url-prefix("") {
  select.form-control option {
    color: inherit;
  }
}
select.form-control[multiple] option {
  color: inherit;
}
select.form-control {
  background-color: rgba(0, 0, 0, 0.01);
}
.nk-form-style-1 .form-control {
  color: #181c23;
  background-color: #fff;
  border-color: #fff;
}
.nk-form-style-1 .form-control.focus,
.nk-form-style-1 .form-control:focus {
  background-color: #fff;
  border-color: #dd163b;
}
div.nk-error {
  padding: 0;
  margin: 0;
  font-size: 0.8em;
  font-weight: 400;
  color: #e2361f;
  background-color: transparent;
  z-index: 1;
}
.form-control.nk-error,
.nk-form-style-1 .form-control.nk-error {
  background-color: rgba(226, 54, 31, 0.4);
  border-color: #e2361f;
}
.nk-form-response-success {
  display: none;
  margin: 15px 0;
  color: #38a220;
}
.nk-form-response-error {
  display: none;
  margin: 15px 0;
  color: #e2361f;
}
.nk-audio-plain {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 20px 15px;
  background-color: #292e38;
  border-radius: 4px;
}
.nk-audio-plain .nk-audio-plain-title {
  flex: 1;
}
.nk-audio-plain .nk-audio-plain-buttons {
  white-space: nowrap;
}
.nk-audio-plain .nk-audio-plain-buttons > a {
  display: inline-block;
  width: 30px;
  color: inherit;
  text-align: center;
  text-decoration: none;
}
.nk-audio-plain .nk-audio-plain-play-pause {
  display: inline-block;
  width: 38px;
  height: 38px;
  margin-right: 15px;
  margin-left: 7px;
  font-size: 1.1rem;
  line-height: 38px;
  color: #fff;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-color: #465166;
  border-radius: 19px;
}
.nk-audio-plain .nk-audio-plain-play-pause .nk-audio-plain-pause {
  display: none;
}
.nk-audio-plain.nk-audio-plain-playing
  .nk-audio-plain-play-pause
  .nk-audio-plain-play {
  display: none;
}
.nk-audio-plain.nk-audio-plain-playing
  .nk-audio-plain-play-pause
  .nk-audio-plain-pause {
  display: block;
}
.nk-audio-plain .nk-audio-plain-duration {
  width: 58px;
  margin-right: 7px;
  font-size: 0.9rem;
  text-align: right;
}
.nk-audio-plain .nk-audio-progress {
  position: relative;
  right: 0;
  left: 0;
  height: 1px;
  margin-top: 2px;
  margin-bottom: 2px;
  cursor: pointer;
  background-color: #313947;
  z-index: 1;
}
.nk-audio-plain .nk-audio-progress::before {
  content: "";
  position: absolute;
  display: block;
  top: -8px;
  right: 0;
  bottom: -8px;
  left: 0;
}
.nk-audio-plain .nk-audio-progress .nk-audio-progress-current {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 0;
  background-color: #5a6984;
  transition: 0.3s background-color;
  z-index: 1;
}
.nk-audio-plain .nk-audio-progress .nk-audio-progress-current::after {
  content: "";
  position: absolute;
  top: -2px;
  right: -2px;
  width: 5px;
  height: 5px;
  background-color: #919db3;
  border-radius: 2.5px;
  transition: 0.3s transform;
  transform: scale(0);
}
.nk-audio-plain .nk-audio-progress.hover .nk-audio-progress-current,
.nk-audio-plain .nk-audio-progress:hover .nk-audio-progress-current {
  background-color: #919db3;
}
.nk-audio-plain .nk-audio-progress.hover .nk-audio-progress-current::after,
.nk-audio-plain .nk-audio-progress:hover .nk-audio-progress-current::after {
  transform: scale(1);
}
.nk-countdown {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.nk-countdown.nk-countdown-center {
  justify-content: center;
}
.nk-countdown.nk-countdown-right {
  justify-content: flex-end;
}
.nk-countdown .nk-hexagon {
  position: relative;
  width: 90px;
  height: 51.96px;
  margin: 28px 10px;
  font-size: 0.9rem;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  background-color: rgba(0, 0, 0, 0.35);
  z-index: 0;
}
.nk-countdown .nk-hexagon > span {
  display: block;
  font-family: Montserrat, sans-serif;
  font-size: 2rem;
  font-weight: 600;
  line-height: 1.2;
}
.nk-countdown .nk-hexagon::after,
.nk-countdown .nk-hexagon::before {
  content: "";
  position: absolute;
  left: 0;
  width: 0;
  height: 0;
  border-right: 45px solid transparent;
  border-left: 45px solid transparent;
  z-index: -1;
}
.nk-countdown .nk-hexagon::before {
  top: -25px;
  border-bottom: 25px solid rgba(0, 0, 0, 0.35);
}
.nk-countdown .nk-hexagon::after {
  bottom: -25px;
  border-top: 25px solid rgba(0, 0, 0, 0.35);
}
.nk-countdown .nk-hexagon .nk-hexagon-inner {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-right: solid 4px #dd163b;
  border-left: solid 4px #dd163b;
}
.nk-countdown .nk-hexagon .nk-hexagon-inner::after,
.nk-countdown .nk-hexagon .nk-hexagon-inner::before {
  content: "";
  position: absolute;
  left: 9.1802px;
  width: 63.64px;
  height: 63.64px;
  background-color: inherit;
  transform: scaleY(0.5774) rotate(-45deg);
  z-index: 1;
}
.nk-countdown .nk-hexagon .nk-hexagon-inner::before {
  top: -31.8198px;
  border-top: solid 5.6569px #dd163b;
  border-right: solid 5.6569px #dd163b;
}
.nk-countdown .nk-hexagon .nk-hexagon-inner::after {
  bottom: -31.8198px;
  border-bottom: solid 5.6569px #dd163b;
  border-left: solid 5.6569px #dd163b;
}
.nk-table {
  width: 100%;
  color: #fff;
  border: 1px solid #292e38;
}
.nk-table th {
  font-weight: 600;
  text-transform: uppercase;
}
.nk-table thead {
  font-size: 1.32rem;
  background-color: #dd163b;
}
.nk-table thead td,
.nk-table thead th {
  padding: 15px 25px;
}
.nk-table tbody th {
  padding: 19px 25px;
}
.nk-table tbody td {
  padding: 28px 25px;
}
.nk-table tbody tr {
  background-color: #292e38;
}
.nk-table tbody tr:nth-of-type(odd) {
  background-color: #2f3541;
}
.nk-table tbody tr > * + * {
  border-left: 1px solid #181c23;
}
.nk-table.nk-table thead td,
.nk-table.nk-table thead th {
  padding: 11px 18px;
}
.nk-table.nk-table tbody th {
  padding: 14px 18px;
}
.nk-table.nk-table tbody td {
  padding: 20px 18px;
}
.nk-fullscreen-block {
  display: table;
  width: 100%;
  min-height: 100vh;
}
.nk-fullscreen-block .nk-fullscreen-block-bottom,
.nk-fullscreen-block .nk-fullscreen-block-middle,
.nk-fullscreen-block .nk-fullscreen-block-top {
  display: table-row;
}
.nk-fullscreen-block .nk-fullscreen-block-middle {
  height: 80%;
}
.nk-fullscreen-block .nk-fullscreen-block-middle > div {
  display: table-cell;
  vertical-align: middle;
}
.nk-icon-burger {
  position: relative;
  display: inline-block;
  width: 27px;
  height: 24px;
}
.nk-icon-burger > span {
  position: absolute;
  display: block;
  top: 50%;
  width: 25px;
  height: 0;
  border-bottom: 2px solid;
  transition: 0.3s 0.2s width, 0.3s opacity, 0.2s transform;
  will-change: width, opacity, transform;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transform-style: preserve-3d;
}
.nk-icon-burger .nk-t-1 {
  margin-top: -7px;
}
.nk-icon-burger .nk-t-3 {
  margin-top: 7px;
}
.nk-icon-circles {
  position: relative;
  display: inline-block;
  color: #fff;
}
.nk-gallery-item-overlay .nk-icon-circles {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -6px;
  margin-left: -6px;
}
.nk-icon-circles::after,
.nk-icon-circles::before {
  content: "";
  position: absolute;
  display: inline-block;
  top: -4px;
}
.nk-icon-circles,
.nk-icon-circles::after,
.nk-icon-circles::before {
  width: 12px;
  height: 12px;
  border: 4px solid;
  border-radius: 50%;
}
.nk-icon-circles::before {
  left: -19px;
}
.nk-icon-circles::after {
  right: -19px;
}
.nk-icon-arrow-left,
.nk-icon-arrow-right {
  position: relative;
  display: inline-block;
  width: 26px;
  height: 12px;
  margin-bottom: 12px;
  border-bottom: 1px solid;
}
.nk-icon-arrow-left::after,
.nk-icon-arrow-left::before,
.nk-icon-arrow-right::after,
.nk-icon-arrow-right::before {
  content: "";
  position: absolute;
  display: block;
  bottom: -1px;
  left: 0;
  width: 50%;
  border-bottom: 1px solid;
  transition: 0.2s transform;
  transform-origin: 0 50%;
  will-change: transform;
}
.nk-icon-arrow-left::after,
.nk-icon-arrow-right::after {
  transform: rotate(40deg);
}
.nk-icon-arrow-left::before,
.nk-icon-arrow-right::before {
  transform: rotate(-40deg);
}
.hover > .nk-icon-arrow-left::after,
.hover > .nk-icon-arrow-right::after,
.nk-icon-arrow-left.hover::after,
.nk-icon-arrow-left:hover::after,
.nk-icon-arrow-right.hover::after,
.nk-icon-arrow-right:hover::after,
:hover > .nk-icon-arrow-left::after,
:hover > .nk-icon-arrow-right::after {
  transform: rotate(48deg);
}
.hover > .nk-icon-arrow-left::before,
.hover > .nk-icon-arrow-right::before,
.nk-icon-arrow-left.hover::before,
.nk-icon-arrow-left:hover::before,
.nk-icon-arrow-right.hover::before,
.nk-icon-arrow-right:hover::before,
:hover > .nk-icon-arrow-left::before,
:hover > .nk-icon-arrow-right::before {
  transform: rotate(-48deg);
}
.nk-icon-arrow-right::after,
.nk-icon-arrow-right::before {
  right: 0;
  left: auto;
  transform-origin: 100% 50%;
}
.nk-gif-icon,
.nk-gif-icon-light,
.nk-video-icon,
.nk-video-icon-light {
  position: relative;
  display: inline-block;
  width: 56px;
  height: 56px;
  color: #fff;
}
.nk-gif-icon-light::before,
.nk-gif-icon::before,
.nk-video-icon-light::before,
.nk-video-icon::before {
  content: "";
  position: absolute;
  display: block;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: 4px solid;
  border-radius: 28px;
  opacity: 1;
  transition: 0.3s opacity, 0.3s transform;
  transform-style: preserve-3d;
}
.nk-gif-icon-light > *,
.nk-gif-icon > *,
.nk-video-icon-light > *,
.nk-video-icon > * {
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  width: 50px;
  height: 50px;
  margin-top: -25px;
  margin-left: -25px;
  font-size: 1.5rem;
  line-height: 51px;
  color: inherit;
  text-align: center;
  opacity: 1;
  transition: 0.3s transform, 0.3s opacity;
  transform-style: preserve-3d;
}
.nk-gif-icon-light > .svg-inline--fa,
.nk-gif-icon > .svg-inline--fa,
.nk-video-icon-light > .svg-inline--fa,
.nk-video-icon > .svg-inline--fa {
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
}
.nk-loading-icon {
  position: relative;
  display: inline-block;
  -webkit-animation: nk-loading-animation 0.9s linear infinite 0.3s;
  animation: nk-loading-animation 0.9s linear infinite 0.3s;
}
.nk-loading-icon::after,
.nk-loading-icon::before {
  content: "";
  position: absolute;
  display: block;
}
.nk-loading-icon,
.nk-loading-icon::after,
.nk-loading-icon::before {
  width: 6px;
  height: 6px;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 3px;
}
.nk-loading-icon::before {
  left: -13px;
  -webkit-animation: nk-loading-animation 0.9s linear infinite 0s;
  animation: nk-loading-animation 0.9s linear infinite 0s;
}
.nk-loading-icon::after {
  right: -13px;
  -webkit-animation: nk-loading-animation 0.9s linear infinite 0.6s;
  animation: nk-loading-animation 0.9s linear infinite 0.6s;
}
.nk-gif-icon > .nk-loading-icon,
.nk-video-icon > .nk-loading-icon {
  position: absolute;
  margin-top: -3px;
  margin-left: -3px;
}
@-webkit-keyframes nk-loading-animation {
  0%,
  100%,
  60% {
    background-color: rgba(255, 255, 255, 0.3);
  }
  30% {
    background-color: #fff;
  }
}
@keyframes nk-loading-animation {
  0%,
  100%,
  60% {
    background-color: rgba(255, 255, 255, 0.3);
  }
  30% {
    background-color: #fff;
  }
}
.nk-image-slider {
  position: relative;
  overflow: hidden;
  background-color: #292e38;
  border-bottom: 3px solid #dd163b;
  border-radius: 4px;
}
.nk-image-slider .nk-image-slider-item {
  position: relative;
  width: 100%;
  min-height: 360px;
  padding-bottom: 35%;
}
.nk-image-slider > .nk-image-slider-item:not(:first-of-type) {
  display: none;
}
.nk-image-slider .nk-image-slider-item img,
.nk-image-slider .nk-image-slider-thumbs img {
  position: absolute;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.nk-image-slider .nk-image-slider-bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-position: center center;
  background-size: cover;
  z-index: -1;
}
.nk-image-slider .nk-image-slider-content {
  position: absolute;
  top: 15px;
  right: 15px;
  bottom: 65px;
  width: 315px;
  visibility: hidden;
  background-color: rgba(24, 28, 35, 0.95);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  opacity: 0;
  transition: 0.3s opacity, 0.3s visibility;
}
.nk-image-slider .nk-image-slider-content.nk-image-slider-content-visible {
  visibility: visible;
  opacity: 1;
}
@media (max-width: 575px) {
  .nk-image-slider .nk-image-slider-content {
    bottom: auto;
    left: 15px;
    width: auto;
    height: 330px;
  }
}
.nk-image-slider .nk-image-slider-content .nano-content {
  padding: 25px;
}
.nk-image-slider .nk-image-slider-thumbs {
  position: absolute;
  right: 15px;
  bottom: 15px;
  width: 315px;
  height: 50px;
  overflow: hidden;
  background-color: rgba(24, 28, 35, 0.95);
  border-radius: 4px;
}
.nk-image-slider .nk-image-slider-thumbs .nk-image-slider-thumbs-item {
  position: relative;
  width: 25%;
  height: 50px;
  cursor: pointer;
}
.nk-image-slider .nk-image-slider-thumbs .nk-image-slider-thumbs-item::before {
  content: "";
  position: absolute;
  display: block;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #181c23;
  opacity: 0.6;
  transition: 0.15s opacity;
  z-index: 1;
}
.nk-image-slider
  .nk-image-slider-thumbs
  .nk-image-slider-thumbs-item.hover::before,
.nk-image-slider
  .nk-image-slider-thumbs
  .nk-image-slider-thumbs-item:hover::before {
  opacity: 0.4;
}
.nk-image-slider
  .nk-image-slider-thumbs
  .nk-image-slider-thumbs-item.is-selected::before {
  opacity: 0;
}
.nk-image-slider
  .nk-image-slider-thumbs
  .nk-image-slider-thumbs-item
  .nk-image-slider-thumbs-count {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 30px;
  height: 30px;
  margin-top: -15px;
  margin-left: -15px;
  font-weight: 600;
  line-height: 30px;
  color: #181c23;
  text-align: center;
  background-color: #fff;
  border-radius: 50%;
  z-index: 1;
}
@media (max-width: 575px) {
  .nk-image-slider .nk-image-slider-thumbs {
    position: relative;
    right: auto;
    bottom: auto;
    width: 100%;
    height: auto;
  }
  .nk-image-slider .nk-image-slider-thumbs::after {
    content: "";
    display: block;
    padding-top: 15%;
  }
  .nk-image-slider .nk-image-slider-thumbs .nk-image-slider-thumbs-item {
    height: 100%;
  }
  .nk-image-slider .nk-image-slider-thumbs .flickity-viewport {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100% !important;
  }
}
.nk-image-slider .nk-image-slider-content-visible + .nk-image-slider-thumbs {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.nk-blog-post {
  margin-bottom: 30px;
}
.nk-blog-isotope .nk-blog-post {
  margin-bottom: 40px;
}
.nk-post-title {
  margin-bottom: 10px;
}
.nk-post-title > a {
  color: inherit;
  text-decoration: none;
  transition: 0.15s color;
}
.nk-post-title > a.hover,
.nk-post-title > a:hover {
  color: #dd163b;
}
.nk-post-img {
  position: relative;
  display: block;
  overflow: hidden;
  border-radius: 4px;
  transition: opacity 0.15s ease-in-out;
}
.nk-post-img img {
  width: 100%;
  height: auto;
  transition: transform 0.15s ease-in-out;
  transform: scale(1);
  will-change: transform;
}
.nk-blog-post:not(.nk-blog-post-single):hover .nk-post-img img {
  transform: scale(1.05);
}
.nk-post-img:hover {
  opacity: 0.8;
}
.nk-post-img .nk-post-comments-count {
  position: absolute;
  display: block;
  right: 10px;
  bottom: 10px;
  min-width: 33px;
  height: 29px;
  padding: 0 8px;
  font-weight: 600;
  line-height: 29px;
  text-align: center;
  vertical-align: text-top;
  background-color: #fff;
  border-radius: 50%;
}
.nk-post-img .nk-post-comments-count,
.nk-post-img .nk-post-comments-count:active,
.nk-post-img .nk-post-comments-count:focus,
.nk-post-img .nk-post-comments-count:hover {
  color: #181c23;
  text-decoration: none;
}
.nk-post-img .nk-post-comments-count::after {
  content: "";
  position: absolute;
  display: inline-block;
  bottom: -2px;
  left: 65%;
  width: 0;
  height: 0;
  vertical-align: middle;
  border-top: 9px solid #fff;
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  transform: rotate(-30deg);
}
.nk-post-by {
  margin-top: 15px;
  font-size: 0.8em;
}
.nk-post-by::after {
  content: "";
  display: block;
  clear: both;
}
.nk-post-by img {
  height: auto;
  margin-right: 11px;
}
.nk-post-categories {
  float: right;
  max-width: 100%;
  font-size: 1rem;
}
.nk-post-categories a,
.nk-post-categories span {
  display: inline-block;
  padding: 2px 19px;
  margin-top: 6px;
  color: #fff;
  text-decoration: none;
  white-space: nowrap;
  background-color: #2f3541;
  border-radius: 4px;
}
.nk-post-categories a + a,
.nk-post-categories a + span,
.nk-post-categories span + a,
.nk-post-categories span + span {
  margin-left: 7px;
}
.nk-post-img .nk-post-categories {
  position: absolute;
  top: 10px;
  left: 0;
}
.nk-post-img .nk-post-categories a:first-of-type,
.nk-post-img .nk-post-categories span:first-of-type {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.nk-post-date {
  margin-top: 5px;
  font-size: 0.9em;
  color: #a0a7ab;
}
.nk-post-date span,
.nk-post-date svg {
  margin-right: 6px;
  font-size: 1rem;
}
.nk-post-date span ~ span,
.nk-post-date span ~ svg,
.nk-post-date svg ~ span,
.nk-post-date svg ~ svg {
  margin-left: 10px;
}
.nk-post-date a {
  color: inherit;
  text-decoration: none;
}
.nk-post-date a.hover,
.nk-post-date a:active,
.nk-post-date a:focus,
.nk-post-date a:hover {
  color: #dd163b;
}
.nk-post-share {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 15px;
  background-color: #292e38;
  border-radius: 4px;
}
.nk-post-share > span,
.nk-post-share > svg {
  margin-bottom: 0;
}
.nk-post-share ul {
  margin-left: auto;
}
.nk-post-text::after,
.nk-post-text::before {
  content: "";
  display: block;
  clear: both;
}
.nk-post-text .float-left,
.nk-post-text .float-right,
.nk-post-text .pull-left,
.nk-post-text .pull-right {
  margin: 20px;
}
.nk-post-text .float-right,
.nk-post-text .pull-right {
  margin-right: 0;
}
.nk-post-text .float-left,
.nk-post-text .pull-left {
  margin-left: 0;
}
.nk-post-text > p:last-child {
  margin-bottom: 0;
}
.nk-blog-fullwidth .nk-post-by {
  margin-top: 0;
  font-size: 1em;
  text-align: right;
}
.nk-blog-fullwidth .nk-post-by img {
  float: right;
  height: auto;
  margin-left: 20px;
}
.nk-comments .nk-comment::after {
  content: "";
  display: block;
  clear: both;
}
.nk-comments .nk-comment h4 {
  margin-top: 25px;
}
.nk-comments .nk-comment .nk-comment {
  margin-left: 80px;
}
@media (max-width: 767px) {
  .nk-comments .nk-comment .nk-comment {
    margin-left: 50px;
  }
}
@media (max-width: 575px) {
  .nk-comments .nk-comment .nk-comment {
    margin-left: 30px;
  }
}
.nk-comments .nk-comment .nk-comment,
.nk-comments .nk-comment + .nk-comment {
  padding-top: 20px;
  clear: both;
}
.nk-comments .nk-comment .nk-comment-meta {
  margin-bottom: 15px;
  font-size: 0.8em;
}
.nk-comments .nk-comment .nk-comment-meta img {
  height: auto;
  margin-right: 11px;
}
.nk-news-box {
  height: 425px;
  overflow: hidden;
  background-color: #292e38;
  border-radius: 4px;
}
.nk-news-box .nk-news-box-each-info,
.nk-news-box .nk-news-box-list {
  float: left;
  width: 50%;
  height: 100%;
}
.nk-news-box .nk-news-box-item {
  padding: 10px;
  cursor: pointer;
  transition: 0.15s color, 0.15s background-color;
}
.nk-news-box .nk-news-box-item::after {
  content: "";
  display: block;
  clear: both;
}
.nk-news-box .nk-news-box-item + .nk-news-box-item {
  border-top: 1px solid #2f3541;
}
.nk-news-box .nk-news-box-item.hover,
.nk-news-box .nk-news-box-item:hover {
  background-color: #2f3541;
}
.nk-news-box .nk-news-box-item.nk-news-box-item-active {
  color: #fff;
  background-color: #dd163b;
}
.nk-news-box .nk-news-box-item .nk-news-box-item-img {
  float: left;
  width: 100px;
  margin-right: 20px;
  overflow: hidden;
  border-radius: 4px;
}
.nk-news-box .nk-news-box-item .nk-news-box-item-img img {
  width: 100%;
  height: auto;
  transition: transform 0.15s ease-in-out;
  transform: scale(1);
  will-change: transform;
}
.nk-news-box .nk-news-box-item:hover .nk-news-box-item-img img {
  transform: scale(1.05);
}
.nk-news-box .nk-news-box-item .nk-news-box-item-categories,
.nk-news-box .nk-news-box-item .nk-news-box-item-full-img,
.nk-news-box .nk-news-box-item .nk-news-box-item-url {
  display: none;
}
.nk-news-box .nk-news-box-item .nk-news-box-item-title {
  margin-top: 5px;
  margin-bottom: 2px;
  overflow: hidden;
  font-size: 1.1rem;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.nk-news-box .nk-news-box-item .nk-news-box-item-text,
.nk-news-box .nk-news-box-item .nk-news-box-item-text p {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.nk-news-box .nk-news-box-item .nk-news-box-item-text {
  max-height: 25px;
  margin-bottom: 13px;
}
.nk-news-box .nk-news-box-item .nk-news-box-item-text p {
  margin: 0;
}
.nk-news-box .nk-news-box-item .nk-news-box-item-date {
  margin-top: 5px;
  font-size: 0.9em;
}
.nk-news-box .nk-news-box-item .nk-news-box-item-date span {
  margin-right: 6px;
  font-size: 1rem;
}
.nk-news-box .nk-news-box-each-info {
  border-left: 1px solid #2f3541;
}
.nk-news-box .nk-news-box-each-info .nano-content {
  padding: 20px;
}
.nk-news-box .nk-news-box-each-info .nk-news-box-item-categories {
  position: absolute;
  top: 10px;
  right: 0;
  margin-top: 6px;
  font-size: 1rem;
}
.nk-news-box .nk-news-box-each-info .nk-news-box-item-categories a,
.nk-news-box .nk-news-box-each-info .nk-news-box-item-categories span {
  display: inline-block;
  padding: 2px 19px;
  color: #fff;
  text-decoration: none;
  background-color: #38a220;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.nk-news-box .nk-news-box-each-info .nk-news-box-item-image {
  margin-top: -20px;
  margin-right: -20px;
  margin-bottom: 20px;
  margin-left: -20px;
  background-position: center center;
  background-size: cover;
}
.nk-news-box .nk-news-box-each-info .nk-news-box-item-image img {
  width: 100%;
  height: auto;
}
.nk-news-box
  .nk-news-box-each-info
  .nk-news-box-item-image[style*="background"] {
  padding-top: 41.2%;
}
.nk-news-box .nk-news-box-each-info .nk-news-box-item-title {
  margin-bottom: 15px;
  font-size: 1.2rem;
}
.nk-news-box .nk-news-box-each-info .nk-news-box-item-more {
  font-weight: 600;
  text-transform: uppercase;
}
.nk-news-box .nk-news-box-each-info .nk-news-box-item-date {
  float: right;
  margin-top: 5px;
  font-size: 0.9em;
}
.nk-news-box .nk-news-box-each-info .nk-news-box-item-date span {
  margin-right: 6px;
  font-size: 1rem;
}
@media (max-width: 767px) {
  .nk-news-box {
    height: 720px;
  }
  .nk-news-box .nk-news-box-each-info,
  .nk-news-box .nk-news-box-list {
    float: none;
    width: 100%;
  }
  .nk-news-box .nk-news-box-each-info {
    height: 400px;
    border-left: 0;
  }
  .nk-news-box .nk-news-box-list {
    height: 320px;
  }
  .nk-news-box .nk-news-box-list .nk-news-box-item-img {
    width: 60px;
  }
  .nk-news-box .nk-news-box-list .nk-news-box-item-date {
    display: none;
  }
}
.nk-product-cat,
.nk-product-cat-2,
.nk-product-cat-3 {
  display: flex;
  flex-wrap: wrap;
}
.nk-product-cat .nk-product-image,
.nk-product-cat-2 .nk-product-image,
.nk-product-cat-3 .nk-product-image {
  position: relative;
  display: block;
  width: 115px;
  overflow: hidden;
  border-radius: 4px;
  transition: opacity 0.15s ease-in-out;
}
.nk-product-cat .nk-product-image:hover,
.nk-product-cat-2 .nk-product-image:hover,
.nk-product-cat-3 .nk-product-image:hover {
  opacity: 0.8;
}
.nk-product-cat .nk-product-image img,
.nk-product-cat-2 .nk-product-image img,
.nk-product-cat-3 .nk-product-image img {
  width: 100%;
  height: auto;
  transition: transform 0.15s ease-in-out;
  transform: scale(1);
  will-change: transform;
}
.nk-product-cat .nk-product-image ~ .nk-product-cont,
.nk-product-cat-2 .nk-product-image ~ .nk-product-cont,
.nk-product-cat-3 .nk-product-image ~ .nk-product-cont {
  flex: 1;
  padding-left: 20px;
}
.nk-product-cat-2:hover .nk-product-image img,
.nk-product-cat-3:hover .nk-product-image img,
.nk-product-cat:hover .nk-product-image img {
  transform: scale(1.05);
}
.nk-product-cat-2 .nk-product-image {
  width: 200px;
}
.nk-product-cat-2 .nk-product-image ~ .nk-product-cont {
  padding-left: 35px;
}
@media (max-width: 991px) {
  .nk-product-cat-2 .nk-product-image {
    float: none;
    width: 100%;
  }
  .nk-product-cat-2 .nk-product-image ~ .nk-product-cont {
    padding-left: 0;
    margin-top: 30px;
  }
}
.nk-product-cat-3 {
  max-width: 115px;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
}
.nk-product-cat-3 .nk-product-image {
  float: none;
  width: 100%;
}
.nk-product-cat-3 .nk-product-image ~ .nk-product-cont {
  padding-left: 0;
}
.nk-store-order {
  float: right;
  margin-top: -9px;
}
.nk-store-count {
  padding-bottom: 9px;
}
.nk-product-title {
  margin-top: 0;
  margin-bottom: 0;
}
.nk-product-title a {
  color: inherit;
  text-decoration: none;
}
.nk-product-title a.hover,
.nk-product-title a:active,
.nk-product-title a:focus,
.nk-product-title a:hover {
  color: #dd163b;
}
.nk-product-price {
  display: block;
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1;
  color: #fff;
}
.nk-product-rating {
  position: relative;
  display: inline-block;
  font-size: 1.2rem;
  line-height: 1;
  vertical-align: text-bottom;
}
.nk-product-rating .nk-product-rating-front {
  position: absolute;
  display: block;
  overflow: hidden;
  white-space: nowrap;
}
.nk-product-rating .nk-product-rating-back {
  display: block;
  white-space: nowrap;
}
.nk-store .nk-product-rating {
  float: right;
}
.nk-product-rating,
.nk-product-rating:active,
.nk-product-rating:focus,
.nk-product-rating:hover {
  color: #dd163b;
  text-decoration: none;
}
.nk-review-rating {
  display: inline-block;
  margin-left: 5px;
  font-size: 0.8rem;
  color: #dd163b;
}
.nk-comment .nk-review-rating {
  float: right;
  margin-top: 5px;
  font-size: 1rem;
}
.nk-store-product .nk-product-meta {
  color: #fff;
}
.nk-store-product .nk-product-title {
  margin-bottom: 1.2rem;
}
.nk-store-product .nk-product-description {
  margin-top: 1.2rem;
}
.nk-store-product .nk-product-addtocart .nk-product-price {
  font-size: 2rem;
}
.nk-store-product .nk-product-addtocart input {
  max-width: 80px;
  margin-right: 1rem;
}
.nk-store-product .nk-product-addtocart .input-group {
  width: 220px;
}
.nk-store-product .nk-product-progress {
  margin-top: 20px;
}
.nk-store-product .nk-product-progress table {
  width: 100%;
}
.nk-store-product .nk-product-progress table td:first-child {
  width: 60px;
}
.nk-store-product .nk-product-progress .nk-progress {
  margin-top: 16px;
  margin-bottom: 16px;
}
.nk-store-product .nk-product-info-row > * + ::before {
  content: "";
  position: absolute;
  top: 30px;
  bottom: 0;
  left: 0;
  border-left: 1px solid #2f3541;
}
.nk-store-product .nk-product-pegi img {
  float: left;
  width: 80px;
  height: auto;
}
.nk-store-product .nk-product-pegi img ~ .nk-product-pegi-cont {
  margin-left: 100px;
}
.nk-store-cart .table-responsive .nk-store-cart-products {
  min-width: 680px;
}
.nk-store-cart .nk-store-cart-products {
  color: #fff;
  background-color: #292e38;
  border-radius: 4px;
}
.nk-store-cart .nk-store-cart-products td {
  padding-top: 30px;
  padding-right: 30px;
  padding-bottom: 30px;
  padding-left: 30px;
  border-top: none;
}
.nk-store-cart .nk-store-cart-products tr + tr td {
  padding-top: 0;
}
.nk-store-cart .nk-store-cart-products tbody .nk-product-cart-thumb {
  width: 150px;
  padding-right: 20px;
  padding-left: 20px;
}
.nk-store-cart
  .nk-store-cart-products
  tbody
  .nk-product-cart-thumb
  .nk-post-image {
  display: block;
  overflow: hidden;
  border-radius: 4px;
  transition: opacity 0.15s ease-in-out;
}
.nk-store-cart
  .nk-store-cart-products
  tbody
  .nk-product-cart-thumb
  .nk-post-image:hover {
  opacity: 0.8;
}
.nk-store-cart
  .nk-store-cart-products
  tbody
  .nk-product-cart-thumb
  .nk-post-image
  img {
  transition: transform 0.15s ease-in-out;
  transform: scale(1);
  will-change: transform;
}
.nk-store-cart
  .nk-store-cart-products
  tr:hover
  .nk-product-cart-thumb
  .nk-post-image
  img {
  transform: scale(1.05);
}
.nk-store-cart .nk-store-cart-products .nk-product-cart-remove a {
  display: block;
  margin-top: -7px;
  font-size: 1.8rem;
  line-height: 0;
  color: inherit;
  text-decoration: none;
  opacity: 0.1;
  transition: 0.15s opacity;
}
.nk-store-cart .nk-store-cart-products .nk-product-cart-remove a.hover,
.nk-store-cart .nk-store-cart-products .nk-product-cart-remove a:active,
.nk-store-cart .nk-store-cart-products .nk-product-cart-remove a:focus,
.nk-store-cart .nk-store-cart-products .nk-product-cart-remove a:hover {
  opacity: 0.5;
}
.nk-store-cart .nk-store-cart-products .nk-product-cart-quantity {
  width: 105px;
}
.nk-store-cart .nk-store-cart-products .nk-product-cart-price,
.nk-store-cart .nk-store-cart-products .nk-product-cart-total {
  width: 150px;
}
.nk-store-cart .nk-store-cart-products .nk-product-cart-remove {
  width: 40px;
}
.nk-store-cart .nk-store-cart-products .nk-product-cart-price,
.nk-store-cart .nk-store-cart-products .nk-product-cart-quantity,
.nk-store-cart .nk-store-cart-products .nk-product-cart-remove,
.nk-store-cart .nk-store-cart-products .nk-product-cart-total {
  text-align: center;
}
@media (max-width: 767px) {
  .nk-store-cart .nk-store-cart-products .nk-product-cart-thumb {
    display: none;
  }
}
.nk-store-cart .nk-store-cart-totals {
  margin-top: 30px;
}
.nk-store-cart .nk-store-cart-totals tr td:first-child {
  padding-left: 0;
}
.nk-store-cart .nk-store-cart-totals tr td:last-child {
  padding-right: 0;
  text-align: right;
}
.nk-store-cart .nk-store-cart-totals .nk-store-cart-totals-total td {
  position: relative;
  padding-top: 50px;
  font-size: 1.1rem;
  font-weight: 600;
}
.nk-store-cart .nk-store-cart-totals .nk-store-cart-totals-total td::before {
  content: "";
  position: absolute;
  display: block;
  top: 20px;
  right: 0;
  left: 0;
  border-top: 1px dotted;
  opacity: 0.3;
}
.nk-store-checkout tbody tr.nk-store-cart-totals-shipping,
.nk-store-checkout tbody tr.nk-store-cart-totals-subtotal,
.nk-store-checkout tbody tr.nk-store-cart-totals-total {
  background-color: #181c23;
}
.nk-cart-toggle {
  cursor: pointer;
}
.nk-cart-dropdown {
  position: absolute;
  top: 100%;
  right: 20px;
  width: 315px;
  padding: 18px;
  padding-bottom: 30px;
  margin-top: 11px;
  text-align: left;
  visibility: hidden;
  background-color: #292e38;
  border-bottom: 4px solid #dd163b;
  border-radius: 3px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.35);
  opacity: 0;
  transition: 0.3s opacity, 0.3s visibility;
  z-index: 1010;
}
.nk-cart-dropdown:hover,
.nk-cart-toggle:hover + .nk-cart-dropdown {
  visibility: visible;
  opacity: 1;
}
.nk-cart-dropdown::before {
  content: "";
  position: absolute;
  display: block;
  top: -18px;
  right: 0;
  left: 0;
  height: 18px;
}
.nk-cart-dropdown .nk-post-title {
  font-size: 1rem;
}
.nk-cart-dropdown .nk-cart-remove-item {
  float: right;
  padding: 5px;
  margin-top: -10px;
  font-size: 1.8rem;
  opacity: 0.2;
  transition: 0.15s opacity;
}
.nk-cart-dropdown .nk-cart-remove-item,
.nk-cart-dropdown .nk-cart-remove-item:hover {
  color: #fff;
}
.nk-cart-dropdown .nk-cart-remove-item:hover {
  opacity: 1;
}
.nk-teammate-card {
  position: relative;
  display: flex;
  overflow: hidden;
  background-color: #292e38;
  border-radius: 4px;
}
.nk-teammate-card .nk-teammate-card-photo {
  align-self: flex-end;
  width: 50%;
  padding-right: 15px;
}
.nk-teammate-card .nk-teammate-card-photo img {
  width: 100%;
  height: auto;
}
.nk-teammate-card .nk-teammate-card-info {
  width: 50%;
  padding-left: 15px;
}
.nk-teammate-card .nk-teammate-card-info > table {
  width: 100%;
}
.nk-teammate-card .nk-teammate-card-info > table > tbody > tr > td {
  padding: 20px 10px;
}
@media (max-width: 767px) {
  .nk-teammate-card {
    display: block;
  }
  .nk-teammate-card .nk-teammate-card-info,
  .nk-teammate-card .nk-teammate-card-photo {
    width: 100%;
    padding-right: 0;
    padding-left: 0;
  }
}
.nk-match-score {
  display: inline-block;
  padding: 9px 19px;
  font-family: Montserrat, sans-serif;
  font-size: 0.87rem;
  font-weight: 600;
  line-height: 1.2;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;
  vertical-align: middle;
  background-color: #3f4655;
  border-radius: 4px;
}
.nk-match {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 20px;
  color: #fff;
  background-color: #292e38;
  border-radius: 4px;
}
.nk-match + .nk-match {
  margin-top: 15px;
}
.nk-match .nk-match-team-logo img {
  border-radius: 4px;
}
.nk-match .nk-match-status,
.nk-match .nk-match-team-left,
.nk-match .nk-match-team-right {
  width: 35%;
}
.nk-match .nk-match-status > a,
.nk-match .nk-match-team-left > a,
.nk-match .nk-match-team-right > a {
  color: inherit;
  text-decoration: none;
}
.nk-match .nk-match-status > a:hover,
.nk-match .nk-match-team-left > a:hover,
.nk-match .nk-match-team-right > a:hover {
  color: #dd163b;
}
.nk-match .nk-match-team-left > *,
.nk-match .nk-match-team-right > * {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.nk-match .nk-match-status {
  width: 30%;
  text-align: center;
}
.nk-match .nk-match-status .nk-match-status-vs {
  display: block;
  margin-bottom: 4px;
  font-weight: 600;
}
.nk-match .nk-match-status .nk-match-status-date {
  display: block;
  font-size: 0.9em;
}
.nk-match .nk-match-status .nk-match-score {
  margin-top: 10px;
}
.nk-match .nk-match-team-right {
  text-align: right;
}
.nk-match .nk-match-team-name {
  flex: 1;
  font-family: Montserrat, sans-serif;
  font-weight: 600;
  text-transform: uppercase;
}
.nk-match .nk-match-team-left .nk-match-team-name {
  padding-left: 20px;
}
.nk-match .nk-match-team-right .nk-match-team-name {
  padding-right: 20px;
}
@media (max-width: 991px) {
  .nk-match {
    display: block;
  }
  .nk-match .nk-match-status,
  .nk-match .nk-match-team-left,
  .nk-match .nk-match-team-right {
    width: 100%;
  }
  .nk-match .nk-match-status {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
.nk-team {
  display: flex;
  width: 100%;
  overflow: hidden;
  color: #fff;
  background-color: #292e38;
  border-radius: 4px;
}
.nk-team + .nk-team {
  margin-top: 15px;
}
.nk-team .nk-team-logo {
  align-self: flex-start;
}
.nk-team .nk-team-logo img {
  max-width: 140px;
  height: auto;
}
@media (max-width: 767px) {
  .nk-team .nk-team-logo img {
    max-width: 50px;
  }
}
.nk-team .nk-team-cont {
  position: relative;
  flex: 1;
  padding: 20px;
  z-index: 0;
}
.nk-team .nk-team-cont a {
  color: inherit;
  text-decoration: none;
}
.nk-team .nk-team-cont a.hover,
.nk-team .nk-team-cont a:active,
.nk-team .nk-team-cont a:focus,
.nk-team .nk-team-cont a:hover {
  color: #dd163b;
}
.nk-team .nk-team-cont .nk-team-photo {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-repeat: no-repeat;
  background-position: bottom right;
  z-index: -1;
}
@media (max-width: 767px) {
  .nk-team .nk-team-cont .nk-team-photo {
    display: none;
  }
}
.nk-forum {
  padding: 0;
  margin: 0;
  list-style: none;
  background-color: #292e38;
  border-radius: 4px;
}
.nk-forum > li {
  display: flex;
  align-items: flex-start;
  width: 100%;
  padding: 40px;
}
@media (max-width: 991px) {
  .nk-forum > li {
    padding: 40px;
  }
}
.nk-forum > li + li {
  padding-top: 0;
}
.nk-forum > li.nk-forum-locked > * {
  opacity: 0.4;
}
.nk-forum > li .nk-forum-icon,
.nk-forum > li .nk-forum-title {
  padding-right: 25px;
}
.nk-forum > li .nk-forum-count {
  padding-right: 35px;
  font-size: 0.9rem;
  white-space: nowrap;
}
.nk-forum > li .nk-forum-icon {
  min-width: 55px;
}
.nk-forum > li .nk-forum-icon > span {
  display: block;
  font-size: 1.8rem;
  line-height: 1;
}
.nk-forum > li .nk-forum-title {
  width: 100%;
}
.nk-forum > li .nk-forum-title > h2,
.nk-forum > li .nk-forum-title > h3,
.nk-forum > li .nk-forum-title > h4 {
  margin-bottom: 0;
  font-size: 1.2rem;
}
.nk-forum > li .nk-forum-title > h2 > a,
.nk-forum > li .nk-forum-title > h3 > a,
.nk-forum > li .nk-forum-title > h4 > a {
  color: inherit;
  text-decoration: none;
}
.nk-forum > li .nk-forum-title > h2 > a.hover,
.nk-forum > li .nk-forum-title > h2 > a:hover,
.nk-forum > li .nk-forum-title > h3 > a.hover,
.nk-forum > li .nk-forum-title > h3 > a:hover,
.nk-forum > li .nk-forum-title > h4 > a.hover,
.nk-forum > li .nk-forum-title > h4 > a:hover {
  color: #dd163b;
}
.nk-forum > li .nk-forum-title .nk-forum-title-sub {
  margin-top: 10px;
  font-size: 0.9rem;
  font-style: italic;
}
.nk-forum > li .nk-forum-title .nk-forum-title-sub a {
  font-weight: 600;
  color: inherit;
}
.nk-forum > li .nk-forum-activity-avatar img {
  float: left;
  width: 34px;
  height: auto;
  margin-right: 15px;
  border-radius: 17px;
}
.nk-forum > li .nk-forum-activity {
  min-width: 130px;
  max-width: 130px;
}
.nk-forum > li .nk-forum-activity .nk-forum-activity-date {
  font-size: 0.9rem;
  font-style: italic;
}
.nk-forum > li .nk-forum-activity .nk-forum-activity-title {
  overflow: hidden;
  font-size: 0.9rem;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.nk-forum > li .nk-forum-activity .nk-forum-activity-title > a {
  width: 100%;
  transition: 0.2s opacity;
}
.nk-forum > li .nk-forum-activity .nk-forum-activity-title > a,
.nk-forum > li .nk-forum-activity .nk-forum-activity-title > a:active,
.nk-forum > li .nk-forum-activity .nk-forum-activity-title > a:focus,
.nk-forum > li .nk-forum-activity .nk-forum-activity-title > a:hover {
  color: inherit;
  text-decoration: none;
}
.nk-forum > li .nk-forum-activity .nk-forum-activity-title > a.hover,
.nk-forum > li .nk-forum-activity .nk-forum-activity-title > a:hover {
  opacity: 0.8;
}
@media (max-width: 1199px) {
  .nk-forum > li .nk-forum-activity {
    display: none;
  }
}
@media (max-width: 991px) {
  .nk-forum > li .nk-forum-activity-avatar,
  .nk-forum > li .nk-forum-count {
    display: none;
  }
}
@media (max-width: 575px) {
  .nk-forum > li .nk-forum-icon {
    display: none;
  }
}
.nk-forum-topic > li,
.nk-forum-topic > li + li {
  padding-top: 40px;
}
.nk-forum-topic > li + li {
  margin-top: 30px;
}
.nk-forum-topic > li {
  display: flex;
  flex-wrap: wrap;
}
.nk-forum-topic > li .nk-forum-topic-author {
  width: 120px;
  text-align: center;
}
.nk-forum-topic > li .nk-forum-topic-author img {
  width: 70px;
  height: auto;
  margin: 0 auto;
  border-radius: 35px;
}
.nk-forum-topic > li .nk-forum-topic-author-name {
  margin-top: 20px;
  margin-bottom: 10px;
  font-family: Montserrat, sans-serif;
  font-size: 1.2rem;
  font-weight: 600;
}
.nk-forum-topic > li .nk-forum-topic-author-name > a {
  color: #fff;
  text-decoration: none;
}
.nk-forum-topic > li .nk-forum-topic-author-name > a.hover,
.nk-forum-topic > li .nk-forum-topic-author-name > a:hover {
  color: #dd163b;
}
.nk-forum-topic > li .nk-forum-topic-author-since {
  margin-top: 10px;
  font-size: 0.9rem;
  opacity: 0.6;
}
.nk-forum-topic > li .nk-forum-topic-content {
  flex: 1;
  min-width: 0;
  padding-left: 40px;
}
.nk-forum-topic > li .nk-forum-topic-content p:last-child {
  margin-bottom: 0;
}
.nk-forum-topic > li .nk-forum-topic-footer {
  position: relative;
  width: 100%;
  padding-top: 70px;
}
.nk-forum-topic > li .nk-forum-topic-footer::before {
  content: "";
  position: absolute;
  display: block;
  top: 35px;
  right: 0;
  left: 0;
  border-top: 1px solid #2f3541;
}
.nk-forum-topic > li .nk-forum-topic-footer::after {
  content: "";
  display: block;
  clear: both;
}
.nk-forum-topic > li .nk-forum-topic-footer .nk-forum-topic-date {
  font-size: 0.9rem;
}
.nk-forum-topic > li .nk-forum-topic-footer .nk-forum-action-btn {
  float: right;
  font-size: 0.9rem;
}
.nk-forum-topic > li .nk-forum-topic-footer .nk-forum-action-btn > a > span,
.nk-forum-topic > li .nk-forum-topic-footer .nk-forum-action-btn > a > svg {
  margin-right: 5px;
  vertical-align: middle;
}
.nk-forum-topic > li .nk-forum-topic-footer .nk-forum-action-btn > a,
.nk-forum-topic > li .nk-forum-topic-footer .nk-forum-action-btn > span {
  padding: 7px 14px;
  border-radius: 2px;
  transition: 0.2s background-color, 0.2s color;
}
.nk-forum-topic > li .nk-forum-topic-footer .nk-forum-action-btn > a,
.nk-forum-topic > li .nk-forum-topic-footer .nk-forum-action-btn > a:active,
.nk-forum-topic > li .nk-forum-topic-footer .nk-forum-action-btn > a:focus,
.nk-forum-topic > li .nk-forum-topic-footer .nk-forum-action-btn > a:hover {
  color: inherit;
  text-decoration: none;
}
.nk-forum-topic > li .nk-forum-topic-footer .nk-forum-action-btn > a.hover,
.nk-forum-topic > li .nk-forum-topic-footer .nk-forum-action-btn > a:hover,
.nk-forum-topic > li .nk-forum-topic-footer .nk-forum-action-btn > span.hover,
.nk-forum-topic > li .nk-forum-topic-footer .nk-forum-action-btn > span:hover {
  color: #fff;
  background-color: #2f3541;
}
.nk-forum-topic > li .nk-forum-topic-attachments {
  padding: 40px;
  margin-top: 40px;
  background-color: #2f3541;
}
@media (max-width: 767px) {
  .nk-forum-topic > li {
    padding: 30px;
  }
  .nk-forum-topic > li + li {
    padding-top: 30px;
  }
  .nk-forum-topic > li .nk-forum-topic-author {
    float: none;
    width: 100%;
    text-align: left;
  }
  .nk-forum-topic > li .nk-forum-topic-author::after {
    content: "";
    display: block;
    margin-bottom: 25px;
    clear: both;
  }
  .nk-forum-topic > li .nk-forum-topic-author img {
    float: left;
    width: 50px;
    height: auto;
    margin: 0;
  }
  .nk-forum-topic > li .nk-forum-topic-author .nk-forum-topic-author-name {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 70px;
    line-height: 1.4;
  }
  .nk-forum-topic > li .nk-forum-topic-author .nk-forum-topic-author-role {
    margin-left: 70px;
    font-size: 0.8rem;
  }
  .nk-forum-topic > li .nk-forum-topic-author .nk-forum-topic-author-since {
    display: none;
  }
  .nk-forum-topic > li .nk-forum-topic-content {
    min-width: 100%;
    padding-left: 0;
  }
}
.nano {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.nano .nano-content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: scroll;
  overflow-x: hidden;
  outline: 0;
  -webkit-overflow-scrolling: touch;
}
.nano .nano-content::-webkit-scrollbar {
  display: none;
}
.nano > .nano-pane {
  position: absolute;
  top: 4px;
  right: 4px;
  bottom: 4px;
  width: 6px;
  opacity: 0.01;
  transition: 0.2s opacity;
  will-change: opacity;
}
.nano > .nano-pane > .nano-slider {
  position: relative;
  background: #fff;
  border-radius: 3px;
  opacity: 0.2;
}
.nano > .nano-pane:hover > .nano-slider {
  opacity: 0.6;
}
.has-scrollbar > .nano-content::-webkit-scrollbar {
  display: block;
}
.nano-pane.active,
.nano-pane.flashed,
.nano:hover > .nano-pane {
  opacity: 0.99;
}
.pswp__button {
  opacity: 1;
}
.pswp__button:hover {
  opacity: 0.7;
}
.pswp__button--arrow--left {
  left: 15px;
}
.pswp__button--arrow--right {
  right: 15px;
}
.pswp__button--arrow--left::before,
.pswp__button--arrow--right::before {
  top: 24px;
  width: 56px;
  height: 56px;
  background-color: transparent;
  border: 4px solid #fff;
  border-radius: 28px;
}
.pswp__button--arrow--left::before {
  left: 8px;
  background-position: -130px -34px;
}
.pswp__button--arrow--right::before {
  right: 8px;
  background-position: -85px -34px;
}
.pswp__top-bar {
  top: 15px;
  right: 15px;
  left: 15px;
  width: auto;
}
@media (max-width: 575px) {
  .pswp__button--arrow--left {
    left: 0;
  }
  .pswp__button--arrow--right {
    right: 0;
  }
  .pswp__top-bar {
    top: 15px;
    right: 0;
    left: 0;
  }
}
.pswp__caption__center {
  max-width: 520px;
  padding: 40px;
  font-size: inherit;
  line-height: inherit;
}
.nk-input-slider .slider {
  width: 100%;
}
.nk-input-slider .slider-handle,
.nk-input-slider .slider-selection,
.nk-input-slider .slider-track {
  background-image: none;
}
.nk-input-slider .slider-track {
  background-color: #2f3541;
  border-radius: 0;
}
.nk-widget .nk-input-slider .slider-track {
  background-color: #181c23;
}
.nk-input-slider .slider.slider-horizontal .slider-track {
  height: 6px;
}
.nk-input-slider .slider-handle,
.nk-input-slider .slider-selection {
  background-color: #dd163b;
}
.nk-input-slider .slider-handle {
  width: 16px;
  height: 16px;
}
.nk-input-slider-inline {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.nk-input-slider-inline .nk-input-slider {
  flex: 1;
  padding: 3px 15px;
  margin-right: 20px;
  border: 1px solid #2f3541;
  border-radius: 4px;
}
.nk-input-slider-inline .nk-input-slider > * {
  display: table-cell;
}
.nk-input-slider-inline .nk-input-slider > .nk-input-slider-content {
  min-width: 160px;
}
.nk-input-slider-inline .nk-input-slider > .nk-input-slider-input {
  width: 99%;
  padding-left: 20px;
}
@media (max-width: 767px) {
  .nk-input-slider-inline .nk-input-slider {
    margin-right: 0;
  }
  .nk-input-slider-inline .nk-input-slider > * {
    display: block;
    text-align: center;
  }
  .nk-input-slider-inline .nk-input-slider > .nk-input-slider-content {
    min-width: 0;
  }
  .nk-input-slider-inline .nk-input-slider > .nk-input-slider-input {
    width: 100%;
    padding-left: 0;
    margin-top: 10px;
  }
  .nk-input-slider-inline > * {
    display: block;
  }
  .nk-input-slider-inline > :last-child {
    width: 100%;
    padding-top: 20px;
    padding-left: 0;
    text-align: right;
  }
}
.note-editor.note-frame {
  border: none;
}
.panel-heading.note-toolbar {
  padding: 10px 26px;
  background-color: #292e38;
}
.note-editor.note-frame .note-editing-area .note-editable {
  padding: 25px;
  color: #fff;
  background-color: #2f3541;
}
.note-editor.note-frame .note-statusbar {
  background-color: #0e1014;
  border-top: none;
}
.note-toolbar.card-header {
  background-color: #292e38;
}
.note-toolbar .note-btn {
  padding: 6px 15px;
  color: inherit;
  background-color: #181c23;
  border: none;
}
.note-toolbar .note-btn:hover {
  background-color: #0e1014;
}
.note-toolbar .dropdown-menu {
  color: inherit;
  background-color: #292e38;
  box-shadow: 0 4px 10px 0 #292e38;
}
.note-toolbar .dropdown-menu a {
  display: block;
  padding: 4px 10px;
  color: inherit;
}
.note-toolbar .dropdown-menu a:active,
.note-toolbar .dropdown-menu a:focus,
.note-toolbar .dropdown-menu a:hover {
  text-decoration: none;
}
.note-toolbar .dropdown-menu a:hover {
  background-color: #181c23;
}
.note-toolbar .dropdown-toggle::after {
  display: none;
}
.note-popover .popover-content .note-color-palette div .note-color-btn,
.note-toolbar.panel-heading .note-color-palette div .note-color-btn {
  border-color: #181c23;
}
.note-popover .popover-content > .btn-group,
.panel-heading.note-toolbar > .btn-group {
  margin-top: 10px;
  margin-right: 15px;
  margin-bottom: 10px;
}
.link-dialog .custom-checkbox input {
  top: 6px;
  left: 0;
  opacity: 1;
  z-index: 1;
}
