@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  transition: position 1s ease;
}

.transparentBlack {
  background-color: #0002059f;
}

.bgTransparentRed {
  background-color: rgba(222, 11, 11, 0.71);
}

.d-none {
  display: none;
}
.d-block {
  display: block;
}

.z-100 {
  z-index: -1;
}

.bgChainDark {
  background-color: #292e38;
}

.bgChainDark:hover {
  background-color: #303541ed;
}

.bgLightGray {
  background-color: #2f3541;
}

.bgDarkGray {
  background-color: #292e38;
}

.bgLightBlack {
  background-color: rgba(1, 0, 0, 0.603);
}
.bgDarkBlack {
  background-color: rgba(1, 0, 0, 0.831);
}

.bgGrayColor {
  background-color: rgb(47, 53, 65);
}

.textGrayColor {
  color: rgb(140, 150, 147);
}

body {
  color: #a0a7ab;
  font-family: Montserrat !important;
}

a {
  text-decoration: none !important;
}

.width-60-vw {
  width: 60vw;
}

.form-control {
  background-color: transparent !important;
}

.left-news-img {
  padding-top: 85% !important;
  background-size: cover;
  background-position: center;
}

.active-news-image {
  padding-top: 50% !important;
  background-size: cover;
  background-position: center;
}

.news-body-img {
  padding-top: 56.2% !important;
  background-size: cover;
  background-position: center;
}

.grid-img {
  padding-top: 70% !important;
  background-size: cover;
  background-position: center;
}

.form-control {
  color: #fff !important;
}

/* Login Component CSS */
.mainContainerL {
  background: transparent;
  width: 100%;
  height: 100%;
  min-height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.signL {
  color: #cb183b;
}
.inL {
  color: white;
}
.descL {
  color: white;
  font-size: 10px;
  margin-bottom: 10px;
}
.loginformInput {
  background: transparent;
  border: 1px solid #303846;
  padding: 1px;

  margin: 2px;
}
.loginformInput:focus {
  border-color: #303846;
}
.innerLoginContainer {
  width: 75%;
  height: 95%;
  min-height: 55vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #633323;
}
.loginContainer {
  font-size: 1.22rem;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  height: 90%;
  width: 50%;
  padding: 4%;
  padding-left: 7%;
}
.btnPO {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #862239;
}
.btnL {
  height: 27px;
  width: 180px;
  background-color: white;
  color: #292e38;
  text-transform: uppercase;
}

.otherLinksL {  
  font-size: 1.22rem;
  display: flex;
  flex-direction: column;  
}

/* Specific to Profile */
.profileFormContainer {
  color: white;
  margin-left: 70px;
}
.profileContainer {
  background-color: #292e38;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  height: 90%;
  width: 50%;
  padding: 4%;
  padding-left: 5%;
}
